import React from 'react';
import { motion } from 'framer-motion';
import { EnvelopeIcon, CalendarIcon, LockClosedIcon, PaperClipIcon, UserGroupIcon, ClockIcon, ShieldCheckIcon, DocumentTextIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline';

const FeatureVisual = ({ feature }) => {
  const getVisual = () => {
    switch (feature.title) {
      case 'Simple Message Creator':
        return (
          <div className="bg-gradient-to-br from-blue-500 to-indigo-600 p-6 rounded-xl shadow-lg">
            <div className="bg-white bg-opacity-95 p-4 rounded-lg shadow-inner mb-4">
              <div className="flex items-center mb-3">
                <EnvelopeIcon className="w-6 h-6 text-blue-500 mr-2" />
                <div className="text-sm font-medium text-gray-600">New Message</div>
              </div>
              <div className="space-y-2">
                <div className="h-8 bg-gray-100 rounded flex items-center px-2">
                  <span className="text-xs text-gray-400">To:</span>
                </div>
                <div className="h-8 bg-gray-100 rounded flex items-center px-2">
                  <span className="text-xs text-gray-400">Subject:</span>
                </div>
                <div className="h-24 bg-gray-100 rounded"></div>
              </div>
              <div className="mt-3 flex items-center">
                <PaperClipIcon className="w-5 h-5 text-gray-400 mr-2" />
                <span className="text-xs text-gray-500">Attach files</span>
              </div>
            </div>
            <motion.button 
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="w-full bg-white text-blue-600 font-semibold px-4 py-2 rounded-lg shadow-md hover:bg-opacity-90 transition duration-300"
            >
              Send Message
            </motion.button>
          </div>
        );
      case 'Smart Delivery Rules':
        return (
          <div className="bg-gradient-to-br from-blue-500 to-indigo-600 p-6 rounded-xl shadow-lg">
            <div className="bg-white bg-opacity-95 p-4 rounded-lg shadow-inner mb-4">
              <div className="flex items-center justify-between mb-3">
                <div className="flex items-center">
                  <EnvelopeIcon className="w-6 h-6 text-blue-500 mr-2" />
                  <div className="text-sm font-medium text-gray-600">Deadman's Switch</div>
                </div>
                <ClockIcon className="w-6 h-6 text-indigo-500" />
              </div>
              <div className="space-y-3">
                <div className="flex items-center">
                  <ExclamationCircleIcon className="w-5 h-5 text-yellow-500 mr-2" />
                  <div className="flex-grow h-8 bg-gray-100 rounded flex items-center px-2">
                    <span className="text-xs text-gray-500">Check-in required: 30 days</span>
                  </div>
                </div>
                <div className="flex items-center">
                  <UserGroupIcon className="w-5 h-5 text-blue-500 mr-2" />
                  <div className="flex-grow h-8 bg-gray-100 rounded flex items-center px-2">
                    <span className="text-xs text-gray-500">Trusted contacts: 3</span>
                  </div>
                </div>
                <div className="flex items-center">
                  <ShieldCheckIcon className="w-5 h-5 text-green-500 mr-2" />
                  <div className="flex-grow h-8 bg-gray-100 rounded flex items-center px-2">
                    <span className="text-xs text-gray-500">False trigger prevention active</span>
                  </div>
                </div>
              </div>
            </div>
            <motion.button 
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="w-full bg-white text-blue-600 font-semibold px-4 py-2 rounded-lg shadow-md hover:bg-opacity-90 transition duration-300"
            >
              Configure Switch
            </motion.button>
          </div>
        );
      case 'Access Control':
        return (
          <div className="bg-gradient-to-br from-purple-500 to-pink-500 p-6 rounded-xl shadow-lg">
            <div className="bg-white bg-opacity-95 p-4 rounded-lg shadow-inner mb-4">
              <div className="flex justify-between items-center mb-3">
                <UserGroupIcon className="w-8 h-8 text-purple-600" />
                <ShieldCheckIcon className="w-6 h-6 text-pink-500" />
              </div>
              <div className="space-y-2">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <div className="w-8 h-8 rounded-full bg-gray-200 mr-2"></div>
                    <div className="h-4 bg-gray-200 rounded w-24"></div>
                  </div>
                  <div className="w-16 h-6 bg-green-100 rounded-full flex items-center justify-center">
                    <span className="text-xs text-green-600 font-medium">Admin</span>
                  </div>
                </div>
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <div className="w-8 h-8 rounded-full bg-gray-200 mr-2"></div>
                    <div className="h-4 bg-gray-200 rounded w-24"></div>
                  </div>
                  <div className="w-16 h-6 bg-blue-100 rounded-full flex items-center justify-center">
                    <span className="text-xs text-blue-600 font-medium">View</span>
                  </div>
                </div>
              </div>
            </div>
            <motion.button 
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="w-full bg-white text-purple-600 font-semibold px-4 py-2 rounded-lg shadow-md hover:bg-opacity-90 transition duration-300"
            >
              Manage Access
            </motion.button>
          </div>
        );
      case 'End-to-End Encryption':
        return (
          <div className="bg-gradient-to-br from-purple-600 to-pink-500 p-6 rounded-xl shadow-lg">
            <div className="bg-white bg-opacity-95 p-4 rounded-lg shadow-inner mb-4">
              <div className="flex justify-center mb-3">
                <ShieldCheckIcon className="w-16 h-16 text-purple-600" />
              </div>
              <div className="space-y-2">
                <div className="h-2 bg-purple-200 rounded w-full"></div>
                <div className="h-2 bg-purple-300 rounded w-5/6"></div>
                <div className="h-2 bg-purple-400 rounded w-4/5"></div>
                <div className="h-2 bg-purple-500 rounded w-3/4"></div>
              </div>
            </div>
            <div className="flex justify-between items-center">
              <div className="text-white text-sm font-medium">AES256</div>
              <motion.button 
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="bg-white text-purple-600 font-semibold px-4 py-2 rounded-lg shadow-md hover:bg-opacity-90 transition duration-300"
              >
                Encrypt
              </motion.button>
            </div>
          </div>
        );
      case 'Secure File Storage':
        return (
          <div className="bg-gradient-to-br from-yellow-400 to-orange-500 p-6 rounded-xl shadow-lg">
            <div className="bg-white bg-opacity-95 p-4 rounded-lg shadow-inner mb-4">
              <div className="flex items-center justify-between mb-3">
                <DocumentTextIcon className="w-12 h-12 text-orange-500" />
                <LockClosedIcon className="w-8 h-8 text-yellow-500" />
              </div>
              <div className="space-y-2">
                <div className="flex items-center">
                  <div className="w-8 h-8 rounded bg-gray-200 mr-2 flex items-center justify-center">
                    <DocumentTextIcon className="w-6 h-6 text-gray-400" />
                  </div>
                  <div className="h-4 bg-gray-200 rounded w-2/3"></div>
                </div>
                <div className="flex items-center">
                  <div className="w-8 h-8 rounded bg-gray-200 mr-2 flex items-center justify-center">
                    <DocumentTextIcon className="w-6 h-6 text-gray-400" />
                  </div>
                  <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                </div>
              </div>
            </div>
            <motion.button 
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="w-full bg-white text-orange-500 font-semibold px-4 py-2 rounded-lg shadow-md hover:bg-opacity-90 transition duration-300"
            >
              Upload File
            </motion.button>
          </div>
        );
      default:
        return (
          <div className="bg-gradient-to-br from-gray-400 to-gray-600 p-6 rounded-xl shadow-lg flex items-center justify-center">
            <EnvelopeIcon className="w-16 h-16 text-white" />
          </div>
        );
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="w-full max-w-md mx-auto"
    >
      {getVisual()}
    </motion.div>
  );
};

export default FeatureVisual;
