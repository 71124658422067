import React from 'react';
import { motion } from 'framer-motion';
import { ShieldCheckIcon, LockClosedIcon, EnvelopeIcon, CheckCircleIcon } from '@heroicons/react/24/outline';

const Hero = () => {
  return (
    <section className="relative overflow-hidden bg-gradient-to-br from-blue-50 via-white to-blue-100">
      {/* Updated abstract background pattern */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-br from-blue-400/20 via-blue-300/20 to-cyan-200/20 opacity-30"></div>
        <div className="absolute -top-40 -right-32 w-72 h-72 md:w-96 md:h-96 rounded-full bg-blue-300 mix-blend-multiply opacity-20 animate-blob"></div>
        <div className="absolute -bottom-40 -left-32 w-72 h-72 md:w-96 md:h-96 rounded-full bg-cyan-300 mix-blend-multiply opacity-20 animate-blob animation-delay-2000"></div>
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-72 h-72 md:w-96 md:h-96 rounded-full bg-blue-200 mix-blend-multiply opacity-10 animate-blob animation-delay-4000"></div>
      </div>

      {/* CSS-based mesh gradient overlay */}
      <div className="absolute inset-0 bg-gradient-to-br from-blue-400/30 via-blue-300/30 to-cyan-200/30 opacity-30 filter blur-3xl"></div>

      {/* Additional layered gradients for depth */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-gradient-to-tl from-blue-300/10 via-blue-200/10 to-cyan-100/10 opacity-20 filter blur-2xl"></div>
        <div className="absolute inset-0 bg-gradient-to-r from-blue-200/10 via-cyan-100/10 to-blue-100/10 opacity-10 filter blur-xl"></div>
      </div>

      <div className="relative max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 pb-16">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center mb-12"
        >
 <span className="inline-flex items-center px-4 py-2 rounded-full bg-blue-100 text-blue-700 text-sm font-medium mb-8">
            Trusted by Privacy Enthusiasts and Security Professionals
            <CheckCircleIcon className="h-4 w-4 ml-2 text-blue-700" />
          </span>

          <h1 className="text-5xl sm:text-6xl font-bold text-gray-900 tracking-tight mb-8">
            Secure Your Legacy,{' '}
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-blue-400">
              Protect What Matters Most
            </span>
          </h1>

          <p className="text-xl text-gray-600 max-w-2xl mx-auto mb-12">
            Don't leave your final wishes to chance. Ensure your most important messages reach the right people at the right time, with military-grade security and peace of mind.
          </p>

          <div className="flex flex-col sm:flex-row justify-center gap-4 mb-16">
            <motion.a
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              href="/signup"
              className="inline-flex items-center justify-center px-8 py-4 text-lg font-medium text-white bg-gradient-to-r from-blue-600 to-blue-500 rounded-lg shadow-lg hover:shadow-xl transition-all duration-200"
            >
              Start Your Risk-Free Trial
              <svg className="ml-2 -mr-1 w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7l5 5m0 0l-5 5m5-5H6" />
              </svg>
            </motion.a>
     
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-3 gap-8 max-w-3xl mx-auto mt-16">
            {[
              {
                icon: ShieldCheckIcon,
                title: "Unbreakable Security",
                description: "Military-grade encryption ensures your messages remain private until the moment they're needed"
              },
              {
                icon: EnvelopeIcon,
                title: "Intelligent Delivery",
                description: "Advanced algorithms guarantee your messages reach the right recipients at the perfect time"
              },
              {
                icon: LockClosedIcon,
                title: "Complete Control",
                description: "Customize every aspect of message delivery for ultimate peace of mind"
              }
            ].map((feature, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 + index * 0.1 }}
                className="group relative bg-white/80 backdrop-blur-sm rounded-xl p-6 shadow-md hover:shadow-xl transition-all duration-300"
              >
                <div className="absolute inset-0 bg-gradient-to-br from-blue-50 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-xl"></div>
                <div className="relative flex flex-col h-full">
                  <div className="flex items-center justify-center mb-4">
                    <div className="p-2 bg-blue-100 rounded-full">
                      <feature.icon className="h-6 w-6 text-blue-600" />
                    </div>
                  </div>
                  <h3 className="text-lg font-semibold text-gray-900 mb-2 text-center">{feature.title}</h3>
                  <p className="text-sm text-gray-600 text-center">{feature.description}</p>
                </div>
              </motion.div>
            ))}
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default Hero;
