// Base container style to be used in all sections
const Container = ({ children, className = '' }) => {
  return (
    <div className="px-0 sm:px-6 lg:px-8">
      <div 
        className={`mx-0 sm:mx-auto ${className}`} 
        style={{ 
          maxWidth: '1550px',
          boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)'
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default Container;
