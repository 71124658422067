import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { 
  ChevronDownIcon, 
  ShieldCheckIcon, 
  CurrencyDollarIcon, 
  QuestionMarkCircleIcon,
  KeyIcon,
  UserGroupIcon,
  ClockIcon
} from '@heroicons/react/24/outline';

const FAQ = () => {
  const [activeCategory, setActiveCategory] = useState('general');
  const [openQuestions, setOpenQuestions] = useState({});

  const categories = [
    {
      id: 'general',
      name: 'General Questions',
      icon: QuestionMarkCircleIcon,
      description: 'Basic information about our service'
    },
    {
      id: 'security',
      name: 'Security & Privacy',
      icon: ShieldCheckIcon,
      description: 'How we protect your data'
    },
    {
      id: 'pricing',
      name: 'Pricing & Plans',
      icon: CurrencyDollarIcon,
      description: 'Billing and subscription details'
    },
    {
      id: 'triggers',
      name: 'Message Triggers',
      icon: ClockIcon,
      description: 'How message delivery works'
    },
    {
      id: 'technical',
      name: 'Technical Details',
      icon: KeyIcon,
      description: 'Technical specifications and requirements'
    }
  ];

  const faqs = {
    general: [
      {
        question: "What happens if I want to cancel my account?",
        answer: "You can cancel your subscription at any time. Your messages will be securely deleted from our servers, and you'll receive a confirmation email. We offer a 30-day money-back guarantee if you're not satisfied with our service."
      },
      {
        question: "Can I edit or delete messages after creating them?",
        answer: "Yes, you have full control over your messages until they're triggered for delivery. You can edit, update, or delete any message at any time. Once a message is delivered, it cannot be recalled for security and privacy reasons."
      },
      {
        question: "What types of files can I attach to messages?",
        answer: "You can attach most common file types including documents, images, videos, and archive files. The file size limit varies by plan: Essential (100MB per file), Enhanced Security (1GB per file), and Enterprise (custom limits)."
      }
    ],
    security: [
      {
        question: "How secure is my data with Cryonote?",
        answer: `We use multiple layers of security:
        ▸ 256-bit AES encryption for all data
        ▸ Optional local encryption before upload
        ▸ Zero-knowledge architecture
        ▸ Regular security audits
        ▸ SOC 2 Type II certified infrastructure
        Your data is encrypted both in transit and at rest, and with Enhanced Security, even we cannot access your unencrypted content.`
      },
      {
        question: "What happens if someone tries to hack my account?",
        answer: `We employ multiple security measures including:
        ▸ Automatic account lockout after failed attempts
        ▸ Two-factor authentication
        ▸ Suspicious activity monitoring
        ▸ Real-time alerts for unusual login attempts
        You'll be immediately notified of any suspicious activity.`
      },
      {
        question: "Can Cryonote employees read my messages?",
        answer: "No. With Enhanced Security, your messages are encrypted locally before being uploaded to our servers. We never have access to your encryption keys or unencrypted content. With Standard Security, while messages are encrypted on our servers, we recommend not including sensitive information in the messages themselves."
      }
    ],
    pricing: [
      {
        question: "What's included in the free trial?",
        answer: `Our 14-day free trial includes all features of the Enhanced Security plan:
        ▸ Unlimited messages
        ▸ All security features
        ▸ Full trigger system access
        ▸ 1GB file attachments
        No credit card is required to start the trial.`
      },
      {
        question: "Can I change plans later?",
        answer: "Yes, you can upgrade or downgrade your plan at any time. Upgrades take effect immediately, with prorated billing. When downgrading, your new plan takes effect at the next billing cycle. All your data and messages remain secure during plan changes."
      },
      {
        question: "What happens if I miss a payment?",
        answer: "We provide a 7-day grace period for missed payments, during which all your messages and settings remain intact. We'll send multiple notifications before any service interruption. Your data is never deleted without explicit confirmation from you."
      }
    ],
    triggers: [
      {
        question: "What prevents messages from being triggered accidentally?",
        answer: `We have multiple safeguards in place:
        ▸ Multiple verification steps before delivery
        ▸ Customizable grace periods
        ▸ Secondary confirmation requirements
        ▸ Override options with security phrases
        ▸ Manual verification for sensitive triggers`
      },
      {
        question: "How does the consensus trigger work?",
        answer: `The consensus trigger requires multiple trusted contacts to confirm your status before any messages are delivered. You can:
        ▸ Set the number of required confirmations
        ▸ Choose verification methods
        ▸ Set confirmation timeframes
        ▸ Add backup contacts
        Additional verification is required before final message delivery.`
      }
    ],
    technical: [
      {
        question: "What encryption methods do you use?",
        answer: `We employ industry-standard encryption:
        ▸ AES-256 for data at rest
        ▸ TLS 1.3 for data in transit
        ▸ Local encryption using AES-GCM
        ▸ Secure key generation
        All encryption meets or exceeds NIST standards.`
      },
      {
        question: "Can I export my data?",
        answer: `Yes, you can export all your data at any time. This includes:
        ▸ Message contents and settings
        ▸ Trigger configurations
        ▸ Contact lists and preferences
        ▸ Activity logs
        Data is exported in standard formats for easy backup.`
      }
    ]
  };

  const toggleQuestion = (questionId) => {
    setOpenQuestions(prev => ({
      ...prev,
      [questionId]: !prev[questionId]
    }));
  };

  // Function to parse and render the answer text
  const renderAnswer = (answer) => {
    const lines = answer.split('\n').map(line => line.trim());
    const elements = [];
    let i = 0;

    while (i < lines.length) {
      if (lines[i].startsWith('▸')) {
        // Start of a list
        const items = [];
        while (i < lines.length && lines[i].startsWith('▸')) {
          items.push(lines[i].slice(1).trim());
          i++;
        }
        elements.push(
          <ul className="list-disc list-inside space-y-1 mb-4" key={i}>
            {items.map((item, idx) => (
              <li key={idx}>{item}</li>
            ))}
          </ul>
        );
      } else if (lines[i]) {
        // Regular paragraph
        const paragraphs = [];
        while (i < lines.length && lines[i] && !lines[i].startsWith('▸')) {
          paragraphs.push(lines[i]);
          i++;
        }
        elements.push(
          <p className="mb-4" key={i}>
            {paragraphs.join(' ')}
          </p>
        );
      } else {
        // Empty line
        i++;
      }
    }

    return elements;
  };

  return (
    <section className="relative py-24 bg-white">
      {/* Background decoration */}
      <div className="absolute inset-0">
        <div className="absolute inset-y-0 left-0 w-1/2 bg-gradient-to-r from-blue-50 to-transparent" />
        <div className="absolute inset-y-0 right-0 w-1/2 bg-gradient-to-l from-blue-50 to-transparent" />
      </div>

      <div className="relative max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <motion.span
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="inline-block px-4 py-2 rounded-full bg-blue-100 text-blue-700 text-sm font-medium mb-4"
          >
            Common Questions
          </motion.span>

          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-4xl font-bold text-gray-900 mb-4"
          >
            Frequently Asked Questions
          </motion.h2>

          <motion.p
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-xl text-gray-600 max-w-2xl mx-auto"
          >
            Everything you need to know about Cryonote
          </motion.p>
        </div>

        {/* Category Selection */}
        <div className="grid grid-cols-2 md:grid-cols-5 gap-4 mb-12">
          {categories.map((category) => (
            <button
              key={category.id}
              onClick={() => setActiveCategory(category.id)}
              className={`p-4 rounded-xl text-left transition-all duration-200 ${
                activeCategory === category.id
                  ? 'bg-blue-600 text-white shadow-lg'
                  : 'bg-white hover:bg-blue-50 text-gray-600 hover:text-gray-900 shadow-sm'
              }`}
            >
              <category.icon className="h-6 w-6 mb-2" />
              <div className="font-medium text-sm">{category.name}</div>
            </button>
          ))}
        </div>

        {/* FAQ List */}
        <div className="space-y-4">
          {faqs[activeCategory].map((faq, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.1 }}
              className="bg-white rounded-xl shadow-sm hover:shadow-md transition-shadow duration-200"
            >
              <button
                onClick={() => toggleQuestion(index)}
                className="w-full text-left px-6 py-4 flex items-center justify-between"
              >
                <span className="font-medium text-gray-900">{faq.question}</span>
                <ChevronDownIcon
                  className={`h-5 w-5 text-gray-500 transition-transform duration-200 ${
                    openQuestions[index] ? 'transform rotate-180' : ''
                  }`}
                />
              </button>

              {openQuestions[index] && (
                <div className="px-6 pb-4">
                  <div className="pt-4 border-t">
                    <div className="prose max-w-none text-gray-600">
                      {renderAnswer(faq.answer)}
                    </div>
                  </div>
                </div>
              )}
            </motion.div>
          ))}
        </div>

        {/* Still Have Questions */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="mt-16 text-center"
        >
          <div className="inline-block bg-blue-50 rounded-2xl p-8">
            <h3 className="text-xl font-semibold text-gray-900 mb-4">
              Still have questions?
            </h3>
            <p className="text-gray-600 mb-6">
              Our team is here to help you make the right choice for your digital legacy.
            </p>
            <div className="flex justify-center space-x-4">
              <a
                href="/contact"
                className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 transition-colors duration-200"
              >
                Contact Support
              </a>
              <a
                href="#demo"
                className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-blue-700 bg-white hover:bg-blue-50 transition-colors duration-200"
              >
                Schedule Demo
              </a>
            </div>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default FAQ;

