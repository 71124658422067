import React, { useState, useEffect } from 'react';
import { ChevronDownIcon, ChevronUpIcon, DocumentIcon, InformationCircleIcon, EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import EncryptionService from '../../services/EncryptionService';

const MessageDisplay = ({ message, decryptionKey }) => {
  const [showAttachmentInstructions, setShowAttachmentInstructions] = useState(false);
  const [showPasswords, setShowPasswords] = useState({});
  const [decryptedContent, setDecryptedContent] = useState('');

  useEffect(() => {
    if (message && message.encryptedContent && decryptionKey) {
      try {
        const decrypted = EncryptionService.decrypt(message.encryptedContent, decryptionKey);
        setDecryptedContent(decrypted);
      } catch (error) {
        console.error('Error decrypting content:', error);
        setDecryptedContent('Error decrypting content. Please verify your decryption key.');
      }
    }
  }, [message, decryptionKey]);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      // Using a more subtle alert or toast would be better here
      alert('Copied to clipboard');
    });
  };

  const getFullUrl = (url) => {
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      return `https://${url}`;
    }
    return url;
  };

  const togglePasswordVisibility = (attachmentId) => {
    setShowPasswords(prev => ({
      ...prev,
      [attachmentId]: !prev[attachmentId]
    }));
  };

  const renderAttachment = (att) => (
    <li key={att.filename} className="bg-white rounded-lg shadow border border-gray-100">
      <div className="p-4">
        <div className="flex items-center justify-between mb-3">
          <div className="flex items-center space-x-3">
            <DocumentIcon className="h-5 w-5 text-blue-500" />
            <span className="font-medium text-gray-900">{att.filename}</span>
          </div>
          <div className="flex space-x-2">
            <a
              href={getFullUrl(att.pcloudUrl)}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center px-3 py-1.5 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Download
            </a>
            <button
              onClick={() => copyToClipboard(getFullUrl(att.pcloudUrl))}
              className="inline-flex items-center px-3 py-1.5 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Copy Link
            </button>
          </div>
        </div>

        {att.decryptedKey && (
          <div className="mt-3 bg-gray-50 rounded-md p-3">
            <div className="flex justify-between items-center mb-1">
              <span className="text-sm font-medium text-gray-700">Password for .7z file:</span>
              <button
                onClick={() => togglePasswordVisibility(att.filename)}
                className="text-gray-500 hover:text-gray-700"
              >
                {showPasswords[att.filename] ? (
                  <EyeSlashIcon className="h-4 w-4" />
                ) : (
                  <EyeIcon className="h-4 w-4" />
                )}
              </button>
            </div>
            <div className="flex items-center justify-between bg-white rounded border border-gray-200 px-3 py-2 mt-1">
              <code className="font-mono text-sm text-gray-800">
                {showPasswords[att.filename] ? att.decryptedKey : '••••••••••••••••'}
              </code>
              <button
                onClick={() => copyToClipboard(att.decryptedKey)}
                className="ml-2 text-sm text-blue-600 hover:text-blue-800"
              >
                Copy
              </button>
            </div>
          </div>
        )}
      </div>
    </li>
  );

  return (
    <div className="space-y-6">
      {/* Message Content */}
      <div className="bg-white rounded-lg shadow p-6">
        <h2 className="text-xl font-semibold text-gray-900 mb-4">{message.title}</h2>
        <div className="prose max-w-none text-gray-700">
          {decryptedContent.split('\n').map((line, index) => (
            <p key={index} className="mb-4">
              {line}
            </p>
          ))}
        </div>
      </div>

      {/* Attachments Section */}
      {message.attachments && message.attachments.length > 0 && (
        <div className="bg-white rounded-lg shadow p-6">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg font-semibold text-gray-900">
              Attachments ({message.attachments.length})
            </h3>
            <button
              onClick={() => setShowAttachmentInstructions(!showAttachmentInstructions)}
              className="inline-flex items-center text-sm text-blue-600 hover:text-blue-800"
            >
              {showAttachmentInstructions ? (
                <>
                  <ChevronUpIcon className="h-4 w-4 mr-1" />
                  Hide Instructions
                </>
              ) : (
                <>
                  <ChevronDownIcon className="h-4 w-4 mr-1" />
                  Show Instructions
                </>
              )}
            </button>
          </div>

          {showAttachmentInstructions && (
            <div className="mb-4 bg-blue-50 rounded-md p-4 text-sm">
              <div className="flex items-start">
                <InformationCircleIcon className="h-5 w-5 text-blue-500 mt-0.5 mr-2 flex-shrink-0" />
                <div>
                  <h4 className="font-medium text-blue-900 mb-2">How to access attachments:</h4>
                  <ol className="list-decimal list-inside space-y-1 text-blue-800">
                    <li>Download the encrypted .7z file</li>
                    <li>Use the provided password to extract the file(s) from the .7z archive</li>
                    {message.securityLevel === 'enhanced' && (
                      <li>Use the file decryptor below to decrypt the extracted files</li>
                    )}
                    <li>All decryption happens locally in your browser - no data is sent to our servers</li>
                  </ol>
                </div>
              </div>
            </div>
          )}

          <ul className="space-y-4">
            {message.attachments.map(renderAttachment)}
          </ul>
        </div>
      )}
    </div>
  );
};

export default MessageDisplay;