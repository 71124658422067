import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTriggers, updateTrigger } from '../../store/slices/triggerSlice';
import TriggerCard from '../../components/dashboard/triggers/TriggerCard';
import CollapsibleExplanation from '../../components/common/CollapsibleExplanation';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';

const TriggersPage = () => {
  const dispatch = useDispatch();
  const { triggers, status, error } = useSelector(state => state.triggers);

  useEffect(() => {
    dispatch(fetchTriggers());
  }, [dispatch]);

  const handleSave = (triggerId, type, newSettings) => {
    const triggerToUpdate = triggers.find(trigger => trigger._id === triggerId);
    if (triggerToUpdate) {
      const updatedTrigger = { ...triggerToUpdate };
      if (type === 'Recurring Check-in') {
        updatedTrigger.checkIn = { ...updatedTrigger.checkIn, ...newSettings };
      } else if (type === 'Trusted Consensus') {
        updatedTrigger.consensus = { ...updatedTrigger.consensus, ...newSettings };
      }
      console.log('Updating trigger:', updatedTrigger);
      dispatch(updateTrigger(updatedTrigger))
        .unwrap()
        .then(() => {
          console.log('Trigger updated successfully');
          dispatch(fetchTriggers());  // Fetch updated triggers after successful update
        })
        .catch((error) => {
          console.error('Failed to update trigger:', error);
        });
    }
  };

  if (status === 'loading') {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (status === 'failed') {
    return (
      <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
        <strong className="font-bold">Error!</strong>
        <span className="block sm:inline"> {error}</span>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <CollapsibleExplanation title="Triggers">
        <p>
          Triggers are the mechanisms that determine when your messages will be sent. They ensure that your digital legacy is managed according to your wishes. Here's what you need to know:
        </p>
        <ul className="list-disc list-inside mt-2 space-y-1">
          <li>Recurring Check-in: Regularly confirms your wellbeing. If you miss a check-in, it may activate your messages.</li>
          <li>Trusted Consensus: Allows your designated contacts to collectively decide when to release your messages.</li>
          <li>You can customize the settings for each trigger type to suit your preferences.</li>
          <li>Multiple safeguards are in place to prevent accidental triggering.</li>
        </ul>
        <p className="mt-2">
          Configure your triggers thoughtfully to ensure your messages are delivered at the right time and under the right circumstances.
        </p>
      </CollapsibleExplanation>

      <h2 className="text-2xl font-semibold text-gray-900 mb-6">Your Trigger Settings</h2>

      {triggers.length > 0 ? (
        triggers.map(trigger => (
          <div key={trigger._id} className="mb-8">
            <TriggerCard
              type="Recurring Check-in"
              settings={trigger.checkIn}
              onSave={(newSettings) => handleSave(trigger._id, 'Recurring Check-in', newSettings)}
            />
            <TriggerCard
              type="Trusted Consensus"
              settings={trigger.consensus}
              onSave={(newSettings) => handleSave(trigger._id, 'Trusted Consensus', newSettings)}
            />
          </div>
        ))
      ) : (
        <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mb-8">
          <div className="flex">
            <div className="flex-shrink-0">
              <ExclamationCircleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
            </div>
            <div className="ml-3">
              <p className="text-sm text-yellow-700">
                No trigger settings found. Please contact support if this issue persists.
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TriggersPage;