import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../services/api';

// fetch user profile, get the .data from the response
export const fetchUserProfile = createAsyncThunk('user/fetchProfile', () => api.user.getProfile().then(response => response.data));

export const updateUserProfile = createAsyncThunk('user/updateProfile', (profileData) => api.user.updateProfile(profileData));
export const fetchConnectedEmails = createAsyncThunk('user/fetchConnectedEmails', () => api.user.getConnectedEmails());
export const addConnectedEmail = createAsyncThunk('user/addConnectedEmail', (email) => api.user.addConnectedEmail(email));
export const removeConnectedEmail = createAsyncThunk('user/removeConnectedEmail', (email) => api.user.removeConnectedEmail(email));
export const updateUserSettings = createAsyncThunk('user/updateSettings', (settings) => api.user.updateSettings(settings));
export const updateSubscription = createAsyncThunk('user/updateSubscription', (subscriptionData) => api.user.updateSubscription(subscriptionData));
export const fetchUsageStats = createAsyncThunk('user/fetchUsageStats', () => api.user.getUsageStats());
export const changePassword = createAsyncThunk('user/changePassword', (passwordData) => api.user.changePassword(passwordData));
export const deleteAccount = createAsyncThunk('user/deleteAccount', () => api.user.deleteAccount());

const initialState = {
  profile: null,
  connectedEmails: [],
  settings: {
    notificationPreferences: {
      email: false,
      sms: false,
      push: false,
    },
    twoFactorAuth: {
      isEnabled: false,
      method: 'email',
    },
    language: 'en',
    timezone: 'UTC',
  },
  subscription: null,
  usage: null,
  status: 'idle',
  error: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logout: (state) => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserProfile.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUserProfile.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.profile = action.payload;
        if (action.payload.settings) {
          state.settings = {
            ...state.settings,
            ...action.payload.settings,
          };
        }
        state.subscription = action.payload.subscription;
        state.usage = action.payload.usage;
      })
      .addCase(fetchUserProfile.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        if (action.error.message === 'Unauthorized') {
          return initialState;
        }
      })
      .addCase(updateUserProfile.fulfilled, (state, action) => {
        state.profile = { ...state.profile, ...action.payload };
      })
      .addCase(fetchConnectedEmails.fulfilled, (state, action) => {
        state.connectedEmails = action.payload;
      })
      .addCase(addConnectedEmail.fulfilled, (state, action) => {
        state.connectedEmails = action.payload;
      })
      .addCase(removeConnectedEmail.fulfilled, (state, action) => {
        state.connectedEmails = action.payload;
      })
      .addCase(updateUserSettings.fulfilled, (state, action) => {
        state.settings = {
          ...state.settings,
          ...action.payload,
        };
      })
      .addCase(updateSubscription.fulfilled, (state, action) => {
        state.subscription = action.payload.subscription;
        if (state.profile) {
          state.profile.accountTier = action.payload.accountTier;
        }
      })
      .addCase(fetchUsageStats.fulfilled, (state, action) => {
        state.usage = action.payload;
      });
  },
});

export const { logout } = userSlice.actions;
export default userSlice.reducer;
