import React from 'react';
import { motion } from 'framer-motion';
import { BellIcon, UserGroupIcon, ClockIcon, ShieldCheckIcon } from '@heroicons/react/24/outline';

const TriggerTypes = () => {
  const triggers = [
    {
      name: "Smart Check-in System",
      icon: BellIcon,
      description: "Regular check-ins ensure your well-being, with customizable schedules that fit your lifestyle.",
      features: [
        {
          title: "Flexible Scheduling",
          description: "Choose daily, weekly, or monthly check-ins that work for you"
        },
        {
          title: "Smart Notifications",
          description: "Receive gentle reminders through email, SMS, or app notifications"
        },
        {
          title: "Grace Periods",
          description: "Set comfortable buffer times to prevent accidental triggers"
        }
      ],
      highlight: "96% of users prefer check-ins for peace of mind"
    },
    {
      name: "Trusted Contact Consensus",
      icon: UserGroupIcon,
      description: "Designate trusted individuals who can collectively determine when your messages should be delivered.",
      features: [
        {
          title: "Verified Contacts",
          description: "Add trusted family members, friends, or professionals"
        },
        {
          title: "Consensus Requirements",
          description: "Set how many contacts must agree before action is taken"
        },
        {
          title: "Final Verification",
          description: "Additional safeguard before message release"
        }
      ],
      highlight: "Enhanced security through collective decision-making"
    }
  ];

  return (
    <section className="relative py-24 overflow-hidden bg-gradient-to-b from-white to-gray-50">
      {/* Decorative elements */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute h-full w-full bg-grid-pattern opacity-[0.03] rotate-12"></div>
      </div>

      <div className="relative max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <motion.span
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="inline-block px-4 py-2 rounded-full bg-blue-100 text-blue-700 text-sm font-medium mb-4"
          >
            Intelligent Delivery System
          </motion.span>

          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-4xl font-bold text-gray-900 mb-4"
          >
            Your Messages, Delivered at the Right Time
          </motion.h2>

          <motion.p
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-xl text-gray-600 max-w-2xl mx-auto"
          >
            Choose how you want your messages to be delivered with our intelligent trigger systems
          </motion.p>
        </div>

        <div className="space-y-12">
          {triggers.map((trigger, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, x: index % 2 === 0 ? -20 : 20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              className="relative group"
            >
              <div className="absolute inset-0 bg-gradient-to-r from-blue-50 to-transparent rounded-2xl transform transition-transform group-hover:scale-105 duration-300" />
              
              <div className="relative flex flex-col md:flex-row gap-8 p-8 rounded-2xl">
                <div className="flex-1">
                  <div className="flex items-center mb-6">
                    <div className="flex items-center justify-center w-12 h-12 rounded-xl bg-blue-100 text-blue-600 mr-4">
                      <trigger.icon className="h-6 w-6" />
                    </div>
                    <h3 className="text-2xl font-bold text-gray-900">{trigger.name}</h3>
                  </div>

                  <p className="text-gray-600 mb-6">{trigger.description}</p>

                  <div className="space-y-4">
                    {trigger.features.map((feature, idx) => (
                      <div key={idx} className="flex items-start">
                        <div className="flex-shrink-0 h-6 w-6 rounded-full bg-blue-100 flex items-center justify-center mr-3">
                          <span className="text-sm font-medium text-blue-600">{idx + 1}</span>
                        </div>
                        <div>
                          <h4 className="text-lg font-medium text-gray-900">{feature.title}</h4>
                          <p className="text-gray-600">{feature.description}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="flex-shrink-0 w-full md:w-64">
                  <div className="bg-white rounded-xl p-6 shadow-lg transform transition-transform group-hover:-translate-y-2 duration-300">
                    {index === 0 ? (
                      <div className="space-y-4">
                        <div className="flex items-center justify-between">
                          <span className="text-sm text-gray-500">Check-in Status</span>
                          <span className="text-sm font-medium text-green-600">Active</span>
                        </div>
                        <div className="h-2 bg-gray-100 rounded-full">
                          <div className="h-2 bg-green-500 rounded-full w-3/4"></div>
                        </div>
                        <div className="text-sm text-gray-500">
                          Next check-in: 6 days
                        </div>
                      </div>
                    ) : (
                      <div className="space-y-4">
                        <div className="flex items-center justify-between">
                          <span className="text-sm text-gray-500">Trusted Contacts</span>
                          <span className="text-sm font-medium text-blue-600">3 Verified</span>
                        </div>
                        <div className="flex -space-x-2">
                          {[...Array(3)].map((_, i) => (
                            <div key={i} className="w-8 h-8 rounded-full bg-blue-100 border-2 border-white flex items-center justify-center">
                              <span className="text-xs font-medium text-blue-600">
                                {String.fromCharCode(65 + i)}
                              </span>
                            </div>
                          ))}
                        </div>
                        <div className="text-sm text-gray-500">
                          Consensus required: 2/3
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="mt-4 text-sm text-center text-gray-500">
                    {trigger.highlight}
                  </div>
                </div>
              </div>
            </motion.div>
          ))}
        </div>

        {/* Call to action */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="mt-16 text-center"
        >
          <a
            href="/signup"
            className="inline-flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 transform hover:scale-105 transition-all duration-200 shadow-lg hover:shadow-xl"
          >
            Set Up Your Triggers
            <span className="ml-2">→</span>
          </a>
          <p className="mt-4 text-sm text-gray-500">
            Takes less than 5 minutes to configure
          </p>
        </motion.div>
      </div>
    </section>
  );
};

export default TriggerTypes;