import React from 'react';
import { motion } from 'framer-motion';
import { ShieldCheckIcon, LockClosedIcon } from '@heroicons/react/24/outline';

const SecurityLevels = () => {
  return (
    <section className="relative py-24 overflow-hidden">
      {/* Background decoration */}
      <div className="absolute inset-0 bg-gradient-to-br from-gray-50 to-blue-50">
        <div className="absolute inset-0" style={{
          backgroundImage: `url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%234B5563' fill-opacity='0.03'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
        }}></div>
      </div>

      <div className="relative max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <motion.span
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="inline-block px-4 py-2 rounded-full bg-blue-100 text-blue-700 text-sm font-medium mb-4"
          >
            Military-Grade Protection
          </motion.span>

          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-4xl font-bold text-gray-900 mb-4"
          >
            Choose Your Security Level
          </motion.h2>

          <motion.p
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-xl text-gray-600 max-w-2xl mx-auto"
          >
            From standard protection to enhanced privacy, we ensure your messages and attachments are secure until the moment they're needed.
          </motion.p>
        </div>

        <div className="grid md:grid-cols-2 gap-8">
          {[
            {
              name: "Standard Security",
              icon: ShieldCheckIcon,
              description: "Essential protection for your digital legacy",
              features: [
                "Server-side 256-bit encryption",
                "Secure file storage with 7z encryption",
                "Protected attachment delivery",
                "Automatic password management",
                "Regular security audits"
              ],
              highlight: false
            },
            {
              name: "Enhanced Security",
              icon: LockClosedIcon,
              description: "Maximum privacy with local encryption",
              features: [
                "Local encryption before upload",
                "End-to-end encryption",
                "Two-factor authentication",
                "Zero-knowledge architecture",
                "Advanced access controls"
              ],
              highlight: true
            }
          ].map((level, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.2 }}
              className={`relative group rounded-2xl transition-all duration-300 ${
                level.highlight 
                  ? 'bg-gradient-to-br from-blue-600 to-blue-700 text-white transform hover:-translate-y-1'
                  : 'bg-white hover:bg-blue-50 transform hover:-translate-y-1'
              }`}
            >
              <div className={`absolute inset-0 rounded-2xl transition-opacity duration-300 ${
                level.highlight
                  ? 'opacity-0 group-hover:opacity-100 bg-gradient-to-br from-blue-500 to-blue-600'
                  : 'opacity-0 group-hover:opacity-100 bg-gradient-to-br from-gray-50 to-blue-50'
              }`}></div>

              <div className="relative p-8">
                <level.icon className={`h-12 w-12 mb-6 ${
                  level.highlight ? 'text-white' : 'text-blue-600'
                }`} />

                <h3 className={`text-2xl font-bold mb-4 ${
                  level.highlight ? 'text-white' : 'text-gray-900'
                }`}>
                  {level.name}
                </h3>

                <p className={`mb-8 ${
                  level.highlight ? 'text-blue-100' : 'text-gray-600'
                }`}>
                  {level.description}
                </p>

                <ul className="space-y-4">
                  {level.features.map((feature, idx) => (
                    <li key={idx} className="flex items-start">
                      <svg className={`h-6 w-6 mr-3 ${
                        level.highlight ? 'text-blue-200' : 'text-blue-500'
                      }`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                      </svg>
                      <span className={
                        level.highlight ? 'text-blue-100' : 'text-gray-600'
                      }>
                        {feature}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </motion.div>
          ))}
        </div>

        {/* Additional trust elements */}
        <motion.div
  initial={{ opacity: 0 }}
  whileInView={{ opacity: 1 }}
  viewport={{ once: true }}
  className="mt-16 flex flex-col sm:flex-row justify-center items-center gap-4 sm:gap-8 text-gray-500"
>
  <p className="text-sm text-center mb-4 sm:mb-0">We follow the standards for:</p>
  <div className="flex flex-wrap justify-center gap-4">
    <div className="flex items-center bg-white/80 backdrop-blur-sm px-4 py-2 rounded-lg shadow-sm">
      <ShieldCheckIcon className="h-5 w-5 text-blue-600 mr-2 flex-shrink-0" />
      <span className="text-sm whitespace-nowrap">SOC 2 Type II</span>
    </div>
    <div className="flex items-center bg-white/80 backdrop-blur-sm px-4 py-2 rounded-lg shadow-sm">
      <LockClosedIcon className="h-5 w-5 text-blue-600 mr-2 flex-shrink-0" />
      <span className="text-sm whitespace-nowrap">GDPR Compliant</span>
    </div>
    <div className="flex items-center bg-white/80 backdrop-blur-sm px-4 py-2 rounded-lg shadow-sm">
      <svg
        className="h-5 w-5 text-blue-600 mr-2 flex-shrink-0"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
        />
      </svg>
      <span className="text-sm whitespace-nowrap">ISO 27001</span>
    </div>
  </div>
</motion.div>

        {/* Security explanation */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="mt-16 bg-white/80 backdrop-blur-sm rounded-xl p-8 shadow-lg"
        >
          <div className="flex items-start space-x-4">
            <div className="flex-shrink-0">
              <div className="h-12 w-12 bg-blue-100 rounded-lg flex items-center justify-center">
                <LockClosedIcon className="h-6 w-6 text-blue-600" />
              </div>
            </div>
            <div>
              <h4 className="text-lg font-semibold text-gray-900 mb-2">
                Why Security Matters for Digital Legacy
              </h4>
              <p className="text-gray-600 mb-4">
                Your final messages often contain sensitive information, personal wishes, and private documents. 
                Our enhanced security ensures these stay completely private until the exact moment they're meant 
                to be delivered.
              </p>
              <a href="security" className="inline-flex items-center text-blue-600 hover:text-blue-700">
                Learn more about our security
                <svg className="ml-2 w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                </svg>
              </a>
            </div>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default SecurityLevels;
