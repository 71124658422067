import React, { useEffect, useState } from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import stripeService from '../../services/stripeService';
import { formatFirestoreDate } from '../../utils/firestoreUtils';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import PaymentForm from '../../components/subscription/PaymentForm';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const SubscriptionPage = () => {
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState('premium');

  useEffect(() => {
    fetchSubscriptionData();
  }, []);

  const fetchSubscriptionData = async () => {
    setLoading(true);
    try {
      const data = await stripeService.getSubscriptionStatus();
      setSubscriptionData(data);
    } catch (err) {
      setError('Failed to fetch subscription data. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleUpgrade = () => {
    setShowPaymentForm(true);
  };

  const handlePaymentMethodReceived = async (paymentMethodId) => {
    try {
      const priceId =
        selectedPlan === 'premium'
          ? process.env.REACT_APP_STRIPE_PRICE_PREMIUM
          : process.env.REACT_APP_STRIPE_PRICE_ESSENTIAL;

      const response = await stripeService.createSubscription(priceId, paymentMethodId);
      console.log('Subscription created:', response);

      setShowPaymentForm(false);
      fetchSubscriptionData();
    } catch (err) {
      console.error('Error creating subscription:', err);
      setError('Failed to create subscription. Please try again later.');
    }
  };

  const handleCancel = async () => {
    try {
      await stripeService.cancelSubscription();
      fetchSubscriptionData();
    } catch (err) {
      console.error('Error canceling subscription:', err);
      setError('Failed to cancel subscription. Please try again later.');
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div
        className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
        role="alert"
      >
        <strong className="font-bold">Error!</strong>
        <span className="block sm:inline"> {error}</span>
      </div>
    );
  }

  if (!subscriptionData) {
    return null; // Handle case when data is not available
  }

  const {
    accountTier,
    storage,
    subscription,
    subscriptionDaysRemaining,
    trial,
    trialDaysRemaining,
  } = subscriptionData;

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <h1 className="text-3xl font-bold text-gray-900 mb-6">Manage Your Subscription</h1>

      <div className="bg-white shadow overflow-hidden sm:rounded-lg mb-8 border border-gray-200">
        <div className="px-4 py-5 sm:p-6">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">Subscription Details</h2>
          <p className="text-gray-600 mb-2">
            <strong>Account Tier:</strong>{' '}
            {accountTier.charAt(0).toUpperCase() + accountTier.slice(1)}
          </p>
          <p className="text-gray-600 mb-2">
            <strong>Status:</strong>{' '}
            {subscription.status.charAt(0).toUpperCase() + subscription.status.slice(1)}
          </p>
          <p className="text-gray-600 mb-2">
            <strong>Trial:</strong>{' '}
            {trialDaysRemaining > 0
              ? `${trialDaysRemaining} days remaining`
              : trial.hasHadTrial
              ? 'Trial completed'
              : 'No trial available'}
          </p>
          <p className="text-gray-600 mb-2">
            <strong>Subscription Renewal:</strong>{' '}
            {subscription.autoRenew ? 'Enabled' : 'Disabled'}
          </p>
          <p className="text-gray-600 mb-2">
            <strong>Current Period End:</strong>{' '}
            {formatFirestoreDate(subscription.currentPeriodEnd)}
          </p>
          <p className="text-gray-600 mb-2">
            <strong>Storage Used:</strong>{' '}
            {`${(storage.used / 1024 / 1024).toFixed(2)} MB`} of{' '}
            {`${(storage.limit / 1024 / 1024).toFixed(2)} MB`}
          </p>
        </div>
      </div>

      <div className="flex space-x-4">
        {!showPaymentForm && (
          <>
            <button
              onClick={handleUpgrade}
              className="px-4 py-2 text-sm font-medium text-white rounded-lg bg-gradient-to-r from-blue-600 to-blue-500 hover:from-blue-800 hover:to-blue-700 transition-colors"
            >
              Upgrade Subscription
            </button>
            <button
              onClick={handleCancel}
              className="px-4 py-2 text-sm font-medium text-white rounded-lg bg-red-600 hover:bg-red-700 transition-colors"
            >
              Cancel Subscription
            </button>
          </>
        )}
      </div>

      {showPaymentForm && (
        <div className="mt-8">
          <Elements stripe={stripePromise}>
            <PaymentForm onPaymentMethodReceived={handlePaymentMethodReceived} />
          </Elements>
        </div>
      )}
    </div>
  );
};

export default SubscriptionPage;
