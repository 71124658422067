// DecryptionKeyInput.jsx
import React, { useState } from 'react';
import { KeyIcon, InformationCircleIcon } from '@heroicons/react/24/outline';

const DecryptionKeyInput = ({ onSubmit }) => {
  const [decryptionKey, setDecryptionKey] = useState('');
  const [showInstructions, setShowInstructions] = useState(true);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(decryptionKey);
  };

  return (
    <div className="space-y-4 max-w-2xl mx-auto">
      <div className="bg-blue-50 rounded-lg p-6 mb-6">
        <div className="flex items-start">
          <InformationCircleIcon className="h-6 w-6 text-blue-500 mt-0.5 mr-3" />
          <div>
            <h4 className="text-lg font-medium text-blue-900 mb-2">Decryption Instructions</h4>
            <ul className="list-disc list-inside space-y-2 text-blue-800">
              <li>Enter the decryption key that was shared with you by the message sender</li>
              <li>This key is required to access the encrypted message contents</li>
              <li>The decryption happens locally in your browser - we never see the decrypted content</li>
              <li>This key is not retrievable by Cryonote - by design.</li>
            </ul>
          </div>
        </div>
      </div>

      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="decryptionKey" className="block text-lg font-medium text-gray-700 mb-2">
            Enter Decryption Key
          </label>
          <div className="relative rounded-md shadow-sm">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <KeyIcon className="h-5 w-5 text-gray-400" />
            </div>
            <input
              id="decryptionKey"
              type="password"
              value={decryptionKey}
              onChange={(e) => setDecryptionKey(e.target.value)}
              className="block w-full pl-10 pr-4 py-3 text-lg border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
              placeholder="Paste your decryption key here"
              required
            />
          </div>
        </div>
        <button
          type="submit"
          className="w-full flex justify-center py-3 px-4 border border-transparent rounded-lg shadow-sm text-lg font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Decrypt Message
        </button>
      </form>
    </div>
  );
};

export default DecryptionKeyInput;