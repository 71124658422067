import React, { useState } from 'react';
import { PencilIcon, TrashIcon, EyeIcon, EnvelopeIcon, ChatBubbleLeftRightIcon, LockClosedIcon, PaperAirplaneIcon } from '@heroicons/react/24/outline';
import { Switch } from '@headlessui/react';
import api from '../../../services/api';
import { toast } from 'react-toastify';

const MessageCard = ({ message, onView, onEdit, onDelete, onStatusChange }) => {
  const [isSendingTest, setIsSendingTest] = useState(false);

  // Helper function to format Firestore timestamp with hours and minutes
  const formatFirestoreDate = (firestoreTimestamp) => {
    if (!firestoreTimestamp) return 'N/A';
    
    try {
      const timestamp = firestoreTimestamp._seconds 
        ? new Date(firestoreTimestamp._seconds * 1000) 
        : new Date(firestoreTimestamp);

      const now = new Date();
      const isToday = timestamp.toDateString() === now.toDateString();

      if (isToday) {
        return timestamp.toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
          hour12: true
        });
      } else {
        return timestamp.toLocaleDateString([], {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric'
        });
      }
    } catch (error) {
      console.error('Error formatting timestamp:', error);
      return 'Invalid date';
    }
  };

  const handleSendTestMessage = async () => {
    const messageId = message.id || message._id; // Ensure message ID is defined
    if (!messageId) {
      toast.error('Message ID is missing. Unable to send test message.');
      return;
    }
  
    setIsSendingTest(true); // Start sending feedback
    try {
      // Make API request to send the test message
      await api.post(`/api/messages/${messageId}/send-test`);
  
      // Notify success
      toast.success('Test message sent successfully. Please check your email.');
    } catch (error) {
      // Log the error and notify the user
      console.error('Error sending test message:', error);
      toast.error('Failed to send test message. Please try again.');
    } finally {
      setIsSendingTest(false); // Reset sending state
    }
  };

  const handleStatusChange = () => {
    const messageId = message.id || message._id;
    if (!messageId) {
      console.error('No message ID found:', message);
      return;
    }
    onStatusChange(messageId, message.status === 'draft' ? 'ready' : 'draft');
  };

  return (
    <div className="bg-white shadow-md hover:shadow-lg transition-shadow duration-300 overflow-hidden rounded-lg">
      <div className="px-6 py-4">
        <div className="flex justify-between items-start mb-2">
          <h3 className="text-lg font-semibold text-gray-900 flex items-center">
            {message.type === 'sms' ? (
              <ChatBubbleLeftRightIcon className="h-5 w-5 mr-2 text-green-500" />
            ) : (
              <EnvelopeIcon className="h-5 w-5 mr-2 text-blue-500" />
            )}
            {message.title}
            {message.securityLevel === 'enhanced' && (
              <LockClosedIcon className="h-4 w-4 ml-2 text-green-500" title="Enhanced encryption" />
            )}
          </h3>
          <div className="flex items-center">
            <span className={`text-xs font-medium mr-2 px-2.5 py-0.5 rounded ${
              message.status === 'draft' ? 'bg-yellow-100 text-yellow-800' : 'bg-green-100 text-green-800'
            }`}>
              {message.status === 'draft' ? 'Draft' : 'Ready'}
            </span>
            <Switch
              checked={message.status === 'ready'}
              onChange={handleStatusChange}
              className={`${
                message.status === 'ready' ? 'bg-green-600' : 'bg-gray-200'
              } relative inline-flex h-5 w-9 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500`}
            >
              <span className="sr-only">Toggle message status</span>
              <span
                className={`${
                  message.status === 'ready' ? 'translate-x-5' : 'translate-x-0'
                } inline-block h-3 w-3 transform rounded-full bg-white transition-transform`}
              />
            </Switch>
          </div>
        </div>
        <p className="text-sm text-gray-600 mb-4">
          To: {message.recipients.join(', ')}
        </p>
        <div className="flex justify-end space-x-3">
          <button 
            onClick={() => onView(message)} 
            className="px-3 py-2 text-sm font-medium text-gray-600 bg-gray-100 rounded-lg hover:bg-gray-200 transition-colors"
            title="View message"
          >
            <EyeIcon className="h-5 w-5" />
          </button>
          <button 
            onClick={() => onEdit(message)} 
            className="px-3 py-2 text-sm font-medium text-gray-600 bg-gray-100 rounded-lg hover:bg-gray-200 transition-colors"
            title="Edit message"
          >
            <PencilIcon className="h-5 w-5" />
          </button>
          <button 
            onClick={() => onDelete(message)} 
            className="px-3 py-2 text-sm font-medium text-gray-600 bg-gray-100 rounded-lg hover:bg-gray-200 transition-colors"
            title="Delete message"
          >
            <TrashIcon className="h-5 w-5" />
          </button>
          <button 
            onClick={handleSendTestMessage} 
            disabled={isSendingTest}
            className={`px-4 py-2 text-sm font-medium text-white rounded-lg transition-colors ${
              isSendingTest
                ? 'bg-gray-400 cursor-not-allowed'
                : 'bg-gradient-to-r from-blue-600 to-blue-500 hover:from-blue-800 hover:to-blue-700'
            }`}
            title="Send test message to your accounts email"
          >
            {isSendingTest ? 'Sending...' : 'Send Test'}
          </button>
        </div>
      </div>
      <div className="px-6 py-2 bg-gray-50 border-t border-gray-100">
        <div className="grid grid-cols-2 gap-2 text-xs text-gray-500">
          <div className="flex flex-col">
            <span className="font-medium text-gray-600">Created: {formatFirestoreDate(message.createdAt)}</span>
          </div>
          <div className="flex flex-col text-right">
            <span className="font-medium text-gray-600">Last Updated: {formatFirestoreDate(message.updatedAt)}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessageCard;
