import React from 'react';
import { motion } from 'framer-motion';
import { 
  ShieldCheckIcon, 
  HeartIcon, 
  ArrowRightIcon,
  DocumentCheckIcon,
  ClockIcon
} from '@heroicons/react/24/outline';
import { StarIcon } from '@heroicons/react/24/solid';

const FinalCTA = () => {
  const highlights = [
    {
      icon: ShieldCheckIcon,
      title: "Bank-Grade Security",
      description: "256-bit encryption with zero-knowledge architecture"
    },
    {
      icon: DocumentCheckIcon,
      title: "Message Control",
      description: "Edit or update your messages anytime"
    },
    {
      icon: ClockIcon,
      title: "Smart Delivery",
      description: "Multiple verification layers prevent accidents"
    }
  ];

  const testimonial = {
    quote: "Cryonote gives me peace of mind knowing my family will receive my messages exactly when they need them.",
    author: "Sarah Mitchell",
    role: "Business Owner & Parent",
    rating: 5
  };

  return (
    <section className="relative py-24 bg-gradient-to-br from-blue-600 to-blue-700">
      {/* Decorative elements */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-[linear-gradient(30deg,theme(colors.blue.600/90%),theme(colors.blue.600/30%))]" />
        <div className="absolute inset-y-0 right-0 w-1/2 bg-[radial-gradient(circle_at_center,theme(colors.blue.400/30%),transparent)]" />
        <div 
          className="absolute inset-0 bg-repeat opacity-5"
          style={{
            backgroundImage: `url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23fff' fill-opacity='1' fill-rule='evenodd'%3E%3Ccircle cx='3' cy='3' r='3'/%3E%3C/g%3E%3C/svg%3E")`,
            backgroundSize: '20px 20px'
          }}
        />
      </div>

      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center mb-12"
        >
          <h2 className="text-3xl sm:text-4xl font-bold text-white mb-4">
            Secure Your Legacy, <span className="text-blue-200">Protect Their Future</span>
          </h2>
          <p className="text-xl text-blue-100 max-w-3xl mx-auto">
          Protect Your Legacy with Cryonote's Trusted Platform
          </p>
        </motion.div>

        <div className="grid md:grid-cols-2 gap-12 items-start">
          {/* Left Column - Feature Highlights */}
          <div className="space-y-6">
            {highlights.map((highlight, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, x: -20 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                className="bg-white/10 backdrop-blur-sm rounded-xl p-6 hover:bg-white/20 transition-colors duration-200"
              >
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center h-12 w-12 rounded-lg bg-blue-500/30">
                      <highlight.icon className="h-6 w-6 text-blue-100" />
                    </div>
                  </div>
                  <div className="ml-4">
                    <h3 className="text-lg font-semibold text-white">
                      {highlight.title}
                    </h3>
                    <p className="mt-1 text-blue-100">
                      {highlight.description}
                    </p>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>

          {/* Right Column - CTA and Testimonial */}
          <div className="space-y-8">
            <motion.div
              initial={{ opacity: 0, x: 20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              className="bg-white/10 backdrop-blur-sm rounded-xl p-6"
            >
              <div className="text-center mb-6">
                <span className="inline-block px-4 py-2 rounded-full bg-blue-500/30 text-white text-sm font-medium mb-4">
                  Start Your Free Trial Today
                </span>
                <div className="flex flex-col sm:flex-row gap-4 justify-center">
                  <motion.a
                    href="/signup"
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    className="inline-flex items-center justify-center px-6 py-3 rounded-xl bg-white text-blue-600 font-semibold shadow-lg hover:shadow-xl transition-all duration-200"
                  >
                    Start Free Trial
                    <ArrowRightIcon className="ml-2 h-5 w-5" />
                  </motion.a>
                  <motion.a
                    href="#demo"
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    className="inline-flex items-center justify-center px-6 py-3 rounded-xl bg-blue-500/30 text-white font-semibold hover:bg-blue-500/40 transition-all duration-200"
                  >
                    Schedule Demo
                  </motion.a>
                </div>
              </div>
              <div className="flex justify-center space-x-6 text-blue-100 text-sm">
                <div className="flex items-center">
                  <ShieldCheckIcon className="h-5 w-5 mr-2" />
                  <span>14-day free trial</span>
                </div>
                <div className="flex items-center">
                  <HeartIcon className="h-5 w-5 mr-2" />
                  <span>No credit card required</span>
                </div>
              </div>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              className="bg-white/10 backdrop-blur-sm rounded-xl p-6"
            >
              <div className="flex mb-3 justify-center">
                {[...Array(testimonial.rating)].map((_, i) => (
                  <StarIcon key={i} className="h-5 w-5 text-yellow-400" />
                ))}
              </div>
              <p className="text-blue-50 italic mb-4 text-center">"{testimonial.quote}"</p>
              <div className="text-blue-200 text-sm text-center">
                {testimonial.author} • {testimonial.role}
              </div>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              className="grid grid-cols-3 gap-4"
            >
              <div className="bg-white/10 backdrop-blur-sm rounded-xl p-4 text-center">
                <div className="text-2xl font-bold text-white">AES256</div>
                <div className="text-sm text-blue-100">Encryption Standard</div>
              </div>
              <div className="bg-white/10 backdrop-blur-sm rounded-xl p-4 text-center">
                <div className="text-2xl font-bold text-white">4.9/5</div>
                <div className="text-sm text-blue-100">User Rating</div>
              </div>
              <div className="bg-white/10 backdrop-blur-sm rounded-xl p-4 text-center">
                <div className="text-2xl font-bold text-white">99.9%</div>
                <div className="text-sm text-blue-100">Uptime</div>
              </div>
            </motion.div>
          </div>
        </div>

        {/* Bottom Section - Final Push */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="mt-16 text-center"
        >
          <div className="bg-white/10 backdrop-blur-sm rounded-xl p-8 inline-block">
            <h3 className="text-xl font-semibold text-white mb-4">
              Need More Information?
            </h3>
            <p className="text-blue-100 mb-6">
              Our team is here to help you make the right choice for your digital legacy.
            </p>
            <div className="flex justify-center space-x-4">
              <a
                href="/contact"
                className="inline-flex items-center px-6 py-3 rounded-lg bg-white/10 text-white hover:bg-white/20 transition-colors duration-200"
              >
                Contact Support
              </a>
              <a
                href="#faq"
                className="inline-flex items-center px-6 py-3 rounded-lg bg-white/10 text-white hover:bg-white/20 transition-colors duration-200"
              >
                View FAQ
              </a>
            </div>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default FinalCTA;
