import React from 'react';
import {
  ChevronRightIcon,
  QuestionMarkCircleIcon,
  ShieldCheckIcon,
  CurrencyDollarIcon,
  ClockIcon,
  KeyIcon,
  SupportIcon,
  UserCircleIcon,
} from '@heroicons/react/24/outline';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const FAQPage = () => {
  const sections = [
    { id: 'general', title: '1. General Questions' },
    { id: 'security', title: '2. Security & Privacy' },
    { id: 'pricing', title: '3. Pricing & Plans' },
    { id: 'triggers', title: '4. Message Triggers' },
    { id: 'technical', title: '5. Technical Details' },
    { id: 'account', title: '6. Account Management' },
    { id: 'support', title: '7. Support & Resources' },
  ];
  

  const faqs = {
    general: [
      {
        question: 'What is Cryonote and how does it work?',
        answer:
          'Cryonote is a secure platform for posthumous message delivery. It allows you to create messages and digital assets that will be delivered to your designated recipients under predefined conditions or triggers. You can think of it as a digital safety deposit box for your important information.',
      },
      {
        question: 'Who can benefit from using Cryonote?',
        answer:
          'Individuals and businesses looking to manage their digital legacy can benefit from Cryonote. It is ideal for personal messages, asset transfers, business continuity plans, and more. Whether you want to leave final messages to loved ones or ensure your business operations continue smoothly, Cryonote has you covered.',
      },
      {
        question: 'Is there a mobile app available?',
        answer:
          'Currently, Cryonote is accessible through our web platform, which is optimized for mobile browsers. We are working on developing dedicated mobile apps for iOS and Android to enhance accessibility and user experience.',
      },
      {
        question: 'Can I use Cryonote internationally?',
        answer:
          'Yes, Cryonote is available to users worldwide. Our platform supports multiple languages and complies with international data protection regulations, including GDPR.',
      },
      {
        question: 'How do I get started with Cryonote?',
        answer:
          'Getting started is easy. Simply sign up for an account on our website, choose your preferred plan, and begin creating messages. Our user-friendly interface guides you through each step.',
      },
    ],
    security: [
      {
        question: 'How secure is my data with Cryonote?',
        answer:
          'We use multiple layers of security to protect your data:\n\n▸ 256-bit AES encryption for all data at rest.\n▸ TLS 1.3 encryption for data in transit.\n▸ Optional local encryption before upload with our Enhanced Security plan.\n▸ Zero-knowledge architecture, meaning we cannot access your unencrypted data.\n▸ Regular security audits and compliance with industry standards like SOC 2 Type II and ISO 27001.\n\nYour data is encrypted both in transit and at rest, ensuring maximum protection.',
      },
      {
        question: 'What encryption methods do you use?',
        answer:
          'We employ industry-standard encryption methods:\n\n▸ AES-256 for data at rest.\n▸ TLS 1.3 for data in transit.\n▸ Client-side encryption using AES-GCM for Enhanced Security users.\n\nAll encryption meets or exceeds NIST standards.',
      },
      {
        question: 'Can Cryonote employees access my messages?',
        answer:
          'No. With our Enhanced Security plan, your messages are encrypted locally before being uploaded to our servers. We never have access to your encryption keys or unencrypted content. Even with our Standard Security plan, we prioritize your privacy and have strict protocols to prevent unauthorized access.',
      },
      {
        question: 'How do you handle data breaches?',
        answer:
          'In the unlikely event of a data breach, we have a comprehensive incident response plan in place:\n\n▸ Immediate assessment and containment of the breach.\n▸ Prompt notification to affected users.\n▸ Detailed investigation to determine the scope and cause.\n▸ Implementation of measures to prevent future incidents.\n\nWe are committed to transparency and will keep you informed throughout the process.',
      },
      {
        question: 'Do you comply with GDPR and other international regulations?',
        answer:
          'Yes, we comply with GDPR and other international data protection regulations. We respect your privacy rights and provide tools for data access, portability, and deletion.',
      },
    ],
    pricing: [
      {
        question: 'What plans do you offer and what are their features?',
        answer:
          'We offer three main plans:\n\n▸ Essential: Basic features with Standard Security.\n▸ Enhanced Security: Advanced security features, including client-side encryption.\n▸ Enterprise: Custom solutions tailored to your business needs.\n\nEach plan varies in features such as storage limits, file attachment sizes, and support levels.',
      },
      {
        question: "What's included in the free trial?",
        answer:
          'Our 14-day free trial includes all features of the Enhanced Security plan:\n\n▸ Unlimited messages.\n▸ All security features.\n▸ Full access to the trigger system.\n▸ 1GB file attachments.\n\nNo credit card is required to start the trial.',
      },
      {
        question: 'Can I change or cancel my plan at any time?',
        answer:
          'Yes, you can upgrade, downgrade, or cancel your plan at any time through your account settings. Upgrades take effect immediately, while downgrades and cancellations become effective at the end of the current billing cycle.',
      },
      {
        question: 'Do you offer discounts for annual subscriptions or nonprofits?',
        answer:
          'Yes, we offer a discount for annual subscriptions. Additionally, we provide special rates for nonprofit organizations and educational institutions. Please contact our support team for more information.',
      },
      {
        question: 'What happens if I miss a payment?',
        answer:
          'We provide a 7-day grace period for missed payments. During this time, all your messages and settings remain intact. We will send multiple notifications before any service interruption. Your data is never deleted without explicit confirmation from you.',
      },
    ],
    triggers: [
      {
        question: 'How does message delivery work?',
        answer:
          'Message delivery is based on triggers that you set up. Triggers can be time-based (e.g., on a specific date), inactivity-based (e.g., no login for a period), or event-based (e.g., confirmation from trusted contacts). Once the trigger conditions are met, your messages are securely delivered to designated recipients.',
      },
      {
        question: 'What prevents messages from being triggered accidentally?',
        answer:
          'We have multiple safeguards in place:\n\n▸ Multiple verification steps before delivery.\n▸ Customizable grace periods to allow for false alarms.\n▸ Secondary confirmation requirements from trusted contacts.\n▸ Override options with security phrases.\n\nThese measures help ensure that messages are only delivered under the correct circumstances.',
      },
      {
        question: 'Can I customize the triggers for message delivery?',
        answer:
          'Yes, you have full control over your triggers. You can set up various types of triggers, combine multiple conditions, and adjust settings like grace periods and verification methods to suit your needs.',
      },
      {
        question: 'How does the consensus trigger work?',
        answer:
          'The consensus trigger requires confirmation from multiple trusted contacts before any messages are delivered. You can set:\n\n▸ The number of required confirmations.\n▸ Verification methods (e.g., email, phone call).\n▸ Confirmation timeframes.\n▸ Backup contacts.\n\nThis provides an additional layer of security and ensures that messages are only delivered when appropriate.',
      },
    ],
    technical: [
      {
        question: 'What are the system requirements for using Cryonote?',
        answer:
          'Cryonote is a web-based application accessible through modern browsers like Chrome, Firefox, Safari, and Edge. No additional software is required. For the best experience, we recommend keeping your browser up to date.',
      },
      {
        question: 'Can I export or back up my data?',
        answer:
          'Yes, you can export all your data at any time. This includes message contents, trigger configurations, contact lists, and activity logs. Data is exported in standard formats (e.g., JSON, CSV) for easy backup and portability.',
      },
      {
        question: 'Do you integrate with other platforms or services?',
        answer:
          'We are working on integrations with popular platforms and services to enhance functionality. Currently, you can import contacts from services like Google Contacts. Stay tuned for updates on new integrations.',
      },
      {
        question: 'How do you ensure data integrity over time?',
        answer:
          'We use robust storage solutions with data redundancy and regular backups. Our infrastructure is designed to protect against data loss due to hardware failures, natural disasters, or other unforeseen events.',
      },
      {
        question: 'Is there an API available for developers?',
        answer:
          'We offer an API for Enterprise clients who need custom integrations. Please contact us for more information and documentation.',
      },
    ],
    account: [
      {
        question: 'How do I reset my password?',
        answer:
          'You can reset your password by clicking on the "Forgot Password" link on the login page. You will receive an email with instructions to reset your password securely.',
      },
      {
        question: 'What happens if I forget my encryption keys?',
        answer:
          'For Enhanced Security users, encryption keys are crucial for accessing your data. Unfortunately, due to the zero-knowledge architecture, we cannot recover lost encryption keys. We recommend securely storing a backup of your keys.',
      },
      {
        question: 'Can I have multiple users on one account?',
        answer:
          'Our Enterprise plan supports multiple user accounts with role-based access control. This is ideal for businesses that require team collaboration and administrative oversight.',
      },
      {
        question: 'How do I update my billing information?',
        answer:
          'You can update your billing information by navigating to the "Account Settings" section in your dashboard and selecting "Billing." From there, you can update your payment method and view billing history.',
      },
      {
        question: 'How do I delete my account?',
        answer:
          'If you wish to delete your account, please contact our support team. They will guide you through the process and ensure that all your data is securely erased.',
      },
    ],
    support: [
      {
        question: 'How can I contact customer support?',
        answer:
          'You can reach our customer support team via email at support@cryonote.com or through the contact form on our website. We are available 24/7 to assist you with any questions or issues.',
      },
      {
        question: 'Do you offer onboarding assistance or tutorials?',
        answer:
          'Yes, we offer onboarding assistance to help you get started. Our Help Center includes tutorials, guides, and FAQs. For personalized assistance, feel free to contact our support team.',
      },
      {
        question: 'Where can I find your Terms of Service and Privacy Policy?',
        answer:
          'You can find our Terms of Service [here](/terms-of-service) and our Privacy Policy [here](/privacy-policy). We encourage you to read them to understand our policies.',
      },
      {
        question: 'How do I report a bug or security issue?',
        answer:
          'We take security seriously. If you discover a bug or security vulnerability, please report it immediately to security@cryonote.com. We appreciate your help in keeping our platform secure.',
      },
      {
        question: 'Do you have a status page for system updates?',
        answer:
          'Yes, you can view real-time system status and updates on our [Status Page](/status). We also post maintenance notices and service interruptions there.',
      },
    ],
  };

  const scrollToSection = (sectionId) => {
    document.getElementById(sectionId).scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <Navbar />

      <main className="pt-16 pb-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="lg:grid lg:grid-cols-12 lg:gap-8">
            {/* Sidebar Navigation */}
            <div className="hidden lg:block lg:col-span-3">
              <nav className="sticky top-20 divide-y divide-gray-200">
                <div className="pb-8">
                  <h2 className="text-sm font-semibold text-gray-900">
                    On this page
                  </h2>
                  <ul className="mt-4 space-y-2">
                    {sections.map((section) => (
                      <li key={section.id}>
                        <button
                          onClick={() => scrollToSection(section.id)}
                          className="group flex items-center text-sm text-gray-600 hover:text-gray-900"
                        >
                          <ChevronRightIcon
                            className="h-4 w-4 mr-2 text-gray-400 group-hover:text-gray-900"
                          />
                          {section.title}
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              </nav>
            </div>

            {/* Main Content */}
            <div className="lg:col-span-9">
              {/* Header Section */}
              <div className="bg-white rounded-xl shadow-sm p-8 mb-8">
                <h1 className="text-3xl font-bold text-gray-900 mb-4">
                  Frequently Asked Questions
                </h1>
                <p className="text-gray-600">
                  Find answers to common questions about Cryonote. If you can't find what you're looking for, feel free to{' '}
                  <a href="/contact" className="text-blue-600 hover:text-blue-800">
                    contact us
                  </a>
                  .
                </p>
              </div>

              {/* Content Sections */}
              <div
                id="faq-content"
                className="bg-white rounded-xl shadow-sm p-8 max-w-none"
              >
                {/* General Questions */}
                <section id="general" className="mb-12">
                  <h2 className="text-2xl font-bold mb-6">
                    1. General Questions
                  </h2>
                  {faqs.general.map((faq, index) => (
                    <div key={index} className="mb-8">
                      <h3 className="text-xl font-semibold text-gray-900 mb-2">
                        {faq.question}
                      </h3>
                      <p className="text-gray-600 whitespace-pre-line">
                        {faq.answer}
                      </p>
                    </div>
                  ))}
                </section>

                {/* Security & Privacy */}
                <section id="security" className="mb-12">
                  <h2 className="text-2xl font-bold mb-6">
                    2. Security & Privacy
                  </h2>
                  {faqs.security.map((faq, index) => (
                    <div key={index} className="mb-8">
                      <h3 className="text-xl font-semibold text-gray-900 mb-2">
                        {faq.question}
                      </h3>
                      <p className="text-gray-600 whitespace-pre-line">
                        {faq.answer}
                      </p>
                    </div>
                  ))}
                </section>

                {/* Pricing & Plans */}
                <section id="pricing" className="mb-12">
                  <h2 className="text-2xl font-bold mb-6">
                    3. Pricing & Plans
                  </h2>
                  {faqs.pricing.map((faq, index) => (
                    <div key={index} className="mb-8">
                      <h3 className="text-xl font-semibold text-gray-900 mb-2">
                        {faq.question}
                      </h3>
                      <p className="text-gray-600 whitespace-pre-line">
                        {faq.answer}
                      </p>
                    </div>
                  ))}
                </section>

                {/* Message Triggers */}
                <section id="triggers" className="mb-12">
                  <h2 className="text-2xl font-bold mb-6">
                    4. Message Triggers
                  </h2>
                  {faqs.triggers.map((faq, index) => (
                    <div key={index} className="mb-8">
                      <h3 className="text-xl font-semibold text-gray-900 mb-2">
                        {faq.question}
                      </h3>
                      <p className="text-gray-600 whitespace-pre-line">
                        {faq.answer}
                      </p>
                    </div>
                  ))}
                </section>

                {/* Technical Details */}
                <section id="technical" className="mb-12">
                  <h2 className="text-2xl font-bold mb-6">
                    5. Technical Details
                  </h2>
                  {faqs.technical.map((faq, index) => (
                    <div key={index} className="mb-8">
                      <h3 className="text-xl font-semibold text-gray-900 mb-2">
                        {faq.question}
                      </h3>
                      <p className="text-gray-600 whitespace-pre-line">
                        {faq.answer}
                      </p>
                    </div>
                  ))}
                </section>

                {/* Account Management */}
                <section id="account" className="mb-12">
                  <h2 className="text-2xl font-bold mb-6">
                    6. Account Management
                  </h2>
                  {faqs.account.map((faq, index) => (
                    <div key={index} className="mb-8">
                      <h3 className="text-xl font-semibold text-gray-900 mb-2">
                        {faq.question}
                      </h3>
                      <p className="text-gray-600 whitespace-pre-line">
                        {faq.answer}
                      </p>
                    </div>
                  ))}
                </section>

                {/* Support & Resources */}
                <section id="support" className="mb-12">
                  <h2 className="text-2xl font-bold mb-6">
                    7. Support & Resources
                  </h2>
                  {faqs.support.map((faq, index) => (
                    <div key={index} className="mb-8">
                      <h3 className="text-xl font-semibold text-gray-900 mb-2">
                        {faq.question}
                      </h3>
                      <p className="text-gray-600 whitespace-pre-line">
                        {faq.answer}
                      </p>
                    </div>
                  ))}
                </section>

                {/* Call to Action */}
                <section id="get-started" className="text-center">
                  <h2 className="text-2xl font-bold text-gray-900 mb-4">
                    Ready to Secure Your Legacy?
                  </h2>
                  <p className="text-gray-600 mb-6">
                    Join Cryonote today and experience unparalleled protection
                    for your important messages and assets.
                  </p>
                  <a
                    href="/signup"
                    className="inline-block px-6 py-3 bg-blue-600 text-white font-medium rounded-lg hover:bg-blue-700 transition-colors"
                  >
                    Get Started
                  </a>
                </section>
              </div>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default FAQPage;
