/* eslint-disable default-case */
class FileHandlingService {
  // File type categories (maintained for organizational purposes)
  static FILE_CATEGORIES = {
    DOCUMENTS: ['pdf', 'doc', 'docx', 'txt', 'rtf', 'odt', 'pages'],
    IMAGES: ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff', 'webp', 'mkv'],
    ARCHIVES: ['zip', 'rar', '7z', 'tar', 'gz'],
    SPREADSHEETS: ['xls', 'xlsx', 'csv', 'numbers'],
    PRESENTATIONS: ['ppt', 'pptx', 'key'],
    AUDIO: ['mp3', 'wav', 'ogg', 'm4a', 'aac'],
    VIDEO: ['mp4', 'mov', 'avi', 'wmv', 'flv', 'webm', 'mkv'],
    CODE: ['js', 'py', 'java', 'cpp', 'h', 'css', 'html', 'php'],
    DATA: ['json', 'xml', 'yaml', 'sql'],
    EXECUTABLES: ['exe', 'dll', 'app', 'dmg', 'msi'],
    SYSTEM: ['sys', 'drv', 'bin']
  };

  // Unified size limit
  static MAX_FILE_SIZE = 1024 * 1024 * 1024; // 1GB

  static getFileCategory(filename) {
    const extension = filename.split('.').pop().toLowerCase();
    
    for (const [category, extensions] of Object.entries(this.FILE_CATEGORIES)) {
      if (extensions.includes(extension)) {
        return category;
      }
    }
    
    return 'DEFAULT';
  }

  static validateFile(file) {
    const extension = file.name.split('.').pop().toLowerCase();
    const warnings = [];
    const category = this.getFileCategory(file.name);

    // Check file size against unified limit
    if (file.size > this.MAX_FILE_SIZE) {
      return {
        valid: false,
        error: `File exceeds maximum size limit of ${this.formatSize(this.MAX_FILE_SIZE)}`,
        maxSize: this.MAX_FILE_SIZE,
        fileSize: file.size,
        category,
        maxSizeFormatted: this.formatSize(this.MAX_FILE_SIZE),
        fileSizeFormatted: this.formatSize(file.size)
      };
    }

    // Add warnings for potentially sensitive file types
    if (category === 'EXECUTABLES' || category === 'SYSTEM') {
      warnings.push(
        'This file type may be flagged by antivirus software or email filters. ' +
        'Consider using an archive format instead.'
      );
    }

    // Add warnings for common issues
    if (category === 'DOCUMENTS' && file.size < 100) {
      warnings.push(
        'This file seems unusually small for its type. ' +
        'Please verify the file is not corrupted.'
      );
    }

    return {
      valid: true,
      category,
      warnings,
      suggestedHandling: this.getSuggestedHandling(category),
      fileSize: file.size,
      maxSizeFormatted: this.formatSize(this.MAX_FILE_SIZE),
      fileSizeFormatted: this.formatSize(file.size)
    };
  }

  static getSuggestedHandling(category) {
    const handling = {
      encryption: true,
      compression: false,
      virusScan: false,
      specialInstructions: null
    };

    switch (category) {
      case 'DOCUMENTS':
      case 'SPREADSHEETS':
      case 'PRESENTATIONS':
        handling.compression = true;
        handling.specialInstructions = 'Consider converting to PDF for better compatibility';
        break;

      case 'IMAGES':
        handling.compression = true;
        break;

      case 'ARCHIVES':
        handling.compression = false;
        handling.virusScan = true;
        handling.specialInstructions = 'Password-protected archives may cause issues with encryption';
        break;

      case 'EXECUTABLES':
      case 'SYSTEM':
        handling.compression = true;
        handling.virusScan = true;
        handling.specialInstructions = 
          'Recipients may need to explicitly trust these files to open them';
        break;
    }

    return handling;
  }

  static formatSize(bytes) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
  }

  static validateFileSize(fileSize) {
    return {
      valid: fileSize <= this.MAX_FILE_SIZE,
      maxSize: this.MAX_FILE_SIZE,
      fileSize,
      maxSizeFormatted: this.formatSize(this.MAX_FILE_SIZE),
      fileSizeFormatted: this.formatSize(fileSize)
    };
  }
}

export default FileHandlingService;