/**
 * Utility function to format Firestore Timestamps.
 * 
 * @param {Object} firestoreTimestamp - Firestore Timestamp or a valid Date string.
 * @returns {string} - Formatted date or time string.
 */
const formatFirestoreDate = (firestoreTimestamp) => {
    if (!firestoreTimestamp) return 'N/A';
    
    try {
      // Convert Firestore timestamp to Date
      const timestamp = firestoreTimestamp._seconds
        ? new Date(firestoreTimestamp._seconds * 1000)
        : new Date(firestoreTimestamp);
  
      const now = new Date();
      const isToday = timestamp.toDateString() === now.toDateString();
  
      if (isToday) {
        // If today, show only time
        return timestamp.toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
          hour12: true,
        });
      } else {
        // If not today, show date
        return timestamp.toLocaleDateString([], {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        });
      }
    } catch (error) {
      console.error('Error formatting timestamp:', error);
      return 'Invalid date';
    }
  };
  
  module.exports = {
    formatFirestoreDate,
  };