import React, { useState, useEffect } from 'react';
import { Outlet, NavLink, useNavigate, Link, useLocation } from 'react-router-dom';
import {
  HomeIcon,
  ChartBarIcon,
  EnvelopeIcon,
  BoltIcon,
  CreditCardIcon,
  CogIcon,
  QuestionMarkCircleIcon,
  ShieldCheckIcon,
  BellIcon,
  UserCircleIcon,
  ArrowLeftOnRectangleIcon,
  Bars3Icon,
} from '@heroicons/react/24/outline';
import { Menu, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { useAuth } from '../contexts/AuthContext';
import stripeService from '../services/stripeService';
import SubscriptionBanner from '../components/subscription/SubscriptionBanner';
import { Fragment } from 'react';
import SubscriptionSetupModal from '../components/subscription/SubscriptionSetupModal';

const DashboardLayout = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [isLoadingSubscription, setIsLoadingSubscription] = useState(true);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 1024);
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const checkSubscriptionAccess = async () => {
      if (!user) {
        setIsLoadingSubscription(false);
        return;
      }
  
      try {
        const status = await stripeService.getSubscriptionStatus();
        setSubscriptionStatus(status);
        console.log('Logging subscription status: ', status);
  
        // Show modal if no active subscription or trial
        if (!status.isActive && !status.trial.isActive) {
          setShowSubscriptionModal(true);
        }
      } catch (error) {
        console.error('Failed to check subscription status:', error);
      } finally {
        setIsLoadingSubscription(false);
      }
    };
  
    checkSubscriptionAccess();
  }, [user]);

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const navigation = [
    { name: 'Dashboard', href: '/dashboard', icon: HomeIcon },
    { name: 'Messages', href: '/dashboard/messages', icon: EnvelopeIcon },
    { name: 'Triggers', href: '/dashboard/triggers', icon: BoltIcon },
    { name: 'Settings', href: '/dashboard/settings', icon: CogIcon },
    { name: 'Subscription', href: '/dashboard/subscription', icon: CreditCardIcon }
  ];

  const secondaryNavigation = [
    { name: 'Help Center', href: '/dashboard/help', icon: QuestionMarkCircleIcon },
    { name: 'Security', href: '/dashboard/security', icon: ShieldCheckIcon },
  ];

  if (isLoadingSubscription) {
    return (
      <div className="flex justify-center items-center min-h-screen bg-gray-50">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="flex flex-col h-screen overflow-hidden bg-gray-100">
          <SubscriptionSetupModal 
      isOpen={showSubscriptionModal} 
      onClose={() => setShowSubscriptionModal(false)} 
    />
      {/* Show subscription banners if needed */}
      {subscriptionStatus?.isTrialing && (
        <SubscriptionBanner 
          daysRemaining={subscriptionStatus.trialDaysRemaining}
          type="trial"
        />
      )}
      {subscriptionStatus?.isActive && subscriptionStatus.subscriptionDaysRemaining <= 7 && (
        <SubscriptionBanner 
          daysRemaining={subscriptionStatus.subscriptionDaysRemaining}
          type="expiring"
        />
      )}

      {/* Full-width Navbar */}
      <header className="bg-white z-20 shadow-sm">
        <div className="w-full px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center h-14">
            <div className="flex-shrink-0 flex items-center">
              {isMobile ? (
                <button
                  onClick={() => setSidebarOpen(!sidebarOpen)}
                  className="text-gray-500 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500 lg:hidden"
                >
                  <span className="sr-only">Open sidebar</span>
                  <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                </button>
              ) : (
<div className="flex-shrink-0 flex items-center">
  <Link to="/dashboard" className="flex items-center space-x-2">
    <svg className="h-8 w-8" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="5" y="5" width="30" height="30" rx="5" fill="url(#logo-gradient)" />
      <path d="M15 15L25 15L25 25L15 25L15 15Z" fill="white" />
      <defs>
        <linearGradient id="logo-gradient" x1="5" y1="5" x2="35" y2="35" gradientUnits="userSpaceOnUse">
          <stop stopColor="#3B82F6" />
          <stop offset="1" stopColor="#06B6D4" />
        </linearGradient>
      </defs>
    </svg>
    <span className="text-xl font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-cyan-500">
      Cryonote
    </span>
  </Link>
</div>
              )}
            </div>
            <div className="flex items-center space-x-4">
              <button
                type="button"
                className="p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                <span className="sr-only">View notifications</span>
                <BellIcon className="h-6 w-6" aria-hidden="true" />
              </button>

              {/* Profile dropdown */}
              <Menu as="div" className="relative">
                <Menu.Button className="flex items-center max-w-xs text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                  <span className="sr-only">Open user menu</span>
                  <UserCircleIcon className="h-8 w-8 text-gray-400" aria-hidden="true" />
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to="/dashboard/settings"
                          className={classNames(
                            active ? 'bg-gray-100' : '',
                            'block px-4 py-2 text-sm text-gray-700'
                          )}
                        >
                          Settings
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={handleLogout}
                          className={classNames(
                            active ? 'bg-gray-100' : '',
                            'block w-full text-left px-4 py-2 text-sm text-gray-700'
                          )}
                        >
                          Sign out
                        </button>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>
      </header>

      <div className="flex flex-1 overflow-hidden">
        {/* Sidebar */}
        <div
          className={classNames(
            'fixed inset-y-14 left-0 z-10 w-64 bg-white shadow-md transform transition-transform duration-300 ease-in-out lg:translate-x-0 lg:static lg:inset-0',
            {
              'translate-x-0': sidebarOpen,
              '-translate-x-full': !sidebarOpen && isMobile,
            }
          )}
        >
          <div className="flex flex-col h-full">
            {/* Logo in sidebar for mobile */}
            {isMobile && (
        <div className="flex-shrink-0 flex items-center">
        <Link to="/" className="flex items-center space-x-2">
          <svg className="h-10 w-10" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="5" y="5" width="30" height="30" rx="5" fill="url(#logo-gradient)" />
            <path d="M15 15L25 15L25 25L15 25L15 15Z" fill="white" />
            <defs>
              <linearGradient id="logo-gradient" x1="5" y1="5" x2="35" y2="35" gradientUnits="userSpaceOnUse">
                <stop stopColor="#3B82F6" />
                <stop offset="1" stopColor="#06B6D4" />
              </linearGradient>
            </defs>
          </svg>
          <span className="text-2xl font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-cyan-500">
            Cryonote
          </span>
        </Link>
      </div>
            )}
            {/* Navigation area with shadow */}
            <div className="flex-1 overflow-y-auto">
              <nav className="mt-5 px-4 space-y-2">
                {navigation.map((item) => (
                  <NavLink
                    key={item.name}
                    to={item.href}
                    end={item.href === '/dashboard'}
                    onClick={() => isMobile && setSidebarOpen(false)}
                    className={({ isActive }) =>
                      classNames(
                        isActive
                          ? 'bg-blue-50 text-blue-600'
                          : 'text-gray-700 hover:bg-blue-50 hover:text-blue-600',
                        'group flex items-center px-4 py-3 text-base font-medium rounded-md transition-colors duration-150 ease-in-out'
                      )
                    }
                  >
                    <item.icon className="mr-3 flex-shrink-0 h-6 w-6" aria-hidden="true" />
                    {item.name}
                  </NavLink>
                ))}
              </nav>
              <div className="mt-6 px-4 space-y-2 border-t border-gray-200 pt-4">
                {secondaryNavigation.map((item) => (
                  <NavLink
                    key={item.name}
                    to={item.href}
                    className="group flex items-center px-4 py-3 text-base font-medium rounded-md text-gray-700 hover:bg-blue-50 hover:text-blue-600 transition-colors duration-150 ease-in-out"
                  >
                    <item.icon className="mr-3 flex-shrink-0 h-6 w-6" aria-hidden="true" />
                    {item.name}
                  </NavLink>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Main Content Area */}
        <div className="flex-1 flex flex-col overflow-hidden">
          {/* Main content with Outlet */}
          <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
              <Outlet />
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;