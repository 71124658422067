import React from 'react';
import { Link } from 'react-router-dom';
import { 
  BookOpenIcon, 
  CurrencyDollarIcon, 
  ShieldCheckIcon, 
  QuestionMarkCircleIcon,
  UserGroupIcon,
  BriefcaseIcon,
  EnvelopeIcon,
  DocumentTextIcon,
  LockClosedIcon,
  ClipboardDocumentCheckIcon
} from '@heroicons/react/24/outline';

const Footer = () => (
  <footer className="bg-gradient-to-r from-blue-600 to-blue-800 text-white">
    <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
      <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
        <div className="flex flex-col items-start">
 
  <Link to="/" className="flex items-center space-x-2">
    <svg className="h-10 w-10" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="5" y="5" width="30" height="30" rx="5" fill="url(#logo-gradient)" />
      <path d="M15 15L25 15L25 25L15 25L15 15Z" fill="white" />
      <defs>
        <linearGradient id="logo-gradient" x1="5" y1="5" x2="35" y2="35" gradientUnits="userSpaceOnUse">
          <stop stopColor="#3B82F6" />
          <stop offset="1" stopColor="#06B6D4" />
        </linearGradient>
      </defs>
    </svg>
    <span className="text-2xl font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-blue-100 to-cyan-100">
      Cryonote
    </span>
  </Link>

  
          <p className="text-blue-100 mb-4">Secure your digital legacy with confidence</p>
          <div className="flex space-x-4">
            {/* Social media icons can be added here if needed */}
          </div>
        </div>
        <div>
          <h3 className="text-lg font-semibold mb-4">Product</h3>
          <ul className="space-y-2">
            <li><Link to="/features" className="hover:text-blue-200 transition-colors flex items-center"><BookOpenIcon className="h-5 w-5 mr-2" />Features</Link></li>
            <li><Link to="/pricing" className="hover:text-blue-200 transition-colors flex items-center"><CurrencyDollarIcon className="h-5 w-5 mr-2" />Pricing</Link></li>
            <li><Link to="/security" className="hover:text-blue-200 transition-colors flex items-center"><ShieldCheckIcon className="h-5 w-5 mr-2" />Security</Link></li>
            <li><Link to="/faq" className="hover:text-blue-200 transition-colors flex items-center"><QuestionMarkCircleIcon className="h-5 w-5 mr-2" />FAQ</Link></li>
          </ul>
        </div>
        <div>
          <h3 className="text-lg font-semibold mb-4">Company</h3>
          <ul className="space-y-2">
            <li><Link to="/about" className="hover:text-blue-200 transition-colors flex items-center"><UserGroupIcon className="h-5 w-5 mr-2" />About Us</Link></li>
            <li><Link to="/careers" className="hover:text-blue-200 transition-colors flex items-center"><BriefcaseIcon className="h-5 w-5 mr-2" />Careers</Link></li>
            <li><Link to="/contact" className="hover:text-blue-200 transition-colors flex items-center"><EnvelopeIcon className="h-5 w-5 mr-2" />Contact</Link></li>
          </ul>
        </div>
        <div>
          <h3 className="text-lg font-semibold mb-4">Legal</h3>
          <ul className="space-y-2">
            <li><Link to="/privacy" className="hover:text-blue-200 transition-colors flex items-center"><DocumentTextIcon className="h-5 w-5 mr-2" />Privacy Policy</Link></li>
            <li><Link to="/tos" className="hover:text-blue-200 transition-colors flex items-center"><ClipboardDocumentCheckIcon className="h-5 w-5 mr-2" />Terms of Service</Link></li>
            <li><Link to="/security" className="hover:text-blue-200 transition-colors flex items-center"><LockClosedIcon className="h-5 w-5 mr-2" />Security</Link></li>
          </ul>
        </div>
      </div>
      <div className="mt-12 border-t border-blue-500 pt-8 text-center">
        <p className="text-blue-100">&copy; {new Date().getFullYear()} Cryonote. All rights reserved.</p>
        <p className="text-blue-200 mt-2">Trusted by security specialists. Your legacy, preserved.</p>
      </div>
    </div>
  </footer>
);

export default Footer;
