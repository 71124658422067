import React from 'react';
import Container from '../components/Container';
import Navbar from '../components/Navbar';
import Hero from '../components/Hero';
import TrustMarkers from '../components/TrustMarkers';
import SecurityLevels from '../components/SecurityLevels';
import TriggerTypes from '../components/TriggerTypes';
import FeaturesAndBenefits from '../components/FeaturesAndBenefits';
import HowItWorks from '../components/HowItWorks';
import UseCases from '../components/UseCases';
import Testimonials from '../components/Testimonials';
import Pricing from '../components/Pricing';
import FAQ from '../components/FAQ';
import FinalCTA from '../components/FinalCTA';
import Footer from '../components/Footer';

const LandingPage = () => {
  return (
    <Container>
      <div className="min-h-screen">
        {/* Fixed navigation */}
        <div className="sticky top-0 z-50 bg-white/95 backdrop-blur-sm border-b border-gray-200">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <Navbar />
          </div>
        </div>

        {/* Main content */}
        <main>
          {/* Each section has proper spacing and backgrounds */}
          <div className="bg-white">
            <Hero />
          </div>


          <div className="bg-white">
            <SecurityLevels />
          </div>

          <div className="bg-gray-50">
            <TriggerTypes />
          </div>

          <div id="features" className="bg-white">
            <FeaturesAndBenefits />
          </div>

          <div className="bg-gray-50">
            <HowItWorks />
          </div>

          <div className="bg-white">
            <UseCases />
          </div>

          <div className="bg-gray-50">
            <Testimonials />
          </div>


          <div id="pricing" className="bg-white">
            <Pricing />
          </div>

          <div className="bg-gray-50">
            <FAQ />
          </div>

          <div className="bg-gradient-to-br from-blue-600 to-blue-700">
            <FinalCTA />
          </div>
        </main>

        <Footer />
      </div>
    </Container>
  );
};

export default LandingPage;
