import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { 
  ShieldCheckIcon, 
  KeyIcon, 
  LockClosedIcon, 
  InformationCircleIcon,
  ChevronDownIcon,
  ChevronUpIcon 
} from '@heroicons/react/24/outline';
import api from '../../services/api';
import TwoFactorAuth from './TwoFactorAuth';
import MessageDisplay from './MessageDisplay';
import DecryptionKeyInput from './DecryptionKeyInput';
import FileDecryptor from './FileDecryptor';

const SecureMessageViewer = () => {
  const { token } = useParams();
  const [step, setStep] = useState('initiate2FA');
  const [message, setMessage] = useState(null);
  const [verificationSent, setVerificationSent] = useState(false);
  const [decryptionKey, setDecryptionKey] = useState('');
  const [error, setError] = useState('');
  const [tokenVerified, setTokenVerified] = useState(false);
  const [showInstructions, setShowInstructions] = useState(false);
  const initRef = useRef(false);
  const initPromiseRef = useRef(null);

  useEffect(() => {
    const verifyToken = async () => {
      try {
        if (tokenVerified) return;
        
        const statusRes = await api.get(`/api/messages/secure/${token}/status`);
        if (!statusRes.data.valid) {
          setError('Invalid or expired access link');
          return;
        }

        setTokenVerified(true);
        setStep('verify2FA');
      } catch (error) {
        setError('Failed to verify access link. The link may be invalid or expired.');
      }
    };

    verifyToken();
  }, [token]);

  const initiateVerification = async () => {
    try {
      if (verificationSent) return;
      
      if (initPromiseRef.current) {
        await initPromiseRef.current;
        return;
      }

      const promise = api.post(`/api/messages/secure/${token}/initiate`);
      initPromiseRef.current = promise;
      
      await promise;
      setVerificationSent(true);
    } catch (error) {
      setError('Failed to send verification code. Please try again.');
    } finally {
      initPromiseRef.current = null;
    }
  };

  const handleTwoFactorSuccess = async (code) => {
    try {
      setError('');
      const response = await api.post(`/api/messages/secure/${token}/verify`, { code });
      setMessage(response.data);
      setStep(response.data.securityLevel === 'enhanced' ? 'decryption' : 'display');
    } catch (error) {
      setError(error.response?.data?.message || 'Invalid verification code. Please try again.');
    }
  };

  const handleDecryption = (key) => {
    setDecryptionKey(key);
    setStep('display');
  };

  const renderInstructions = () => (
    <div className="mb-6 bg-blue-50 border border-blue-100 rounded-lg p-4 text-sm">
      <div className="flex items-start">
        <InformationCircleIcon className="h-5 w-5 text-blue-500 mt-0.5 mr-2 flex-shrink-0" />
        <div className="space-y-2 text-blue-800">
          <div>
            <span className="font-medium">1. Verification:</span>
            <span className="text-blue-600 ml-1">Enter the code sent to your email</span>
          </div>
          <div>
            <span className="font-medium">2. Decryption:</span>
            <span className="text-blue-600 ml-1">Use your provided decryption key</span>
          </div>
          <div>
            <span className="font-medium">3. Files:</span>
            <span className="text-blue-600 ml-1">Download and decrypt with provided passwords</span>
          </div>
          <div className="text-xs text-blue-600 mt-2 border-t border-blue-100 pt-2">
            All decryption happens locally in your browser for maximum security
          </div>
        </div>
      </div>
    </div>
  );

  const renderStepIndicator = () => {
    const steps = [
      { key: 'verify2FA', icon: ShieldCheckIcon, label: 'Verify' },
      { key: 'decryption', icon: KeyIcon, label: 'Decrypt' },
      { key: 'display', icon: LockClosedIcon, label: 'View' }
    ];

    return (
      <div className="flex justify-center mb-8">
        {steps.map((s, index) => (
          <div key={s.key} className="flex items-center">
            <div className="flex flex-col items-center">
              <div className={`w-10 h-10 rounded-full flex items-center justify-center transition-colors duration-200 ${
                step === s.key 
                  ? 'bg-blue-600 text-white' 
                  : steps.findIndex(st => st.key === step) > steps.findIndex(st => st.key === s.key)
                    ? 'bg-green-500 text-white'
                    : 'bg-gray-200 text-gray-500'
              }`}>
                <s.icon className="w-5 h-5" />
              </div>
              <span className="text-xs text-gray-500 mt-1">{s.label}</span>
            </div>
            {index < steps.length - 1 && (
              <div className={`w-20 h-0.5 mx-2 ${
                steps.findIndex(st => st.key === step) > index 
                  ? 'bg-green-500' 
                  : 'bg-gray-200'
              }`} />
            )}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="bg-white rounded-lg shadow-xl p-6 sm:p-8">
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-2xl sm:text-3xl font-bold">Secure Message</h1>
        {step !== 'display' && (
          <button
            onClick={() => setShowInstructions(!showInstructions)}
            className="flex items-center text-sm text-blue-600 hover:text-blue-800"
          >
            {showInstructions ? (
              <ChevronUpIcon className="h-4 w-4 mr-1" />
            ) : (
              <ChevronDownIcon className="h-4 w-4 mr-1" />
            )}
            Help
          </button>
        )}
      </div>

      {showInstructions && step !== 'display' && renderInstructions()}
      
      {renderStepIndicator()}

      {error && (
        <div className="mb-6 bg-red-50 border-l-4 border-red-500 text-red-700 p-4 rounded-r" role="alert">
          <p>{error}</p>
        </div>
      )}

      <div className="max-w-2xl mx-auto">
        {step === 'verify2FA' && tokenVerified && (
          <div>
            {!verificationSent ? (
              <div className="text-center">
                <p className="mb-4 text-gray-600">
                  Click the button below to receive your verification code via email.
                </p>
                <button
                  onClick={initiateVerification}
                  className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  Send Verification Code
                </button>
              </div>
            ) : (
              <TwoFactorAuth 
                onSuccess={handleTwoFactorSuccess}
                onErrorClear={() => setError('')}
                verificationSent={verificationSent}
              />
            )}
          </div>
        )}

        {step === 'decryption' && message && (
          <DecryptionKeyInput onSubmit={handleDecryption} />
        )}

        {step === 'display' && message && (
          <div className="space-y-8">
            <MessageDisplay 
              message={message}
              decryptionKey={decryptionKey}
            />
            {message.securityLevel === 'enhanced' && message.attachments?.length > 0 && (
              <FileDecryptor decryptionKey={decryptionKey} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SecureMessageViewer;