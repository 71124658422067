import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { ArrowLeftIcon, LockClosedIcon } from '@heroicons/react/24/outline';
import stripeService from '../../services/stripeService';

const PaymentForm = ({ plan, onBack, onComplete }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setError(null);

    if (!stripe || !elements) {
      return;
    }

    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
      });

      if (error) {
        throw error;
      }

      // Start trial with payment method attached
      await stripeService.startTrial({
        planName: plan.name,
        paymentMethodId: paymentMethod.id
      });

      onComplete();
    } catch (error) {
      console.error('Payment error:', error);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="max-w-lg mx-auto">
      <button
        onClick={onBack}
        className="flex items-center text-gray-600 hover:text-gray-900 mb-6"
      >
        <ArrowLeftIcon className="h-4 w-4 mr-2" />
        Back to plans
      </button>
  
      <h3 className="text-2xl font-bold mb-2">Set up your {plan.name} trial</h3>
      <p className="text-gray-600 mb-6">
        Enter your payment details to start your 14-day free trial
      </p>
  
      <div className="flex items-center justify-between mb-6 bg-gray-50 rounded-lg p-4">
        <div className="flex items-center">
        <a 
          href="https://stripe.com" 
          target="_blank" 
          rel="noopener noreferrer"
          className="flex items-center space-x-2"
        >
          <img 
            src="/powered-by-stripe.svg" 
            alt="Powered by Stripe" 
            className="md:h-6 w-auto h-6" // Increased height to make it prominent
          />
        </a>
          <span className="text-sm ml-2 text-gray-600">Secure payment by Stripe</span>
        </div>
        <LockClosedIcon className="h-5 w-5 text-gray-400" />
      </div>
  
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="space-y-4">
          <div className="bg-white rounded-lg border border-gray-200 p-4">
            <CardElement
              options={{
                style: {
                  base: {
                    fontSize: '16px',
                    color: '#424770',
                    '::placeholder': {
                      color: '#aab7c4',
                    },
                  },
                  invalid: {
                    color: '#9e2146',
                  },
                },
                hidePostalCode: true
              }}
            />
          </div>
  
          {error && (
            <div className="bg-red-50 text-red-700 p-4 rounded-lg text-sm">
              {error}
            </div>
          )}
        </div>
  
        <div className="bg-blue-50 rounded-lg p-4 space-y-2">
          <div className="flex justify-between text-sm">
            <span className="text-gray-600">Trial period</span>
            <span className="font-medium">14 days</span>
          </div>
          <div className="flex justify-between text-sm">
            <span className="text-gray-600">First payment</span>
            <span className="font-medium">After trial ends</span>
          </div>
          <div className="flex justify-between text-sm font-medium">
            <span className="text-gray-900">Then every 6 months</span>
            <span className="text-gray-900">${plan.price}.00</span>
          </div>
        </div>
  
        <button
          type="submit"
          disabled={!stripe || isLoading}
          className="w-full py-3 px-6 rounded-lg font-semibold text-center bg-gradient-to-r from-blue-600 to-blue-700 text-white hover:from-blue-700 hover:to-blue-800 transition-all duration-200 disabled:opacity-50"
        >
          {isLoading ? 'Processing...' : `Start ${plan.name} Trial`}
        </button>
  
        <div className="text-center space-y-2">
          <div className="flex items-center justify-center space-x-2 text-sm text-gray-500">
            <LockClosedIcon className="h-4 w-4" />
            <span>Your payment is secure and encrypted</span>
          </div>
          <p className="text-xs text-gray-500">
            Trial ends {new Date(Date.now() + 14 * 24 * 60 * 60 * 1000).toLocaleDateString()}. 
            Cancel anytime before then.
          </p>
        </div>
      </form>
  
      {/* Updated Stripe Badge */}
      {/* <div className="mt-6 flex items-center justify-center">
        <a 
          href="https://stripe.com" 
          target="_blank" 
          rel="noopener noreferrer"
          className="flex items-center space-x-2"
        >
          <img 
            src="/powered-by-stripe.svg" 
            alt="Powered by Stripe" 
            className="md:h-6 w-auto h-6" // Increased height to make it prominent
          />
        </a>
      </div> */}
    </div>
  );
  
  
};

export default PaymentForm;