import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMessages, createMessage, updateMessage, deleteMessage, updateMessageStatus } from '../../store/slices/messageSlice';
import MessageList from '../../components/dashboard/messages/MessageList';
import MessageEditor from '../../components/dashboard/messages/MessageEditor';
import ConfirmDialog from '../../components/dashboard/messages/ConfirmDialog';
import CollapsibleExplanation from '../../components/common/CollapsibleExplanation';
import { PlusIcon } from '@heroicons/react/24/outline';

const MessagesPage = () => {
  const dispatch = useDispatch();
  const { messages, status, error } = useSelector(state => state.messages);
  const [editorOpen, setEditorOpen] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [editorMode, setEditorMode] = useState('view');
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
  const [messageToDelete, setMessageToDelete] = useState(null);

  useEffect(() => {
    dispatch(fetchMessages());
  }, [dispatch]);

  const handleView = (message) => {
    setSelectedMessage(message);
    setEditorMode('view');
    setEditorOpen(true);
  };

  const handleEdit = (message) => {
    setSelectedMessage(message);
    setEditorMode('edit');
    setEditorOpen(true);
  };

  const handleDeleteClick = (message) => {
    setMessageToDelete(message);
    setIsDeleteConfirmOpen(true);
  };

  const handleDeleteConfirm = () => {
    if (messageToDelete) {
      const messageId = messageToDelete.id || messageToDelete._id;
      dispatch(deleteMessage(messageId));
      setIsDeleteConfirmOpen(false);
      setMessageToDelete(null);
    }
  };

  const handleCreate = () => {
    setSelectedMessage({
      title: "",
      recipients: [],
      bcc: [],
      sender: "",
      trigger: "Upon passing",
      status: "draft",
      content: "",
      type: "email",
      securityLevel: "standard"
    });
    setEditorMode('create');
    setEditorOpen(true);
  };

  // const handleSave = (savedMessage) => {
  //   setEditorOpen(false);
  //   if (editorMode === 'create') {
  //     dispatch(createMessage(savedMessage));
  //   } else if (editorMode === 'edit') {
  //     dispatch(updateMessage(savedMessage));
  //   }
  // };

  const handleSave = (savedMessage) => {
    setEditorOpen(false);
    // Any other necessary state updates or actions
  };

  const handleStatusChange = (messageId, newStatus) => {
    dispatch(updateMessageStatus({ id: messageId, status: newStatus }));
  };

  if (status === 'loading') {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (status === 'failed') {
    return (
      <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
        <strong className="font-bold">Error!</strong>
        <span className="block sm:inline"> {error}</span>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 relative">
<CollapsibleExplanation title="Messages">
  <p>
    Messages are the cornerstone of your digital legacy, serving both personal and professional needs. They provide a secure way to communicate crucial information, express sentiments, share memories with loved ones, or relay sensitive business data to successors after your passing. Here's what you need to know:
  </p>
  <ul className="list-disc list-inside mt-2 space-y-1">
    <li>Create distinct messages for various recipients, purposes, or scenarios, whether personal or business-related.</li>
    <li>Choose between email or SMS delivery methods to suit your communication needs.</li>
    <li>Securely attach important documents, media files, or business records to your messages.</li>
    <li>Set the security level: standard for normal encryption, or enhanced (Local Encryption) for maximum protection. Enhanced security encrypts messages/files on your device locally before they are securely transmitted.</li>
    <li>Messages remain in 'Draft' status until you mark them as 'Ready' for potential delivery, allowing for careful review and updates.</li>
  </ul>
  <p className="mt-2">
    Whether you're preserving family history, expressing final wishes, or ensuring business continuity, take the time to craft these messages thoughtfully. Remember, they will only be sent when the conditions you set in your Triggers are met. Your digital legacy is a powerful tool for personal expression and organizational planning alike.
  </p>
</CollapsibleExplanation>

      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-semibold text-gray-900">Your Messages</h2>
        <button 
          onClick={handleCreate}
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-gradient-to-r from-blue-600 to-blue-500 hover:from-blue-800 hover:to-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          <PlusIcon className="h-5 w-5 mr-2" />
          New Message
        </button>
      </div>

      <MessageList
        messages={messages}
        onView={handleView}
        onEdit={handleEdit}
        onDelete={handleDeleteClick}
        onStatusChange={handleStatusChange}
      />

      {editorOpen && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center z-50"> 
          <MessageEditor
            message={selectedMessage}
            mode={editorMode}
            onSave={handleSave}
            onClose={() => setEditorOpen(false)}
            userEmails={['user@example.com', 'work@example.com']}
          />
        </div>
      )}

      <ConfirmDialog
        isOpen={isDeleteConfirmOpen}
        onClose={() => setIsDeleteConfirmOpen(false)}
        onConfirm={handleDeleteConfirm}
        title="Confirm Delete"
        message="Are you sure you want to delete? This will remove all associated text and attachments permanently."
      />
    </div>
  );
};

export default MessagesPage;