import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { fetchTriggers } from '../../store/slices/triggerSlice';
import { fetchMessages } from '../../store/slices/messageSlice';
import { fetchUserProfile, fetchUsageStats } from '../../store/slices/userSlice';
import {
  EnvelopeIcon,
  BoltIcon,
  QuestionMarkCircleIcon,
  ChartBarIcon,
  ClockIcon,
  ShieldCheckIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/outline';
import { useAuth } from '../../contexts/AuthContext';
import { motion } from 'framer-motion';
import { formatFirestoreDate } from '../../utils/firestoreUtils';

const StatusBadge = ({ status }) => (
  <span
    className={`inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium ${
      status === 'complete'
        ? 'bg-green-100 text-green-800'
        : status === 'incomplete'
        ? 'bg-yellow-100 text-yellow-800'
        : 'bg-red-100 text-red-800'
    }`}
  >
    {status === 'complete' ? 'Complete' : status === 'incomplete' ? 'Incomplete' : '2FA Not Setup'}
  </span>
);

const TierBadge = ({ tier }) => {
  const tierColors = {
    Essential: 'bg-blue-100 text-blue-800',
    Premium: 'bg-green-100 text-green-800',
    Enterprise: 'bg-purple-100 text-purple-800',
  };
  return (
    <span
      className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
        tierColors[tier] || tierColors['Essential']
      }`}
    >
      {tier || 'Essential'}
    </span>
  );
};

const formatStorage = (bytes) => {
  const mb = bytes / (1024 * 1024);
  if (mb >= 1024) {
    const gb = mb / 1024;
    return `${gb.toFixed(2)} GB`;
  } else {
    return `${mb.toFixed(2)} MB`;
  }
};

const InfoCard = ({ icon: Icon, title, value, description }) => (
  <div className="bg-white rounded-lg p-4 shadow-sm hover:shadow-md transition-all duration-300">
    <div className="flex items-center mb-2">
      <div className="p-2 bg-blue-100 rounded-full mr-3">
        <Icon className="h-5 w-5 text-blue-600" aria-hidden="true" />
      </div>
      <h3 className="text-sm font-medium text-gray-900">{title}</h3>
    </div>
    <div className="text-xl font-semibold text-blue-600 mb-1">{value}</div>
    <p className="text-xs text-gray-600">{description}</p>
  </div>
);

const QuickActionButton = ({ to, icon: Icon, text }) => (
  <div>
    <Link
      to={to}
      className="flex items-center justify-center p-3 rounded-lg shadow-sm transition-all duration-300 bg-gradient-to-r from-blue-600 to-blue-500 hover:from-blue-700 hover:to-blue-600"
    >
      <Icon className="h-4 w-4 mr-2 text-white" aria-hidden="true" />
      <span className="text-white text-sm font-medium">{text}</span>
    </Link>
  </div>
);

const DashboardHome = () => {
  const dispatch = useDispatch();
  const { user } = useAuth();
  const { displayName } = useAuth();
  const triggers = useSelector((state) => state.triggers.triggers);
  const messages = useSelector((state) => state.messages.messages);
  const userProfile = useSelector((state) => state.user.profile);
  const userUsage = useSelector((state) => state.user.usage);
  const [setupProgress, setSetupProgress] = useState(0);
  const [tasksRemaining, setTasksRemaining] = useState([]);
  const [isProgressOpen, setIsProgressOpen] = useState(true);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        await Promise.all([
          dispatch(fetchTriggers()),
          dispatch(fetchMessages()),
          dispatch(fetchUserProfile()),
          dispatch(fetchUsageStats()),
        ]);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to load dashboard data. Please try again.');
        setLoading(false);
      }
    };
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (userProfile && messages && triggers) {
      let progress = 0;
      let tasks = [];

      if (userProfile.name && userProfile.name.trim() !== '') {
        progress += 20;
      } else {
        tasks.push({
          task: 'Add your name to your profile',
          actionText: 'Edit Profile',
          actionLink: '/dashboard/settings',
        });
      }

      if (messages.length > 0) {
        progress += 40;
      } else {
        tasks.push({
          task: 'Create at least one message',
          actionText: 'Create Message',
          actionLink: '/dashboard/messages',
        });
      }

      const checkInTrigger = triggers.find((t) => t.checkIn && t.checkIn.isActive);
      if (checkInTrigger) {
        progress += 40;
      } else {
        tasks.push({
          task: 'Set up a check-in trigger',
          actionText: 'Set up Trigger',
          actionLink: '/dashboard/triggers',
        });
      }

      setSetupProgress(progress);
      setTasksRemaining(tasks);
    }
  }, [userProfile, messages, triggers]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-indigo-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div
        className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded-lg"
        role="alert"
      >
        <strong className="font-medium">Error:</strong>
        <span className="block sm:inline"> {error}</span>
      </div>
    );
  }
  if (!userProfile) return <div>No user data available. Please try logging in again.</div>;

  const checkInTrigger = triggers.find((t) => t.checkIn && t.checkIn.isActive);
  const consensusTrigger = triggers.find((t) => t.consensus && t.consensus.isActive);

  const tierLimits = {
    Essential: { messages: 20, storage: 1024 * 1024 * 1024 },
    Premium: { messages: 100, storage: 10 * 1024 * 1024 * 1024 },
    Enterprise: { messages: Infinity, storage: Infinity },
  };

  const currentTierLimits = tierLimits[userProfile.accountTier] || tierLimits['Essential'];

  const activeMessagesCount = messages.filter((message) => message.status === 'Ready').length;

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
      <div className="mb-6 bg-gradient-to-r from-blue-600 to-blue-400 shadow-md overflow-hidden rounded-lg text-white">
        <div className="px-6 py-6 sm:p-8">
          <div className="flex justify-between items-center">
            <div>
              <h1 className="text-2xl font-bold">
                Welcome back, {userProfile.name || displayName || 'User'}!
              </h1>
              <p className="mt-1 text-sm text-blue-100">
                Here's an overview of your digital legacy setup.
              </p>
            </div>
            <TierBadge tier={userProfile.accountTier} />
          </div>
        </div>
      </div>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
        className="mb-6 bg-white shadow-sm rounded-lg"
      >
        <div onClick={() => setIsProgressOpen(!isProgressOpen)} className="p-4 cursor-pointer">
          <h2 className="text-lg font-semibold text-gray-900 mb-3">Setup Progress</h2>
          <div className="bg-gray-200 rounded-full h-3 overflow-hidden">
            <motion.div
              className="bg-blue-500 h-3 rounded-full"
              initial={{ width: 0 }}
              animate={{ width: `${setupProgress}%` }}
              transition={{ duration: 1, ease: 'easeOut' }}
            ></motion.div>
          </div>
          <p className="mt-2 text-xs text-gray-600">
            Your account is {setupProgress}% set up.{' '}
            {setupProgress < 100 &&
              'Complete your setup to ensure your digital legacy is fully managed.'}
          </p>
        </div>
        {isProgressOpen && tasksRemaining.length > 0 && (
          <div className="px-4 pb-4">
            <h3 className="text-sm font-medium text-gray-800 mb-2">Tasks to complete:</h3>
            <ul className="list-disc list-inside text-sm text-gray-700">
              {tasksRemaining.map((task, index) => (
                <li key={index} className="mb-2">
                  {task.task}
                  <Link
                    to={task.actionLink}
                    className="ml-2 text-blue-600 hover:text-blue-800 font-medium"
                  >
                    {task.actionText}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        )}
      </motion.div>

      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 mb-6">
        <InfoCard
          icon={ChartBarIcon}
          title="Active Messages"
          value={activeMessagesCount}
          description={`message${activeMessagesCount !== 1 ? 's' : ''} active`}
        />
        <InfoCard
          icon={ClockIcon}
          title="Next Check-in"
          value={
            checkInTrigger && checkInTrigger.checkIn.nextCheckInDeadline
              ? formatFirestoreDate(checkInTrigger.checkIn.nextCheckInDeadline)
              : 'Not set'
          }
          description="Date of next check-in"
        />
        <InfoCard
          icon={EnvelopeIcon}
          title="Message Usage"
          value={`${userProfile.usage.messageCount || 0} / ${
            currentTierLimits.messages === Infinity ? 'Unlimited' : currentTierLimits.messages
          }`}
          description="Messages created"
        />
        <InfoCard
          icon={ShieldCheckIcon}
          title="Storage Usage"
          value={formatStorage(userProfile.storage.used || 0)}
          description={`of ${
            currentTierLimits.storage === Infinity
              ? 'Unlimited'
              : formatStorage(currentTierLimits.storage)
          } used`}
        />
      </div>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.4 }}
        className="mb-6 bg-white shadow-sm rounded-lg overflow-hidden"
      >
        <div className="px-4 py-4">
          <h2 className="text-lg font-semibold text-gray-900 mb-3">Quick Actions</h2>
          <div className="grid grid-cols-1 gap-3 sm:grid-cols-3">
            <QuickActionButton to="/dashboard/messages" icon={EnvelopeIcon} text="Create Message" />
            <QuickActionButton to="/dashboard/triggers" icon={BoltIcon} text="Manage Triggers" />
            <QuickActionButton to="/dashboard/faq" icon={QuestionMarkCircleIcon} text="View FAQ" />
          </div>
        </div>
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.6 }}
        className="bg-white shadow-sm rounded-lg overflow-hidden"
      >
        <div className="px-4 py-4">
          <h2 className="text-lg font-semibold text-gray-900 mb-3">System Status</h2>
          <dl className="grid grid-cols-1 gap-3 sm:grid-cols-2">
            <div className="flex justify-between items-center py-2 border-b border-gray-200">
              <dt className="text-sm font-medium text-gray-600">Messages</dt>
              <dd className="text-sm text-gray-900">
                <StatusBadge status={messages.length > 0 ? 'complete' : 'incomplete'} />
              </dd>
            </div>
            <div className="flex justify-between items-center py-2 border-b border-gray-200">
              <dt className="text-sm font-medium text-gray-600">Check-In Trigger</dt>
              <dd className="text-sm text-gray-900">
                <StatusBadge status={checkInTrigger ? 'complete' : 'incomplete'} />
              </dd>
            </div>
            <div className="flex justify-between items-center py-2 border-b border-gray-200">
              <dt className="text-sm font-medium text-gray-600">Consensus Trigger</dt>
              <dd className="text-sm text-gray-900">
                <StatusBadge status={consensusTrigger ? 'complete' : 'incomplete'} />
                {userProfile.accountTier === 'Essential' && (
                  <span className="ml-2 text-xs text-gray-500">(Enhanced feature)</span>
                )}
              </dd>
            </div>
            <div className="flex justify-between items-center py-2 border-b border-gray-200">
              <dt className="text-sm font-medium text-gray-600">Security Status</dt>
              <dd className="text-sm text-gray-900">
                <StatusBadge
                  status={
                    userProfile.settings?.twoFactorAuth?.isEnabled ? 'complete' : 'incomplete'
                  }
                />
              </dd>
            </div>
          </dl>
        </div>
      </motion.div>

      {userProfile.accountTier !== 'Enterprise' && userProfile.accountTier !== 'Premium' && (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.8 }}
          className="mt-6 bg-blue-50 border-l-4 border-blue-400 rounded-lg p-4"
        >
          <div className="flex">
            <div className="flex-shrink-0">
              <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-blue-800">Upgrade your account</h3>
              <div className="mt-2 text-sm text-blue-700">
                <p>
                  Unlock more features and increase your limits by upgrading to a higher tier.
                </p>
                <Link
                  to="/dashboard/settings"
                  className="font-medium underline hover:text-blue-600 transition-colors duration-200"
                >
                  View upgrade options
                </Link>
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </div>
  );
};

export default DashboardHome;
