// components/dashboard/messages/SecurityTips.jsx
import React, { useState } from 'react';
import { InformationCircleIcon } from '@heroicons/react/24/outline';

const SecurityTips = ({ securityLevel, type }) => {
  const [isVisible, setIsVisible] = useState(false);

  const getContent = () => {
    const contents = {
      enhanced: {
        content: {
          title: 'Enhanced Security Content Protection',
          tips: [
            'Content is encrypted locally in your browser before transmission',
            'Once saved, content cannot be read without your decryption key',
            'Server never sees unencrypted content',
            'Additional server-side encryption adds another layer of protection'
          ]
        },
        files: {
          title: 'Enhanced Security File Protection',
          tips: [
            'Files are encrypted locally before upload',
            'Additional server-side 256-bit AES encryption in 7z format',
            'Recipients receive password-protected 7z files w/ password to extract',
            'Recipients need your decryption key to decrypt',
            'Files can only be decrypted in recipient\'s browser'
          ]
        }
      },
      standard: {
        content: {
          title: 'Standard Security Content Protection',
          tips: [
            'Content is transmitted securely to server',
            'Immediate server-side encryption before storage',
            'For maximum security, consider storing sensitive data in encrypted attachments'
          ]
        },
        files: {
          title: 'Standard Security File Protection',
          tips: [
            'Files are secured with 256-bit AES encryption in 7z format',
            'Encrypted at rest in secure storage',
            'Recipients receive password-protected 7z files'
          ]
        }
      }
    };

    const tipContent = contents[securityLevel][type];

    return (
      <div className="text-sm">
        <p className="font-semibold mb-2">{tipContent.title}</p>
        <ul className="list-disc list-inside space-y-1">
          {tipContent.tips.map((tip, index) => (
            <li key={index}>{tip}</li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="relative inline-block">
      <button
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
        className="text-blue-600 hover:text-blue-800 focus:outline-none"
        aria-label="Security Information"
      >
        <InformationCircleIcon className="h-5 w-5" />
      </button>
      {isVisible && (
  <div className="absolute z-50 w-72 bg-white border border-gray-200 rounded-lg shadow-lg p-4 bottom-full right-0 mb-2">
    {getContent()}
  </div>
)}
    </div>
  );
};

export default SecurityTips;