// components/common/SimpleTextEditor.jsx
import React, { useState, useEffect } from 'react';

const SimpleTextEditor = ({ value, onChange, placeholder }) => {
  const [text, setText] = useState(value || '');

  useEffect(() => {
    setText(value || '');
  }, [value]);

  const handleChange = (e) => {
    setText(e.target.value);
    onChange(e.target.value);
  };

  return (
    <div className="border border-gray-300 rounded-md overflow-hidden">
      <textarea
        id="simple-editor"
        value={text}
        onChange={handleChange}
        placeholder={placeholder}
        className="w-full h-64 p-4 resize-y focus:outline-none focus:ring-2 focus:ring-blue-500"
      />
    </div>
  );
};

export default SimpleTextEditor;