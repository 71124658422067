import { configureStore } from '@reduxjs/toolkit';
import messageReducer from './slices/messageSlice';
import triggerReducer from './slices/triggerSlice';
import userReducer from './slices/userSlice';

export const store = configureStore({
  reducer: {
    messages: messageReducer,
    triggers: triggerReducer,
    user: userReducer
  },
});