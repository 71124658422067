import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

const AuthCallback = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { handleGoogleCallback } = useAuth();

  useEffect(() => {
    const processAuthCallback = async () => {
      const params = new URLSearchParams(location.search);
      const token = params.get('token');

      if (!token) {
        console.error('No token found in the callback URL');
        navigate('/login');
        return;
      }

      try {
        const result = await handleGoogleCallback(token);
        if (result.require2FA) {
          navigate(`/auth/2fa?token=${result.tempToken}&provider=${result.method}`);
        } else {
          navigate('/dashboard');
        }
      } catch (error) {
        console.error('Error processing auth callback:', error);
        navigate('/login');
      }
    };

    processAuthCallback();
  }, [navigate, location, handleGoogleCallback]);

  return (
    <div className="flex justify-center items-center h-screen">
      <p>Processing your login... Please wait.</p>
    </div>
  );
};

export default AuthCallback;
