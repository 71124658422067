import React, { useState, useEffect } from 'react';
import { XMarkIcon, PaperClipIcon, EyeIcon, PencilIcon, CheckCircleIcon, ClipboardIcon, LockClosedIcon } from '@heroicons/react/24/outline';
import SimpleTextEditor from '../../common/SimpleTextEditor';
import SecurityTips from './SecurityTips';
import { useDropzone } from 'react-dropzone';
import * as tus from 'tus-js-client';
import { useDispatch } from 'react-redux';
import { createMessage, updateMessage, getMessage } from '../../../store/slices/messageSlice';
import EncryptionService from '../../../services/EncryptionService';
import FileHandlingService from '../../../services/FileHandlingService';
import EnhancedSecurityPopup from './EnhancedSecurityPopup';
import api from '../../../services/api'; 
import { toast } from 'react-toastify';
import { useAuth } from '../../../contexts/AuthContext';

// EN0KZ1F5MROIVZF3AMG7LWNHGU6Y5G9C

// PUDW9XYB0J122R4WNTTMNPA3T5HN88CM

// const api = axios.create({
//   baseURL: process.env.REACT_APP_API_URL,
//   headers: {
//     'Content-Type': 'application/json',
//     'Authorization': `Bearer ${localStorage.getItem('token')}`
//   }
// });

const MessageEditor = ({ message, mode, onSave, onClose, userEmails }) => {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const initialType = message && message.type ? message.type : 'email';

  const [editedMessage, setEditedMessage] = useState({
    ...message,
    title: message?.title || 'Draft Message',
    type: initialType,
    securityLevel: message?.securityLevel || 'standard'
  });
  const [files, setFiles] = useState(message.attachments?.map(att => ({...att, isNew: false})) || []);
  const [viewMode, setViewMode] = useState(mode === 'view');
  const [recipients, setRecipients] = useState(message.recipients || []);
  const [currentInput, setCurrentInput] = useState('');
  const [uploads, setUploads] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [showEnhancedSecurityPopup, setShowEnhancedSecurityPopup] = useState(false);
  const [encryptionKey, setEncryptionKey] = useState('');
  const [showDecryptionPrompt, setShowDecryptionPrompt] = useState(false);
  const [decryptionKey, setDecryptionKey] = useState('');
  const [isMessageSaved, setIsMessageSaved] = useState(!!message?.id);
  const [processingFiles, setProcessingFiles] = useState([]);
  const [uploadErrors, setUploadErrors] = useState({});
  const [isDecrypted, setIsDecrypted] = useState(false);
  

// Update useEffect for loading message data
 // Update useEffect for loading message data
 useEffect(() => {
  const fetchMessageData = async () => {
    if ((message._id || message.id) && mode !== 'create') {
      try {
        const fetchedMessage = await dispatch(getMessage({ 
          id: message._id || message.id 
        })).unwrap();

        // Handle message content based on security level
        if (fetchedMessage.securityLevel === 'enhanced') {
          setShowDecryptionPrompt(true);
          setEditedMessage(prev => ({
            ...prev,
            ...fetchedMessage,
            _id: fetchedMessage.id || fetchedMessage._id,
            // For enhanced security, don't set content yet
            encryptedContent: fetchedMessage.encryptedContent
          }));
        } else {
          // For standard security
          setEditedMessage(prev => ({
            ...prev,
            ...fetchedMessage,
            _id: fetchedMessage.id || fetchedMessage._id,
            // Use content directly for standard security as it's already decrypted
            content: fetchedMessage.content || fetchedMessage.encryptedContent
          }));
          setIsDecrypted(true);
        }

        // Handle attachments if present
        if (fetchedMessage.attachments?.length > 0) {
          setFiles(fetchedMessage.attachments.map(att => ({
            ...att,
            isNew: false
          })));
        }
      } catch (error) {
        console.error('Error fetching message:', error);
        toast.error('Failed to load message');
      }
    }
  };
  fetchMessageData();
}, [message, mode, dispatch]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedMessage((prev) => ({ ...prev, [name]: value }));
  };

  const handleContentChange = (content) => {
    setEditedMessage((prev) => ({ ...prev, content }));
  };

  const handleSecurityLevelChange = () => {
    if (editedMessage.securityLevel === 'standard') {
      setShowEnhancedSecurityPopup(true);
    } else {
      setEditedMessage(prev => ({ ...prev, securityLevel: 'standard' }));
      setEncryptionKey('');
    }
  };

  const handleEnhancedSecurityConfirm = () => {
    setShowEnhancedSecurityPopup(false);
    setEditedMessage(prev => ({ ...prev, securityLevel: 'enhanced' }));
    setEncryptionKey(EncryptionService.generateKey());
  };

  const copyEncryptionKey = () => {
    navigator.clipboard.writeText(encryptionKey);
    // Optionally, show a tooltip or notification that the key was copied
  };

  const handleDecryptionKeySubmit = () => {
    try {
      if (!editedMessage.encryptedContent) {
        throw new Error('No encrypted content available');
      }

      let decryptedContent;
      if (editedMessage.securityLevel === 'enhanced') {
        decryptedContent = EncryptionService.decrypt(
          editedMessage.encryptedContent, 
          decryptionKey
        );
      } else {
        decryptedContent = editedMessage.encryptedContent;
      }

      // Set both content and key after successful decryption
      setEditedMessage(prev => ({
        ...prev,
        content: decryptedContent
      }));
      setEncryptionKey(decryptionKey);
      setIsDecrypted(true);
      setShowDecryptionPrompt(false);

      // Handle attachment decryption if needed
      if (editedMessage.attachments?.length > 0) {
        const decryptedAttachments = editedMessage.attachments.map(att => {
          if (att.encryptedKey && editedMessage.securityLevel === 'enhanced') {
            try {
              const decryptedKey = EncryptionService.decrypt(
                att.encryptedKey, 
                decryptionKey
              );
              return { ...att, decryptedKey };
            } catch (error) {
              console.error('Failed to decrypt attachment key:', error);
              return att;
            }
          }
          return att;
        });
        setFiles(decryptedAttachments);
      }
    } catch (error) {
      console.error('Decryption failed:', error);
      toast.error('Failed to decrypt message. Please check your key and try again.');
    }
  };

  const uploadFile = async (file) => {
    try {
      console.log('Starting upload process for file:', {
        name: file.name,
        size: file.size,
        type: file.type
      });
  
      const chunkSize = 1024 * 1024;
      const fileSize = file.size;
  
      let encryptedFile = file;
      if (editedMessage.securityLevel === 'enhanced') {
        console.log('Enhanced security enabled, encrypting file...');
        const messageEncryptionKey = encryptionKey || EncryptionService.generateKey();
        encryptedFile = await EncryptionService.encryptFile(file, messageEncryptionKey);
        if (!encryptionKey) {
          setEncryptionKey(messageEncryptionKey);
        }
        console.log('File encryption complete');
      }
  
      return new Promise((resolve, reject) => {
        // Ensure the endpoint is properly formatted
        const endpoint = process.env.REACT_APP_API_URL.replace(/\/$/, '') + '/uploads';
        
        // Clear any existing upload data
        const storageKey = `tus::${file.name}::${file.size}`;
        const existingData = localStorage.getItem(storageKey);
        if (existingData) {
          console.log('Clearing existing upload data:', { storageKey, existingData });
        }
        localStorage.removeItem(storageKey);
  
        console.log('Upload configuration:', {
          endpoint,
          chunkSize,
          fileSize,
          messageId: editedMessage._id,
          securityLevel: editedMessage.securityLevel
        });
  
        // Get CSRF token
        const csrfToken = document.cookie
          .split('; ')
          .find(row => row.startsWith('XSRF-TOKEN'))
          ?.split('=')[1];
  
        const upload = new tus.Upload(encryptedFile, {
          endpoint,
          uploadUrl: null,
          chunkSize,
          storeFingerprintForResuming: false,
          removeFingerprintOnSuccess: true,
          metadata: {
            filename: encryptedFile.name || file.name,
            filetype: 'application/octet-stream',
            messageId: editedMessage._id || '',
            securityLevel: editedMessage.securityLevel,
            fileExtension: file.name.split('.').pop()
          },
          headers: {
            'X-CSRF-Token': csrfToken
          },
          withCredentials: true,
          retryDelays: [0, 1000, 3000, 5000],
          onBeforeRequest: (req) => {
            try {
              // Log request details before modification
              console.log('TUS Request (before modification):', {
                url: req.url,
                method: typeof req.getMethod === 'function' ? req.getMethod() : req.method,
                headers: req.headers
              });
  
              // Ensure all requests use HTTPS
              if (req.url && typeof req.url === 'string' && req.url.startsWith('http:')) {
                const originalUrl = req.url;
                req.url = req.url.replace('http:', 'https:');
                console.log('Modified request URL:', {
                  from: originalUrl,
                  to: req.url
                });
              }
  
              // Ensure CSRF token is present
              if (csrfToken && req.headers) {
                req.headers['X-CSRF-Token'] = csrfToken;
              }
  
              // Log final request configuration
              console.log('TUS Request (after modification):', {
                url: req.url,
                method: typeof req.getMethod === 'function' ? req.getMethod() : req.method,
                headers: req.headers
              });
            } catch (error) {
              console.error('Error in onBeforeRequest:', error);
            }
          },
          onAfterResponse: (req, res) => {
            try {
              console.log('TUS Response:', {
                method: typeof req.getMethod === 'function' ? req.getMethod() : req.method,
                url: req.url,
                status: typeof res.getStatus === 'function' ? res.getStatus() : res.status,
                headers: typeof res.getHeaders === 'function' ? res.getHeaders() : res.headers
              });
            } catch (error) {
              console.error('Error in onAfterResponse:', error);
            }
          },
          onChunkComplete: (chunkSize, bytesUploaded, bytesTotal) => {
            console.log('Chunk upload complete:', {
              chunkSize,
              bytesUploaded,
              bytesTotal,
              progress: Math.round((bytesUploaded / bytesTotal) * 100) + '%'
            });
          },
          onProgress: (bytesUploaded, bytesTotal) => {
            const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
            setUploads(prev => ({ ...prev, [file.name]: percentage }));
          },
          onError: (error) => {
            console.error('TUS upload error:', {
              error: error.message,
              stack: error.stack,
              endpoint,
              url: upload.url,
              uploadState: {
                bytesUploaded: upload.offset,
                bytesTotal: upload.size,
                isComplete: upload.isComplete(),
                url: upload.url,
                options: upload.options
              },
              fileDetails: {
                name: file.name,
                size: file.size,
                type: file.type
              }
            });
            
            if (error.message?.includes('req.getHeaders')) {
              console.log('Headers compatibility issue detected, retrying with alternative method');
              upload.options.headers = { 'X-CSRF-Token': csrfToken };
              upload.start();
              return;
            }
            
            if (error.message?.includes('Mixed Content') || error.message?.includes('blocked')) {
              console.log('Attempting retry with HTTPS');
              localStorage.removeItem(storageKey);
              toast.error('Retrying upload with secure connection...');
              upload.options.endpoint = endpoint.replace('http:', 'https:');
              upload.start();
              return;
            }
            
            if (error.message?.includes('authorization')) {
              toast.error('Upload failed: Please log in again');
              api.auth.refreshToken().catch(() => {
                window.location.href = '/login';
              });
            } else {
              reject(error);
            }
          },
          onSuccess: async () => {
            console.log('Upload completed successfully:', {
              fileId: upload.url?.split('/').pop(),
              size: fileSize,
              endpoint,
              url: upload.url
            });
  
            try {
              const tusId = upload.url?.split('/').pop();
              console.log('Processing uploaded file:', {
                tusId,
                messageId: editedMessage._id,
                filename: file.name
              });
  
              const response = await api.post('/api/files/upload', {
                messageId: editedMessage._id || '',
                tusId: tusId || '',
                contentType: file.type || 'application/octet-stream',
                size: fileSize,
                filename: file.name,
                fileExtension: file.name.split('.').pop(),
                securityLevel: editedMessage.securityLevel
              });
              
              console.log('File processing complete:', {
                tusId,
                attachment: response.data.attachment
              });
  
              resolve(response.data.attachment);
            } catch (error) {
              console.error('Failed to process uploaded file:', {
                error: error.message,
                stack: error.stack,
                fileDetails: {
                  name: file.name,
                  size: fileSize,
                  tusId: upload.url?.split('/').pop()
                }
              });
              reject(error);
            }
          }
        });
  
        console.log('Starting upload with configuration:', {
          endpoint,
          uploadUrl: upload.url,
          options: {
            ...upload.options,
            headers: '<omitted for security>'
          },
          fileDetails: {
            name: file.name,
            size: fileSize,
            type: file.type
          }
        });
  
        upload.start();
      });
    } catch (error) {
      console.error('Failed to start upload:', {
        error: error.message,
        stack: error.stack,
        file: {
          name: file.name,
          size: file.size,
          type: file.type
        }
      });
      throw error;
    }
  };

  // MessageEditor.jsx
  const handleSave = async (shouldClose = false) => {
    if (isSaving) return;
    if (!editedMessage.title.trim()) {
      toast.error('Please enter a message title');
      return;
    }
    
    setIsSaving(true);
    
    try {
      // Keep track of current files without modifying them
      const existingFiles = files.filter(file => !file.isNew);
      const newFiles = files.filter(file => file.isNew);
  
      console.log('Current files state:', {
        existing: existingFiles.map(f => ({ id: f.pcloudId, name: f.filename })),
        new: newFiles.map(f => ({ id: f.pcloudId, name: f.name }))
      });
  
      // Only process new files that haven't been uploaded
      const unprocessedFiles = newFiles.filter(file => !file.pcloudId);
      const uploadPromises = unprocessedFiles.map(file => {
        if (file.uploadPromise) return file.uploadPromise;
  
        const uploadPromise = uploadFile(file.file).catch(error => {
          console.error('Upload failed:', error);
          toast.error(`Failed to upload ${file.name}: ${error.message}`);
          return null;
        });
  
        file.uploadPromise = uploadPromise;
        return uploadPromise;
      });
  
      const uploadResults = (await Promise.all(uploadPromises))
        .filter(Boolean);
  
      // Build complete attachments list preserving file states
      const allAttachments = [
        ...existingFiles,
        ...newFiles.filter(f => f.pcloudId),
        ...uploadResults
      ].filter(Boolean);
  
      // Prepare message update data
      const messageData = {
        title: editedMessage.title,
        type: editedMessage.type || 'email',
        recipients,
        attachments: allAttachments.map(file => ({
          filename: file.filename || file.name,
          pcloudId: file.pcloudId,
          pcloudUrl: file.pcloudUrl,
          contentType: file.contentType || file.type,
          size: file.size
        })),
        securityLevel: editedMessage.securityLevel,
        // Handle content based on security level
        encryptedContent: editedMessage.securityLevel === 'enhanced' 
          ? EncryptionService.encrypt(editedMessage.content, encryptionKey)
          : editedMessage.content // For standard security, server will handle encryption
      };
  
      // Determine if updating or creating
      let result;
      if (editedMessage._id || editedMessage.id) {
        const messageId = editedMessage._id || editedMessage.id;
        console.log('Updating existing message:', messageId);
        
        result = await dispatch(updateMessage({
          id: messageId,
          ...messageData
        })).unwrap();
      } else {
        console.log('Creating new message');
        result = await dispatch(createMessage(messageData)).unwrap();
      }
  
      // Update local state while preserving encryption state
      setEditedMessage(prev => ({
        ...prev,
        ...result,
        // For display in editor:
        // Enhanced: keep current content (already decrypted)
        // Standard: use returned encryptedContent as content
        content: editedMessage.securityLevel === 'enhanced' ? 
          prev.content : 
          result.encryptedContent,
        _id: result.id || result._id, // Ensure we capture the ID
        securityLevel: editedMessage.securityLevel,
        type: editedMessage.type || 'email'
      }));
  
      // Update files state preserving upload information
      setFiles(prevFiles => 
        prevFiles.map(file => {
          const savedAttachment = result.attachments?.find(att => 
            att.pcloudId === file.pcloudId || 
            (file.isNew && att.filename === file.name)
          );
  
          if (savedAttachment) {
            return {
              ...savedAttachment,
              isNew: false,
              uploadPromise: file.uploadPromise
            };
          }
          return file;
        })
      );
  
      setIsMessageSaved(true);
  
      if (shouldClose) {
        onSave(result);
        onClose();
      }
  
      toast.success('Message saved successfully');
    } catch (error) {
      console.error('Failed to save message:', error);
      toast.error(`Failed to save message: ${error.message}`);
    } finally {
      setIsSaving(false);
    }
  };

  const handleNewFiles = async (newFiles) => {
    if (!isMessageSaved) {
      toast.error('Please save the message before adding attachments');
      return;
    }

    const fileArray = Array.from(newFiles);

    // Validate all files first
    const validationResults = fileArray.map(file => ({
      file,
      ...FileHandlingService.validateFile(file)
    }));

    // Handle invalid files
    const invalidFiles = validationResults.filter(result => !result.valid);
    invalidFiles.forEach(({ file, error, maxSizeFormatted, fileSizeFormatted }) => {
      toast.error(
        <div className="space-y-1">
          <p className="font-medium">{file.name}</p>
          <p>{error}</p>
          <p className="text-sm">
            File size: {fileSizeFormatted}<br />
            Maximum allowed: {maxSizeFormatted}
          </p>
        </div>,
        { autoClose: 5000 } // Give users more time to read the message
      );
    });

    // Process only valid files
    const validFiles = validationResults.filter(result => result.valid);
    setProcessingFiles(prev => [...prev, ...validFiles.map(f => f.file.name)]);

    for (const { file, warnings } of validFiles) {
      try {
        // Show warnings if any
        if (warnings && warnings.length > 0) {
          toast.warning(
            <div className="space-y-1">
              <p className="font-medium">{file.name}</p>
              {warnings.map((warning, index) => (
                <p key={index} className="text-sm">{warning}</p>
              ))}
            </div>,
            { autoClose: 5000 }
          );
        }

        const fileObj = {
          name: file.name,
          type: file.type,
          file: file,
          isNew: true,
          uploadPromise: null,
        };

        setFiles(prevFiles => [...prevFiles, fileObj]);
        
        const uploadPromise = uploadFile(file)
          .then(attachment => {
            setFiles(prevFiles => prevFiles.map(f => 
              f.name === fileObj.name ? { ...attachment, isNew: false } : f
            ));
            setEditedMessage(prevMessage => ({
              ...prevMessage,
              attachments: [...(prevMessage.attachments || []), attachment]
            }));
            setProcessingFiles(prev => prev.filter(name => name !== file.name));
            toast.success(`${file.name} uploaded successfully`);
            return attachment;
          })
          .catch(error => {
            console.error('Failed to upload file:', error);
            let errorMessage = error.message;
            
            // Handle specific error types
            if (error.response?.status === 413) {
              const { error: serverError, remainingStorageFormatted, storageLimitFormatted } = error.response.data;
              if (serverError === 'Storage quota exceeded') {
                errorMessage = `Storage quota exceeded. You have ${remainingStorageFormatted} remaining out of ${storageLimitFormatted}`;
              } else {
                const { maxSizeFormatted, fileSizeFormatted } = error.response.data;
                errorMessage = `File too large (${fileSizeFormatted}). Maximum size allowed: ${maxSizeFormatted}`;
              }
            }
            
            setFiles(prevFiles => prevFiles.filter(f => f.name !== fileObj.name));
            setUploadErrors(prev => ({
              ...prev,
              [file.name]: errorMessage
            }));
            setProcessingFiles(prev => prev.filter(name => name !== file.name));
            toast.error(
              <div className="space-y-1">
                <p className="font-medium">{file.name}</p>
                <p>{errorMessage}</p>
              </div>,
              { autoClose: 5000 }
            );
            throw error;
          });

        setFiles(prevFiles => prevFiles.map(f => 
          f.name === fileObj.name ? { ...f, uploadPromise } : f
        ));

      } catch (error) {
        setUploadErrors(prev => ({
          ...prev,
          [file.name]: error.message || 'Processing failed'
        }));
        setProcessingFiles(prev => prev.filter(name => name !== file.name));
        toast.error(
          <div className="space-y-1">
            <p className="font-medium">{file.name}</p>
            <p>{error.message || 'Processing failed'}</p>
          </div>,
          { autoClose: 5000 }
        );
      }
    }
};

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleNewFiles,
  });

  const handleRemoveFile = (index) => {
    setFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
    setUploads(prev => {
      const newUploads = { ...prev };
      delete newUploads[files[index].name];
      return newUploads;
    });
  };

  const handleDownload = async (fileId) => {
    try {
      const response = await api.get(`/files/${editedMessage._id}/${fileId}`);
      window.open(response.data.url, '_blank');
    } catch (error) {
      console.error('Failed to download file:', error);
    }
  };

  const toggleViewMode = () => {
    setViewMode(!viewMode);
  };

  const isValidEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const isValidPhoneNumber = (number) => {
    const phoneRegex = /^\+[1-9]\d{1,14}$/;
    return phoneRegex.test(number);
  };

  // const handleKeyDown = (e) => {
  //   if (e.key === 'Enter' || e.key === ',') {
  //     e.preventDefault();
  //     const trimmedInput = currentInput.trim();
  //     if (editedMessage.type === 'email' && isValidEmail(trimmedInput)) {
  //       setRecipients(prev => [...prev, trimmedInput]);
  //       setCurrentInput('');
  //     } else if (editedMessage.type === 'sms' && isValidPhoneNumber(trimmedInput)) {
  //       setRecipients(prev => [...prev, trimmedInput]);
  //       setCurrentInput('');
  //     }
  //   }
  // };

  const handleInputBlur = () => {
    validateAndAddRecipients();
  };

  const validateAndAddRecipients = () => {
    const trimmedInput = currentInput.trim();
    if (trimmedInput) {
      if (editedMessage.type === 'email' && isValidEmail(trimmedInput)) {
        setRecipients(prev => [...prev, trimmedInput]);
      } else if (editedMessage.type === 'sms' && isValidPhoneNumber(trimmedInput)) {
        setRecipients(prev => [...prev, trimmedInput]);
      }
      setCurrentInput('');
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === ',') {
      e.preventDefault();
      validateAndAddRecipients();
    }
  };

  const removeRecipient = (index) => {
    setRecipients((prev) => prev.filter((_, i) => i !== index));
  };


  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
      <div className="relative bg-white rounded-lg shadow-xl max-w-4xl w-full max-h-[90vh] overflow-y-auto p-6 custom-scrollbar">
        <div className="flex justify-between items-center pb-3 border-b">
          <h2 className="text-2xl font-semibold text-gray-700">
            {viewMode ? 'View Message' : (mode === 'edit' ? 'Edit Message' : 'Create Message')}
          </h2>
          <div className="flex space-x-2">
            {mode !== 'create' && (
              <button onClick={toggleViewMode} className="text-gray-400 hover:text-gray-500">
                {viewMode ? <PencilIcon className="h-6 w-6" /> : <EyeIcon className="h-6 w-6" />}
              </button>
            )}
            <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
              <XMarkIcon className="h-6 w-6" />
            </button>
          </div>
        </div>
  
        {showDecryptionPrompt && (
  <div className="mt-4 p-4 bg-yellow-50 rounded-md">
    <h3 className="text-lg font-medium text-yellow-900 mb-2">Decryption Required</h3>
    <p className="text-sm text-yellow-700 mb-4">This message is encrypted. Please enter the decryption key to view or edit the content.</p>
    <div className="flex items-center">
      <input
        type="password"
        value={decryptionKey}
        onChange={(e) => setDecryptionKey(e.target.value)}
        className="flex-grow p-2 border border-yellow-300 rounded-md focus:outline-none focus:ring-2 focus:ring-yellow-500"
        placeholder="Enter decryption key"
      />
      <button
        onClick={handleDecryptionKeySubmit}
        className="ml-2 px-4 py-2 bg-yellow-500 text-white rounded-md hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-yellow-500"
      >
        Decrypt
      </button>
    </div>
  </div>
)}
  
        {!showDecryptionPrompt && (
          <div className="mt-4 space-y-4">
            <div className="flex justify-between items-center">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Message Type</label>
                <div className="flex space-x-2">
                  <button
                    type="button"
                    onClick={() => setEditedMessage(prev => ({ ...prev, type: 'email' }))}
                    disabled={mode === 'edit' || viewMode}
                    className={`px-4 py-2 rounded-md ${
                      editedMessage.type === 'email'
                        ? 'bg-blue-600 text-white'
                        : 'bg-gray-200 text-gray-700'
                    } ${(mode === 'edit' || viewMode) && 'opacity-50 cursor-not-allowed'}`}
                  >
                    Email
                  </button>
                  <button
                    type="button"
                    onClick={() => setEditedMessage(prev => ({ ...prev, type: 'sms' }))}
                    disabled={mode === 'edit' || viewMode}
                    className={`px-4 py-2 rounded-md ${
                      editedMessage.type === 'sms'
                        ? 'bg-blue-600 text-white'
                        : 'bg-gray-200 text-gray-700'
                    } ${(mode === 'edit' || viewMode) && 'opacity-50 cursor-not-allowed'}`}
                  >
                    SMS
                  </button>
                </div>
              </div>
              {!viewMode && (
                <div className="flex items-center">
                  <span className="mr-2 text-sm font-medium text-gray-700">Local Encryption</span>
                  <button
                    onClick={handleSecurityLevelChange}
                    disabled={mode === 'edit'}
                    className={`relative inline-flex items-center h-6 rounded-full w-11 ${
                      editedMessage.securityLevel === 'enhanced' ? 'bg-blue-600' : 'bg-gray-200'
                    } ${mode === 'edit' && 'opacity-50 cursor-not-allowed'}`}
                  >
                    <span className="sr-only">Toggle enhanced security</span>
                    <span
                      className={`${
                        editedMessage.securityLevel === 'enhanced' ? 'translate-x-6' : 'translate-x-1'
                      } inline-block w-4 h-4 transform bg-white rounded-full transition-transform`}
                    />
                  </button>
                </div>
              )}
            </div>
  
            {editedMessage.securityLevel === 'enhanced' && !viewMode && (
              <div className="mt-4 p-3 bg-blue-50 rounded-md">
                <p className="text-sm font-medium text-gray-700 mb-2">Encryption Key:</p>
                <div className="flex items-center">
                  <code className="flex-grow p-2 bg-blue-100 rounded font-mono text-sm">
                    {encryptionKey}
                  </code>
                  <button
                    onClick={copyEncryptionKey}
                    className="ml-2 p-2 text-blue-600 hover:text-blue-800"
                    title="Copy to clipboard"
                  >
                    <ClipboardIcon className="h-5 w-5" />
                  </button>
                </div>
              </div>
            )}
  
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Title</label>
              {viewMode ? (
                <p className="text-gray-900">{editedMessage.title}</p>
              ) : (
                <input
                  type="text"
                  name="title"
                  value={editedMessage.title}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
              )}
            </div>
  
            {editedMessage.type === 'email' && (
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Send from</label>
                {viewMode ? (
                  <p className="text-gray-900">{editedMessage.sender}</p>
                ) : (
                  <>
                    <select
                      name="sender"
                      value={editedMessage.sender}
                      onChange={handleInputChange}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                    >
                      {/* {userEmails.map((email) => (
                        <option key={email} value={email}>{email}</option>
                      ))} */}
                      <option value="myecho">Secure Echo Email</option>
                    </select>
                    {editedMessage.sender === 'myecho' && (
                      <div className="mt-1 text-green-600 flex items-center">
                        <CheckCircleIcon className="h-4 w-4 mr-1" />
                        <span className="text-sm">Verified secure email</span>
                      </div>
                    )}
                  </>
                )}
              </div>
            )}
  
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                {editedMessage.type === 'email' ? 'Recipients' : 'Phone Number(s)'}
              </label>
              {viewMode ? (
                <p className="text-gray-900">{recipients.join(', ')}</p>
              ) : (
                <div className="flex flex-wrap items-center gap-2 border border-gray-300 rounded-md p-2">
                  {recipients.map((recipient, index) => (
                    <div key={index} className="bg-blue-100 text-blue-800 px-2 py-1 rounded-full text-sm flex items-center">
                      {recipient}
                      <button onClick={() => removeRecipient(index)} className="ml-2 text-blue-600 hover:text-blue-800">
                        &times;
                      </button>
                    </div>
                  ))}
                  <input
                    type="text"
                    value={currentInput}
                    onChange={(e) => setCurrentInput(e.target.value)}
                    onKeyDown={handleKeyDown}
                    onBlur={handleInputBlur}
                    className="flex-grow px-2 py-1 focus:outline-none"
                    placeholder={editedMessage.type === 'email' 
                      ? "Enter email addresses and press Enter or comma to add"
                      : "Enter phone numbers with country code (e.g., +1234567890)"
                    }
                  />
                </div>
              )}
            </div>
  
            <div className="mb-4">
              <div className="flex items-center justify-between mb-2">
                <label className="block text-sm font-medium text-gray-700">Content</label>
                <SecurityTips 
                  securityLevel={editedMessage.securityLevel} 
                  type="content" 
                />
              </div>
              {viewMode ? (
                <div className="prose max-w-none" dangerouslySetInnerHTML={{ __html: editedMessage.content }} />
              ) : (
                <SimpleTextEditor
                  value={editedMessage.content}
                  onChange={handleContentChange}
                  placeholder="Enter your message content here..."
                />
              )}
            </div>
  
            {editedMessage.type === 'email' && (
              <div className="mb-4">
                <div className="flex items-center justify-between mb-2">
                  <label className="block text-sm font-medium text-gray-700">Attachments</label>
                  <SecurityTips 
                    securityLevel={editedMessage.securityLevel} 
                    type="files" 
                  />
                </div>
                {viewMode ? (
                  <ul className="mt-2 space-y-1">
                    {files.map((file, index) => (
                      <li key={index} className="flex items-center justify-between bg-gray-100 p-2 rounded">
                        <div className="flex items-center">
                          <PaperClipIcon className="h-5 w-5 text-gray-500 mr-2" />
                          <span className="text-sm text-gray-700">{file.filename || file.name}</span>
                        </div>
                        <button
                          onClick={() => handleDownload(file.pcloudId)}
                          className="text-blue-500 hover:text-blue-700"
                        >
                          Download
                        </button>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <div>
                    {!isMessageSaved ? (
                      <div className="border-2 border-dashed border-gray-300 rounded-md p-4 bg-gray-50">
                        <p className="text-center text-gray-500">
                          Please save the message before adding attachments
                        </p>
                      </div>
                    ) : (
<div
  {...getRootProps()}
  className={`border-2 border-dashed ${
    isDragActive ? 'border-blue-500 bg-blue-50' : 'border-gray-300'
  } rounded-md p-4 text-center hover:border-blue-500 transition duration-150 ease-in-out cursor-pointer`}
>
  <input {...getInputProps()} disabled={!isMessageSaved} />
  {isDragActive ? (
    <p className="text-blue-500">Drop the files here ...</p>
  ) : (
    <div>
      <p>Drag 'n' drop some files here, or click to select files</p>
      <p className="text-sm text-gray-500 mt-2">
        Maximum file size: 1GB - Use unique filenames
      </p>
      {user?.storage && (
        <div className="mt-4">
          <div className="text-sm text-gray-600 mb-1">
            Storage used: {FileHandlingService.formatSize(user.storage.used)} of {FileHandlingService.formatSize(user.storage.limit)}
          </div>
          <div className="w-full bg-gray-200 rounded-full h-2">
            <div 
              className="bg-blue-600 h-2 rounded-full transition-all duration-300"
              style={{ width: `${(user.storage.used / user.storage.limit) * 100}%` }}
            />
          </div>
        </div>
      )}
    </div>
  )}
</div>
                    )}
                    {files.length > 0 && (
                      <ul className="mt-2 space-y-1">
                        {files.map((file, index) => (
                          <li key={index} className="flex items-center justify-between bg-gray-100 p-2 rounded">
                            <div className="flex items-center">
                              <PaperClipIcon className="h-5 w-5 text-gray-500 mr-2" />
                              <span className="text-sm text-gray-700">{file.filename || file.name}</span>
                            </div>
                            <div className="flex items-center space-x-2">
                              {file.isNew && (
                                <span className="text-xs text-blue-500">
                                  {uploads[file.name] === '100.00' ? 'Almost Done' : `${uploads[file.name] || 0}%`}
                                </span>
                              )}
                              {file.uploadError && (
                                <span className="text-xs text-red-500">Upload failed</span>
                              )}
                              <button
                                onClick={() => handleRemoveFile(index)}
                                className="text-red-500 hover:text-red-700"
                              >
                                Remove
                              </button>
                            </div>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        )}
  
        {!viewMode && !showDecryptionPrompt && (
          <div className="mt-6  flex justify-end space-x-4">
            <button
              onClick={() => handleSave(false)}
              disabled={isSaving}
              className={`px-4 py-2 bg-gray-600 text-white rounded-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 ${
                isSaving ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            >
              {isSaving ? 'Saving...' : 'Save'}
            </button>
            <button
              onClick={() => handleSave(true)}
              disabled={isSaving}
              className={`px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${
                isSaving ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            >
              {isSaving ? 'Saving...' : 'Save & Close'}
            </button>
          </div>
        )}
        
        {showEnhancedSecurityPopup && (
          <EnhancedSecurityPopup onClose={handleEnhancedSecurityConfirm} />
        )}
      </div>
    </div>
  );
};

export default MessageEditor;