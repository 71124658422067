import React from 'react';
import { 
  CheckIcon, 
  ShieldCheckIcon, 
  BoltIcon,
  DocumentCheckIcon,
  QuestionMarkCircleIcon
} from '@heroicons/react/24/outline';

const Pricing = () => {
  const plans = [
    {
      name: "Essential",
      tagline: "Perfect for personal legacy planning",
      price: 10,
      billingCycle: "per 6 months",
      features: [
        "Up to 10 encrypted messages",
        "Basic message templates",
        "File attachments up to 100MB",
        "Standard security encryption",
        "Basic check-in trigger",
        "Email support"
      ],
      highlight: false,
      path: '/signup',
      cta: "Start Free Trial",
      popular: false
    },
    {
      name: "Premium Security",
      tagline: "Recommended for comprehensive legacy planning",
      price: 20,
      billingCycle: "per 6 months",
      features: [
        "Unlimited encrypted messages",
        "Advanced message templates",
        "File attachments up to 1GB",
        "Enhanced local encryption",
        "Advanced trigger system",
        "10 trusted contacts",
        "Unlimited message history",
        "Priority support",
        "Advanced security features"
      ],
      highlight: true,
      cta: "Get Enhanced Security",
      popular: true,
      path: '/signup',
      bestFor: ["Families", "Business Owners", "Digital Asset Holders"]
    },
    {
      name: "Enterprise",
      tagline: "Custom solutions for organizations",
      price: null,
      features: [
        "Everything in Premium Security",
        "Custom message workflows",
        "Unlimited file storage",
        "Custom security requirements",
        "Custom trigger logic",
        "Unlimited trusted contacts",
        "Advanced audit logs",
        "SSO integration",
        "Dedicated account manager"
      ],
      highlight: false,
      path: '/contact',
      cta: "Contact Sales",
      popular: false
    }
  ];

  return (
    <section className="relative py-24 bg-gradient-to-b from-white to-gray-50 overflow-hidden">
      {/* Background decorative elements */}
      <div className="absolute inset-0">
        <div className="absolute inset-y-0 right-0 w-1/2 bg-gradient-to-l from-blue-50 to-transparent" />
        <div className="absolute bottom-0 left-0 right-0 h-px bg-gradient-to-r from-transparent via-blue-200 to-transparent" />
      </div>

      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <span className="inline-block px-4 py-2 rounded-full bg-blue-100 text-blue-700 text-sm font-medium mb-4">
            Simple Pricing
          </span>

          <h2 className="text-4xl font-bold text-gray-900 mb-4">
            Choose Your Legacy Plan
          </h2>

          <p className="text-xl text-gray-600 max-w-2xl mx-auto mb-8">
            Start with a 14-day free trial. No credit card required.
          </p>

          {/* Billing Note */}
          <p className="text-sm text-gray-500">
            Billed every 6 months to ensure uninterrupted service during check-in periods.
          </p>
        </div>

        {/* Pricing Cards */}
        <div className="grid md:grid-cols-3 gap-8">
          {plans.map((plan, index) => (
            <div
              key={index}
              className={`relative ${plan.highlight ? 'md:scale-105' : ''}`}
            >
              <div className={`relative rounded-2xl overflow-hidden transition-all duration-300 ${
                plan.highlight 
                  ? 'bg-white shadow-xl ring-2 ring-blue-600' 
                  : 'bg-white/80 backdrop-blur-sm shadow-lg hover:shadow-xl'
              }`}>
                {plan.popular && (
                  <div className="absolute top-0 right-0 bg-blue-600 text-white px-3 py-1 text-sm font-semibold rounded-bl-lg">
                    Most Popular
                  </div>
                )}

                <div className="p-6">
                  <h3 className="text-xl font-bold text-gray-900 mb-2">
                    {plan.name}
                  </h3>
                  <p className="text-sm text-gray-500 mb-4">{plan.tagline}</p>

                  {/* Pricing */}
                  <div className="mb-6">
                    {plan.price ? (
                      <>
                        <span className="text-3xl font-bold text-gray-900">${plan.price}</span>
                        <span className="text-gray-500">/{plan.billingCycle}</span>
                      </>
                    ) : (
                      <span className="text-2xl font-bold text-gray-900">Custom</span>
                    )}
                  </div>

                  {/* Features */}
                  <ul className="space-y-2 mb-6">
                    {plan.features.map((feature, idx) => (
                      <li key={idx} className="flex items-start text-sm">
                        <CheckIcon className="h-5 w-5 text-green-500 mr-2 flex-shrink-0" />
                        <span className="text-gray-600">{feature}</span>
                      </li>
                    ))}
                  </ul>

                  {/* CTA Button */}
                  <button
                    className={`w-full px-4 py-2 rounded-lg text-center font-medium transition-all duration-200 ${
                      plan.highlight
                        ? 'bg-blue-600 text-white hover:bg-blue-700'
                        : 'bg-gray-100 text-gray-900 hover:bg-gray-200'
                    }`}
                  >
                    {plan.cta}
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Trust Indicators */}
        <div className="mt-16 grid grid-cols-1 md:grid-cols-3 gap-8 text-center">
          <div className="p-6 bg-white/80 backdrop-blur-sm rounded-xl shadow-sm">
            <div className="text-blue-600 font-bold text-2xl mb-1">14-Day</div>
            <div className="text-gray-600">Free Trial</div>
          </div>
          <div className="p-6 bg-white/80 backdrop-blur-sm rounded-xl shadow-sm">
            <div className="text-blue-600 font-bold text-2xl mb-1">24/7</div>
            <div className="text-gray-600">Support</div>
          </div>
          <div className="p-6 bg-white/80 backdrop-blur-sm rounded-xl shadow-sm">
            <div className="text-blue-600 font-bold text-2xl mb-1">99.9%</div>
            <div className="text-gray-600">Uptime</div>
          </div>
        </div>

        {/* FAQ Preview */}
        <div className="mt-16 text-center">
          <p className="text-gray-600">
            Have questions? Check our{' '}
            <a href="#faq" className="text-blue-600 hover:text-blue-700 font-medium">
              FAQ
            </a>
            {' '}or{' '}
            <a href="/contact" className="text-blue-600 hover:text-blue-700 font-medium">
              contact our team
            </a>
          </p>
        </div>
      </div>
    </section>
  );
};

export default Pricing;
