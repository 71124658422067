import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
  fetchUserProfile, 
  updateUserProfile, 
  updateUserSettings, 
  changePassword 
} from '../../store/slices/userSlice';
import { Switch } from '@headlessui/react';
import { BellIcon, ShieldCheckIcon, UserCircleIcon } from '@heroicons/react/24/outline';
import { toast } from 'react-toastify';

const SettingsPage = () => {
  const dispatch = useDispatch();
  const { profile, settings, status } = useSelector(state => state.user);
  const [activeTab, setActiveTab] = useState('profile');

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    notificationPreferences: {
      email: false,
      sms: false,
    },
    twoFactorAuth: {
      isEnabled: false,
      method: 'email',
    },
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  useEffect(() => {
    dispatch(fetchUserProfile());
  }, [dispatch]);

  useEffect(() => {
    if (profile && settings) {
      setFormData(prev => ({
        ...prev,
        name: profile.name || '',
        email: profile.email || '',
        notificationPreferences: settings.notificationPreferences,
        twoFactorAuth: settings.twoFactorAuth,
      }));
    }
  }, [profile, settings]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleToggleChange = (field) => {
    setFormData(prev => ({
      ...prev,
      notificationPreferences: {
        ...prev.notificationPreferences,
        [field]: !prev.notificationPreferences[field],
      },
    }));
  };

  const handleTwoFactorToggle = () => {
    setFormData(prev => ({
      ...prev,
      twoFactorAuth: {
        ...prev.twoFactorAuth,
        isEnabled: !prev.twoFactorAuth.isEnabled,
      },
    }));
  };

  const handleTwoFactorMethodChange = (e) => {
    setFormData(prev => ({
      ...prev,
      twoFactorAuth: {
        ...prev.twoFactorAuth,
        method: e.target.value,
      },
    }));
  };

  const handleSubmit = useCallback(async (type) => {
    try {
      let result;
      switch (type) {
        case 'profile':
          result = await dispatch(updateUserProfile({ name: formData.name }));
          break;
        case 'notifications':
          result = await dispatch(updateUserSettings({ notificationPreferences: formData.notificationPreferences }));
          break;
        case '2fa':
          result = await dispatch(updateUserSettings({ twoFactorAuth: formData.twoFactorAuth }));
          break;
        case 'password':
          if (formData.newPassword !== formData.confirmPassword) {
            toast.error("New passwords don't match");
            return;
          }
          result = await dispatch(changePassword({
            currentPassword: formData.currentPassword,
            newPassword: formData.newPassword,
          }));
          break;
        default:
          return;
      }

      if (result.meta.requestStatus === 'fulfilled') {
        toast.success(`${type.charAt(0).toUpperCase() + type.slice(1)} updated successfully`);
        
        if (type === 'profile') {
          setFormData(prev => ({ ...prev, name: result.payload.name }));
        } else if (type === 'notifications' || type === '2fa') {
          setFormData(prev => ({ 
            ...prev, 
            ...result.payload 
          }));
        } else if (type === 'password') {
          setFormData(prev => ({ ...prev, currentPassword: '', newPassword: '', confirmPassword: '' }));
        }
        
        dispatch(fetchUserProfile());
      }
    } catch (error) {
      toast.error(`Failed to update ${type}: ${error.message}`);
    }
  }, [dispatch, formData]);

  if (status === 'loading') {
    return <div>Loading...</div>;
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-10">
       <div className="flex justify-between items-center mb-8">
    <h1 className="text-3xl font-bold text-gray-900">Settings</h1>
    <a
      href="/dashboard/subscription"
      className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
    >
      Manage Subscription
    </a>
  </div>
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <nav className="flex space-x-4 mb-6">
            {['profile', 'notifications', 'security'].map((tab) => (
              <button
                key={tab}
                onClick={() => setActiveTab(tab)}
                className={`px-3 py-2 rounded-md text-sm font-medium ${
                  activeTab === tab
                    ? 'bg-blue-100 text-blue-700'
                    : 'text-gray-500 hover:text-gray-700'
                }`}
              >
                {tab.charAt(0).toUpperCase() + tab.slice(1)}
              </button>
            ))}
          </nav>

          {activeTab === 'profile' && (
            <div className="space-y-6">
              <div>
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={formData.email}
                  disabled
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 bg-gray-100 sm:text-sm"
                />
              </div>
              <button
                onClick={() => handleSubmit('profile')}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Update Profile
              </button>
            </div>
          )}

          {activeTab === 'notifications' && (
            <div className="space-y-6">
              <Switch.Group>
                <div className="flex items-center justify-between">
                  <Switch.Label className="mr-4">Email Notifications</Switch.Label>
                  <Switch
                    checked={formData.notificationPreferences.email}
                    onChange={() => handleToggleChange('email')}
                    className={`${
                      formData.notificationPreferences.email ? 'bg-blue-600' : 'bg-gray-200'
                    } relative inline-flex items-center h-6 rounded-full w-11 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
                  >
                    <span
                      className={`${
                        formData.notificationPreferences.email ? 'translate-x-6' : 'translate-x-1'
                      } inline-block w-4 h-4 transform bg-white rounded-full transition-transform`}
                    />
                  </Switch>
                </div>
              </Switch.Group>
              <Switch.Group>
                <div className="flex items-center justify-between">
                  <Switch.Label className="mr-4">SMS Notifications</Switch.Label>
                  <Switch
                    checked={formData.notificationPreferences.sms}
                    onChange={() => handleToggleChange('sms')}
                    className={`${
                      formData.notificationPreferences.sms ? 'bg-blue-600' : 'bg-gray-200'
                    } relative inline-flex items-center h-6 rounded-full w-11 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
                  >
                    <span
                      className={`${
                        formData.notificationPreferences.sms ? 'translate-x-6' : 'translate-x-1'
                      } inline-block w-4 h-4 transform bg-white rounded-full transition-transform`}
                    />
                  </Switch>
                </div>
              </Switch.Group>
              <button
                onClick={() => handleSubmit('notifications')}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Update Notifications
              </button>
            </div>
          )}

          {activeTab === 'security' && (
            <div className="space-y-6">
              <Switch.Group>
                <div className="flex items-center justify-between">
                  <Switch.Label className="mr-4">Two-Factor Authentication</Switch.Label>
                  <Switch
                    checked={formData.twoFactorAuth.isEnabled}
                    onChange={handleTwoFactorToggle}
                    className={`${
                      formData.twoFactorAuth.isEnabled ? 'bg-blue-600' : 'bg-gray-200'
                    } relative inline-flex items-center h-6 rounded-full w-11 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
                  >
                    <span
                      className={`${
                        formData.twoFactorAuth.isEnabled ? 'translate-x-6' : 'translate-x-1'
                      } inline-block w-4 h-4 transform bg-white rounded-full transition-transform`}
                    />
                  </Switch>
                </div>
              </Switch.Group>
              {formData.twoFactorAuth.isEnabled && (
                <div>
                  <label htmlFor="twoFactorMethod" className="block text-sm font-medium text-gray-700">
                    Two-Factor Method
                  </label>
                  <select
                    id="twoFactorMethod"
                    name="twoFactorMethod"
                    value={formData.twoFactorAuth.method}
                    onChange={handleTwoFactorMethodChange}
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                  >
                    <option value="email">Email</option>
                    <option value="sms">SMS</option>
                    <option value="authenticator">Authenticator App</option>
                  </select>
                </div>
              )}
              <button
                onClick={() => handleSubmit('2fa')}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Update Two-Factor Authentication
              </button>
              {/* {!profile.googleId && (
                <div className="pt-6 border-t border-gray-200">
                  <h3 className="text-lg font-medium text-gray-900">Change Password</h3>
                  <div className="mt-6 space-y-6">
                    <div>
                      <label htmlFor="currentPassword" className="block text-sm font-medium text-gray-700">
                        Current Password
                      </label>
                      <input
                        type="password"
                        name="currentPassword"
                        id="currentPassword"
                        value={formData.currentPassword}
                        onChange={handleInputChange}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      />
                    </div>
                    <div>
                      <label htmlFor="newPassword" className="block text-sm font-medium text-gray-700">
                        New Password
                      </label>
                      <input
                        type="password"
                        name="newPassword"
                        id="newPassword"
                        value={formData.newPassword}
                        onChange={handleInputChange}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      />
                    </div>
                    <div>
                      <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">
                        Confirm New Password
                      </label>
                      <input
                        type="password"
                        name="confirmPassword"
                        id="confirmPassword"
                        value={formData.confirmPassword}
                        onChange={handleInputChange}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      />
                    </div>
                    <button
                      onClick={() => handleSubmit('password')}
                      className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      Change Password
                    </button>
                  </div>
                </div>
              )} */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SettingsPage;