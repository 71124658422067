import CryptoJS from 'crypto-js';

class EncryptionService {
  static generateKey() {
    return CryptoJS.lib.WordArray.random(256 / 8).toString(CryptoJS.enc.Hex);
  }

  static encrypt(content, key) {
    try {
      return CryptoJS.AES.encrypt(content, key).toString();
    } catch (error) {
      console.error('Encryption error:', error);
      throw new Error('Failed to encrypt content');
    }
  }

  static decrypt(encryptedContent, key) {
    try {
      const decrypted = CryptoJS.AES.decrypt(encryptedContent, key);
      return decrypted.toString(CryptoJS.enc.Utf8);
    } catch (error) {
      console.error('Decryption error:', error);
      throw new Error('Failed to decrypt content');
    }
  }

  static async encryptFile(file, key) {
    try {
      if (!file) {
        throw new Error('No file provided');
      }

      // Handle ArrayBuffer input
      let workingFile;
      if (file instanceof ArrayBuffer) {
        workingFile = new Blob([file], { type: 'application/octet-stream' });
      } else if (file instanceof Blob || file instanceof File) {
        workingFile = file;
      } else {
        throw new Error('Invalid file format provided');
      }

      const iv = crypto.getRandomValues(new Uint8Array(12));
      const salt = crypto.getRandomValues(new Uint8Array(16));

      // Determine if file is text based
      const isTextFile = workingFile.type.startsWith('text/') || 
                        workingFile.name?.toLowerCase().endsWith('.txt') ||
                        workingFile.name?.toLowerCase().endsWith('.md') ||
                        workingFile.name?.toLowerCase().endsWith('.csv');

      // Create metadata
      const metadata = {
        name: workingFile.name || 'unknown',
        type: workingFile.type || 'application/octet-stream',
        size: workingFile.size,
        lastModified: workingFile.lastModified || Date.now(),
        isText: isTextFile
      };

      // Read file content
      let fileContent;
      if (isTextFile) {
        try {
          const text = await new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsText(workingFile);
          });
          fileContent = new TextEncoder().encode(text);
        } catch (error) {
          console.error('Failed to read text file:', error);
          // Fallback to binary reading
          fileContent = new Uint8Array(await workingFile.arrayBuffer());
        }
      } else {
        fileContent = new Uint8Array(await workingFile.arrayBuffer());
      }

      // Prepare metadata buffer
      const metadataBuffer = new TextEncoder().encode(JSON.stringify(metadata));
      const metadataLength = new Uint32Array([metadataBuffer.length]);

      // Combine data
      const combinedData = new Uint8Array(
        metadataLength.byteLength + 
        metadataBuffer.byteLength + 
        fileContent.byteLength
      );

      combinedData.set(new Uint8Array(metadataLength.buffer), 0);
      combinedData.set(metadataBuffer, metadataLength.byteLength);
      combinedData.set(fileContent, metadataLength.byteLength + metadataBuffer.byteLength);

      // Derive encryption key
      const baseKey = await crypto.subtle.importKey(
        'raw',
        new TextEncoder().encode(key),
        { name: 'PBKDF2' },
        false,
        ['deriveBits', 'deriveKey']
      );

      const encryptionKey = await crypto.subtle.deriveKey(
        {
          name: 'PBKDF2',
          salt: salt,
          iterations: 100000,
          hash: 'SHA-256'
        },
        baseKey,
        { name: 'AES-GCM', length: 256 },
        false,
        ['encrypt']
      );

      // Encrypt
      const encrypted = await crypto.subtle.encrypt(
        { name: 'AES-GCM', iv },
        encryptionKey,
        combinedData
      );

      // Combine all components
      const result = new Uint8Array(
        salt.byteLength + iv.byteLength + encrypted.byteLength
      );
      result.set(salt, 0);
      result.set(iv, salt.byteLength);
      result.set(new Uint8Array(encrypted), salt.byteLength + iv.byteLength);

      return new Blob([result], { type: 'application/octet-stream' });
    } catch (error) {
      console.error('File encryption error:', error);
      throw new Error(`Failed to encrypt file: ${error.message}`);
    }
  }

  static async decryptFile(encryptedBlob, key) {
    try {
      const arrayBuffer = await encryptedBlob.arrayBuffer();
      const dataView = new Uint8Array(arrayBuffer);

      const salt = dataView.slice(0, 16);
      const iv = dataView.slice(16, 28);
      const encryptedData = dataView.slice(28);

      const baseKey = await crypto.subtle.importKey(
        'raw',
        new TextEncoder().encode(key),
        { name: 'PBKDF2' },
        false,
        ['deriveBits', 'deriveKey']
      );

      const decryptionKey = await crypto.subtle.deriveKey(
        {
          name: 'PBKDF2',
          salt: salt,
          iterations: 100000,
          hash: 'SHA-256'
        },
        baseKey,
        { name: 'AES-GCM', length: 256 },
        false,
        ['decrypt']
      );

      const decrypted = await crypto.subtle.decrypt(
        { name: 'AES-GCM', iv },
        decryptionKey,
        encryptedData
      );

      // Extract metadata
      const metadataLength = new Uint32Array(decrypted.slice(0, 4))[0];
      const metadataBytes = decrypted.slice(4, 4 + metadataLength);
      const metadata = JSON.parse(new TextDecoder().decode(metadataBytes));

      // Extract file content
      const fileData = new Uint8Array(decrypted.slice(4 + metadataLength));

      // Handle text files
      let finalData;
      if (metadata.isText) {
        finalData = new TextDecoder().decode(fileData);
      } else {
        finalData = fileData;
      }

      return new File([finalData], metadata.name, {
        type: metadata.type,
        lastModified: metadata.lastModified
      });
    } catch (error) {
      console.error('File decryption error:', error);
      throw new Error('Failed to decrypt file');
    }
  }
}

export default EncryptionService;