import React, { useState } from 'react';
import { Dialog } from '@headlessui/react';
import { CheckIcon, SparklesIcon } from '@heroicons/react/24/outline';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import PaymentForm from './PaymentForm';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const plans = [
  {
    name: 'Essential',
    price: 10,
    interval: '6 months',
    features: [
      'Up to 10 encrypted messages',
      'Basic message templates',
      'File attachments up to 100MB',
      'Standard security encryption',
      'Basic check-in trigger',
      '3 trusted contacts'
    ]
  },
  {
    name: 'Premium',
    price: 20,
    interval: '6 months',
    features: [
      'Unlimited encrypted messages',
      'Advanced message templates',
      'File attachments up to 1GB',
      'Enhanced local encryption',
      'Advanced trigger system',
      '10 trusted contacts',
      'Custom verification periods',
      'Priority support'
    ],
    highlighted: true
  }
];

const SubscriptionSetupModal = ({ isOpen }) => {
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [step, setStep] = useState('select-plan'); // ['select-plan', 'payment']
  const [isLoading, setIsLoading] = useState(false);

  const handlePlanSelect = (plan) => {
    setSelectedPlan(plan);
    setStep('payment');
  };

  return (
    <Dialog 
      open={isOpen} 
      onClose={() => {}} // Prevent closing
      className="fixed inset-0 z-50 overflow-y-auto"
    >
      <div className="fixed inset-0 bg-gray-900/90 backdrop-blur-sm" />

      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4">
          <Dialog.Panel className="w-full max-w-4xl transform bg-white rounded-2xl p-8">
            {step === 'select-plan' ? (
              <>
                <div className="text-center mb-8">
                  <Dialog.Title className="text-3xl font-bold text-gray-900 mb-4">
                    Welcome to Cryonote
                  </Dialog.Title>
                  <p className="text-lg text-gray-600 max-w-2xl mx-auto">
                    Start your 14-day free trial with any plan. Switch or cancel anytime.
                  </p>
                  <div className="mt-4 inline-block bg-blue-50 rounded-full px-4 py-2">
                    <p className="text-sm text-blue-700">
                      Billed every 6 months to ensure uninterrupted service during check-in periods
                    </p>
                  </div>
                </div>

                <div className="grid md:grid-cols-2 gap-8 mb-8">
                  {plans.map((plan) => (
                    <div
                      key={plan.name}
                      className={`relative rounded-xl ${
                        plan.highlighted 
                          ? 'ring-2 ring-blue-600 shadow-xl' 
                          : 'border border-gray-200'
                      } hover:scale-105 transition-all duration-200 cursor-pointer`}
                      onClick={() => handlePlanSelect(plan)}
                    >
                      <div className="p-8">
                        {plan.highlighted && (
                          <div className="absolute top-0 right-0 -mt-4 -mr-4">
                            <span className="inline-flex items-center px-4 py-1 rounded-full text-sm font-semibold bg-gradient-to-r from-blue-600 to-blue-700 text-white shadow-lg">
                              <SparklesIcon className="h-4 w-4 mr-1" />
                              Most Popular
                            </span>
                          </div>
                        )}

                        <h3 className="text-2xl font-bold mb-2">{plan.name}</h3>
                        <div className="mb-4">
                          <span className="text-4xl font-bold">${plan.price}</span>
                          <span className="text-gray-600 ml-2">per {plan.interval}</span>
                        </div>

                        <div className="border-t border-gray-100 pt-4 mb-6">
                          <ul className="space-y-4">
                            {plan.features.map((feature) => (
                              <li key={feature} className="flex items-start">
                                <CheckIcon className="h-5 w-5 text-green-500 mr-3 flex-shrink-0" />
                                <span className="text-gray-600">{feature}</span>
                              </li>
                            ))}
                          </ul>
                        </div>

                        <button
                          onClick={() => handlePlanSelect(plan)}
                          className={`w-full py-3 px-6 rounded-lg font-semibold text-center ${
                            plan.highlighted
                              ? 'bg-gradient-to-r from-blue-600 to-blue-700 text-white hover:from-blue-700 hover:to-blue-800'
                              : 'bg-gray-100 text-gray-900 hover:bg-gray-200'
                          } transition-all duration-200`}
                        >
                          Start {plan.name} Trial
                        </button>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="text-center space-y-4">
                  <div className="flex items-center justify-center space-x-4">
                    <CheckIcon className="h-5 w-5 text-green-500" />
                    <span className="text-gray-600">14-day free trial</span>
                    <CheckIcon className="h-5 w-5 text-green-500" />
                    <span className="text-gray-600">Cancel anytime</span>
                    <CheckIcon className="h-5 w-5 text-green-500" />
                    <span className="text-gray-600">No setup fee</span>
                  </div>
                  <p className="text-sm text-gray-500">
                    By starting a trial, you agree to our Terms of Service and Privacy Policy
                  </p>
                </div>
              </>
            ) : (
              <Elements stripe={stripePromise}>
                <PaymentForm 
                  plan={selectedPlan}
                  onBack={() => setStep('select-plan')}
                  onComplete={() => window.location.reload()}
                />
              </Elements>
            )}
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
};

export default SubscriptionSetupModal;