import React from 'react';
import { useNavigate } from 'react-router-dom';

const SubscriptionBanner = ({ daysRemaining, type }) => {
  const navigate = useNavigate();

  const getBannerContent = () => {
    switch (type) {
      case 'trial':
        return {
          text: `Your free trial ends in ${daysRemaining} day${daysRemaining !== 1 ? 's' : ''} - Upgrade now to keep your access`,
          buttonText: 'Upgrade Now',
          bgColor: 'bg-gradient-to-r from-blue-600 to-blue-800'
        };
      case 'expiring':
        return {
          text: `Your subscription expires in ${daysRemaining} day${daysRemaining !== 1 ? 's' : ''}`,
          buttonText: 'Renew Now',
          bgColor: 'bg-gradient-to-r from-yellow-500 to-yellow-600'
        };
      default:
        return {
          text: 'Subscribe to access all features',
          buttonText: 'Subscribe Now',
          bgColor: 'bg-gradient-to-r from-blue-600 to-blue-800'
        };
    }
  };

  const content = getBannerContent();

  return (
    <div className={`${content.bgColor} text-white px-4 py-3`}>
      <div className="max-w-7xl mx-auto flex flex-col sm:flex-row justify-between items-center gap-2">
        <div className="flex items-center">
          <span className="text-sm font-medium">{content.text}</span>
        </div>
        <button
          onClick={() => navigate('/pricing')}
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-blue-600 bg-white hover:bg-blue-50 transition-all duration-200"
        >
          {content.buttonText}
        </button>
      </div>
    </div>
  );
};

export default SubscriptionBanner;