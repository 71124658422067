import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../services/api';
import EncryptionService from '../../services/EncryptionService';

export const fetchMessages = createAsyncThunk(
  'messages/fetchMessages',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.messages.getMessages();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || { message: error.message });
    }
  }
);

export const createMessage = createAsyncThunk(
  'messages/createMessage',
  async (messageData, { rejectWithValue }) => {
    try {
      const response = await api.messages.createMessage(messageData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || { message: error.message });
    }
  }
);

export const getMessage = createAsyncThunk(
  'messages/getMessage',
  async ({ id, encryptionKey }, { rejectWithValue }) => {
    try {
      const response = await api.messages.getMessage(id);
      const message = response.data;

      if (message.securityLevel === 'enhanced' && encryptionKey) {
        message.content = EncryptionService.decrypt(message.content, encryptionKey);
        message.attachments = await Promise.all(message.attachments.map(async (att) => ({
          ...att,
          pcloudId: EncryptionService.decrypt(att.encryptedKey, encryptionKey)
        })));
      }

      return message;
    } catch (error) {
      return rejectWithValue(error.response?.data || { message: error.message });
    }
  }
);

export const updateMessage = createAsyncThunk(
  'messages/updateMessage',
  async (messageData, { rejectWithValue }) => {
    try {
      const messageId = messageData.id || messageData._id;
      const response = await api.put(`/api/messages/${messageId}`, messageData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || { message: error.message });
    }
  }
);

export const updateMessageStatus = createAsyncThunk(
  'messages/updateStatus',
  async ({ id, status }, { rejectWithValue }) => {
    try {
      const response = await api.patch(`/api/messages/${id}/status`, { status });
      return { id, ...response.data };
    } catch (error) {
      return rejectWithValue(error.response?.data || { message: error.message });
    }
  }
);

export const deleteMessage = createAsyncThunk(
  'messages/deleteMessage',
  async (messageId, { rejectWithValue }) => {
    try {
      await api.delete(`/api/messages/${messageId}`);
      return messageId;
    } catch (error) {
      return rejectWithValue(error.response?.data || { message: error.message });
    }
  }
);

const messageSlice = createSlice({
  name: 'messages',
  initialState: {
    messages: [],
    status: 'idle',
    error: null,
    currentMessage: null,
  },
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
    setCurrentMessage: (state, action) => {
      state.currentMessage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch Messages
      .addCase(fetchMessages.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchMessages.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // Ensure we have unique messages by ID
        const messageMap = new Map();
        action.payload.forEach(message => {
          const id = message.id || message._id;
          messageMap.set(id, message);
        });
        state.messages = Array.from(messageMap.values()).sort(
          (a, b) => {
            const getTime = (timestamp) => {
              if (!timestamp) return 0;
              return timestamp._seconds ? 
                timestamp._seconds * 1000 : 
                new Date(timestamp).getTime();
            };
            return getTime(b.createdAt) - getTime(a.createdAt);
          }
        );
      })
      .addCase(fetchMessages.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload?.message || action.error.message;
      })

      // Create Message
      .addCase(createMessage.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createMessage.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const newMessage = action.payload;
        state.messages.unshift(newMessage);
      })
      .addCase(createMessage.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload?.message || action.error.message;
      })

      // Update Message
      .addCase(updateMessage.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateMessage.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const updatedMessage = action.payload;
        const messageIndex = state.messages.findIndex(
          msg => (msg.id || msg._id) === (updatedMessage.id || updatedMessage._id)
        );
        if (messageIndex !== -1) {
          state.messages[messageIndex] = updatedMessage;
        }
      })
      .addCase(updateMessage.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload?.message || action.error.message;
      })

      // Update Message Status
      .addCase(updateMessageStatus.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateMessageStatus.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const { id, ...updatedData } = action.payload;
        const messageIndex = state.messages.findIndex(
          msg => (msg.id || msg._id) === id
        );
        if (messageIndex !== -1) {
          state.messages[messageIndex] = {
            ...state.messages[messageIndex],
            ...updatedData
          };
        }
      })
      .addCase(updateMessageStatus.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload?.message || action.error.message;
      })

      // Delete Message
      .addCase(deleteMessage.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteMessage.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.messages = state.messages.filter(
          message => (message.id || message._id) !== action.payload
        );
      })
      .addCase(deleteMessage.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload?.message || action.error.message;
      })

      // Get Single Message
      .addCase(getMessage.fulfilled, (state, action) => {
        const message = action.payload;
        const messageIndex = state.messages.findIndex(
          msg => (msg.id || msg._id) === (message.id || message._id)
        );
        if (messageIndex !== -1) {
          state.messages[messageIndex] = message;
        } else {
          state.messages.push(message);
        }
        state.currentMessage = message;
      });
  },
});

export const { clearError, setCurrentMessage } = messageSlice.actions;
export default messageSlice.reducer;