import React from 'react';
import { ShieldCheckIcon, LockClosedIcon, KeyIcon, DocumentTextIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline';

const SecurityFeature = ({ icon: Icon, title, description }) => (
  <div className="flex items-start space-x-4 mb-8">
    <div className="flex-shrink-0">
      <Icon className="h-8 w-8 text-blue-600" />
    </div>
    <div>
      <h3 className="text-xl font-semibold text-gray-900 mb-2">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </div>
  </div>
);

const Security = () => {

  const handleContactSupport = () => {
    window.location.href = "mailto:cryonotemail@gmail.com?subject=Security%20Request&body=Please%20describe%20the%20issue%20here.";
  };

  return (
    <div className="max-w-4xl mx-auto">
      <h1 className="text-3xl font-bold text-gray-900 mb-8">Security Center</h1>
      
      <div className="bg-white shadow-lg rounded-lg overflow-hidden mb-8">
        <div className="p-6">
          <h2 className="text-2xl font-semibold text-gray-900 mb-6">Our Security Measures</h2>
          
          <SecurityFeature
            icon={ShieldCheckIcon}
            title="End-to-End Encryption"
            description="All data transmitted through our platform is encrypted using industry-standard protocols to ensure the highest level of security."
          />
          
          <SecurityFeature
            icon={LockClosedIcon}
            title="Secure Data Storage"
            description="Your data is stored in secure, encrypted databases with regular backups to prevent data loss."
          />
          
          <SecurityFeature
            icon={KeyIcon}
            title="Two-Factor Authentication"
            description="Enable two-factor authentication for an extra layer of security on your account."
          />
        </div>
      </div>

      <div className="bg-white shadow-lg rounded-lg overflow-hidden mb-8">
        <div className="p-6">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">Security Levels: Standard vs. Enhanced</h2>
          <p className="text-gray-600 mb-4">
            Cryonote offers two levels of security for your notes and files:
          </p>
          <div className="grid md:grid-cols-2 gap-6">
            <div>
              <h3 className="text-xl font-semibold text-gray-900 mb-2">Standard Security</h3>
              <ul className="list-disc list-inside text-gray-600 space-y-2">
                <li>Default encryption for all notes and files</li>
                <li>Suitable for most day-to-day use cases</li>
                <li>Recommended for storing passwords and sensitive information</li>
                <li>Option to add individual passwords to files for extra protection</li>
              </ul>
            </div>
            <div>
              <h3 className="text-xl font-semibold text-gray-900 mb-2">Enhanced Security</h3>
              <ul className="list-disc list-inside text-gray-600 space-y-2">
                <li>Advanced encryption algorithms for maximum protection</li>
                <li>Ideal for highly sensitive or confidential information</li>
                <li>Additional authentication steps required for access</li>
                <li>Increased computational overhead, may affect performance</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white shadow-lg rounded-lg overflow-hidden mb-8">
        <div className="p-6">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">Storing Sensitive Information</h2>
          <div className="flex items-start space-x-4 mb-4">
            <ExclamationTriangleIcon className="h-6 w-6 text-yellow-500 flex-shrink-0" />
            <p className="text-gray-600">
              While both security methods provide strong protection, we recommend storing passwords and sensitive information using the standard security method, preferably in separate files with their own passwords. This approach balances security with ease of access for frequently used information.
            </p>
          </div>
          <div className="flex items-start space-x-4">
            <DocumentTextIcon className="h-6 w-6 text-blue-600 flex-shrink-0" />
            <p className="text-gray-600">
              For highly confidential data that requires maximum protection and is accessed less frequently, consider using the enhanced security method.
            </p>
          </div>
        </div>
      </div>

      <div className="bg-white shadow-lg rounded-lg overflow-hidden mb-8">
        <div className="p-6">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">Security Best Practices</h2>
          <ul className="list-disc list-inside text-gray-600 space-y-2">
            <li>Use a strong, unique password for your Cryonote account</li>
            <li>Enable two-factor authentication for an additional layer of security</li>
            <li>Regularly review your account activity and security settings</li>
            <li>Keep your email address up to date for account recovery purposes</li>
            <li>Be cautious of phishing attempts and only log in through official channels</li>
            <li>Use enhanced security for your most sensitive information</li>
            <li>Regularly update and rotate your passwords</li>
            <li>Avoid using public or unsecured Wi-Fi networks when accessing sensitive information</li>
          </ul>
        </div>
      </div>

      <div className="bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="p-6">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">Report a Security Concern</h2>
          <p className="text-gray-600 mb-4">
            If you believe you've found a security vulnerability or have any security concerns, please let us know immediately. Our security team will investigate and address the issue promptly.
          </p>
          <button onClick={handleContactSupport} className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-150 ease-in-out">
            Report Security Issue
          </button>
        </div>
      </div>
    </div>
  );
};

export default Security;
