// src/App.js
import React from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './store';
import { ToastContainer } from 'react-toastify'; // Import ToastContainer
import 'react-toastify/dist/ReactToastify.css'; // Import Toast styles
import { AuthProvider } from './contexts/AuthContext';
import LandingPage from './pages/LandingPage';
import LoginPage from './pages/LoginPage';
import SignupPage from './pages/SignupPage';
import DashboardLayout from './layouts/DashboardLayout';
import Home from './pages/dashboard/Home';
import Messages from './pages/dashboard/Messages';
import Triggers from './pages/dashboard/Triggers';
import Settings from './pages/dashboard/Settings';
import Subscription from './pages/dashboard/Subscription';
import Connections from './pages/dashboard/Connections';
import FAQ from './pages/dashboard/FAQ';
import SecureMessagePage from './pages/SecureMessagePage';
import VerifyTrustedContact from './pages/VerifyTrustedContact';
import SecurityPage from './pages/SecurityPage';
import HelpCenter from './pages/dashboard/HelpCenter';
import Security from './pages/dashboard/Security';
import TwoFactorVerification from './pages/auth/TwoFactorVerification';
import AuthCallback from './pages/auth/AuthCallback';
import LongevityAndTrust from './pages/LongevityAndTrust';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';
import PrivacyPage from './pages/PrivacyPage';
import TosPage from './pages/TosPage';
import FAQPage from './pages/FAQPage';

function App() {
  // Define a wrapper component to access location and provide currentRoute
  const AppWrapper = () => {
    const location = useLocation(); // Get current path

    return (
      <AuthProvider currentRoute={location.pathname}> {/* Pass current path as currentRoute */}
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/privacy" element={<PrivacyPage />} />
          <Route path="/tos" element={<TosPage />} />
          <Route path="/faq" element={<FAQPage />} />
          <Route path="/longevity-and-trust" element={<LongevityAndTrust />} />
          <Route path="/auth/2fa" element={<TwoFactorVerification />} />
          <Route path="/auth/callback" element={<AuthCallback />} />
          <Route path="/secure-messages/:token" element={<SecureMessagePage />} />
          <Route path="/verify-trusted-contact/:triggerId/:token" element={<VerifyTrustedContact />} />
          <Route path="/security" element={<SecurityPage />} />
          <Route path="/dashboard" element={<DashboardLayout />}>
            <Route index element={<Home />} />
            <Route path="messages" element={<Messages />} />
            <Route path="triggers" element={<Triggers />} />
            <Route path="settings" element={<Settings />} />
            <Route path="subscription" element={<Subscription />} />
            <Route path="connections" element={<Connections />} />
            <Route path="faq" element={<FAQ />} />
            <Route path="help" element={<HelpCenter />} />
            <Route path="security" element={<Security />} />
          </Route>
        </Routes>
        <ToastContainer /> {/* Add ToastContainer here */}
      </AuthProvider>
    );
  };

  return (
    <Provider store={store}>
      <BrowserRouter>
        <AppWrapper />
      </BrowserRouter>
    </Provider>
  );
}

export default App;
