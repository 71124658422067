import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

const TwoFactorVerification = () => {
  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [authData, setAuthData] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { verify2FA } = useAuth();

  useEffect(() => {
    console.log('[2FA Page] Location state:', location.state);
    console.log('[2FA Page] URL search params:', location.search);

    const params = new URLSearchParams(location.search);
    const urlToken = params.get('token');
    const urlProvider = params.get('provider');
    
    const stateData = location.state || {};
    
    const token = urlToken || stateData.tempToken;
    const provider = urlProvider || stateData.provider;

    console.log('[2FA Page] Parsed data:', { token, provider });

    if (!token) {
      console.error('[2FA Page] No token found');
      // navigate('/login');
    } else {
      setAuthData({ token, provider });
    }
  }, [navigate, location]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    if (!authData) {
      setError('Authentication data is missing');
      setIsLoading(false);
      return;
    }

    try {
      console.log('[2FA Page] Submitting verification:', {
        token: authData.token,
        provider: authData.provider,
        codeLength: code.length
      });

      const result = await verify2FA(authData.token, code, authData.provider);
      
      console.log('[2FA Page] Verification result:', result);

      if (result.success) {
        navigate('/dashboard');
      } else {
        setError(result.error || 'Verification failed');
      }
    } catch (error) {
      console.error('[2FA Page] Verification error:', error);
      setError(error.response?.data?.message || 'Verification failed');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Two-Factor Authentication
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            Enter the verification code sent to your email
          </p>
        </div>

        {error && (
          <div className="bg-red-50 border border-red-400 text-red-700 px-4 py-3 rounded relative">
            {error}
          </div>
        )}

        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
          <div>
            <input
              type="text"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              placeholder="Enter verification code"
              className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              required
            />
          </div>

          <div>
            <button
              type="submit"
              disabled={isLoading}
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              {isLoading ? 'Verifying...' : 'Verify'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default TwoFactorVerification;
