// src/services/stripeService.js
import { loadStripe } from '@stripe/stripe-js';
import api from './api';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const stripeService = {
  // Initialize Stripe payment session
  async createSubscription(priceId) {
    try {
      const response = await api.post('/api/stripe/create-subscription', {
        priceId
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  startTrial: async ({ planName, paymentMethodId }) => {
    try {
      const response = await api.post('/api/stripe/start-trial', {
        planName,
        paymentMethodId
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // Create a checkout session for initial subscription
  async createCheckoutSession(priceId) {
    try {
      const response = await api.post('/api/stripe/create-checkout-session', {
        priceId
      });
      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({
        sessionId: response.data.sessionId
      });
      if (error) {
        throw error;
      }
    } catch (error) {
      throw error;
    }
  },

  // Handle subscription updates
  async updateSubscription(subscriptionId, newPriceId) {
    try {
      const response = await api.post('/api/stripe/update-subscription', {
        subscriptionId,
        newPriceId
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // Cancel subscription
  async cancelSubscription(subscriptionId) {
    try {
      const response = await api.post('/api/stripe/cancel-subscription', {
        subscriptionId
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // Get subscription status
  async getSubscriptionStatus() {
    try {
      const response = await api.get('/api/stripe/subscription-status');
      console.log('Subscription Data: ', response.data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
};

export default stripeService;
