import React, { useState, useEffect } from 'react';
import { XMarkIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import CollapsibleExplanation from '../../components/common/CollapsibleExplanation';
import api from '../../services/api';

const ConnectionsPage = () => {
  const [connectedAccounts, setConnectedAccounts] = useState([]);

  useEffect(() => {
    fetchConnectedAccounts();
  }, []);

  const fetchConnectedAccounts = async () => {
    try {
      const response = await api.get('/api/connections/connected-accounts');
      setConnectedAccounts(response.data);
    } catch (error) {
      console.error('Error fetching connected accounts:', error);
    }
  };

  const handleConnect = async (provider) => {
    window.location.href = `${process.env.REACT_APP_API_URL}/api/connections/auth/${provider.toLowerCase()}`;
  };

  const handleDisconnect = async (provider) => {
    try {
      await api.delete(`/api/connections/disconnect/${provider.toLowerCase()}`);
      fetchConnectedAccounts();
    } catch (error) {
      console.error(`Error disconnecting ${provider} account:`, error);
    }
  };

  const providers = [
    { name: 'Google', icon: 'https://img.icons8.com/color/48/gmail-new.png' },
    { name: 'Outlook', icon: 'https://img.icons8.com/color/48/ms-outlook.png' },
  ];

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <CollapsibleExplanation title="Email Connections">
        <p>
          Connect your email accounts to allow MyEcho to send messages on your behalf. 
          This ensures that your final messages are sent from your personal email address, 
          maintaining a personal touch. We use industry-standard OAuth for secure authorization 
          and never store your email passwords.
        </p>
      </CollapsibleExplanation>

      <h1 className="text-3xl font-bold text-gray-900 mt-8 mb-6">Connected Accounts</h1>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {providers.map((provider) => (
          <div key={provider.name} className="bg-white shadow-md rounded-lg p-6 relative">
            <div className="flex items-center mb-4">
              <img src={provider.icon} alt={provider.name} className="h-8 w-8 mr-3" />
              <h2 className="text-lg font-semibold text-gray-900">{provider.name}</h2>
            </div>

            {connectedAccounts.find(account => account.provider.toLowerCase() === provider.name.toLowerCase()) ? (
              <button
                onClick={() => handleDisconnect(provider.name)}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 mb-4"
              >
                Disconnect {provider.name} Account
              </button>
            ) : (
              <button
                onClick={() => handleConnect(provider.name)}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mb-4"
              >
                Connect {provider.name} Account
              </button>
            )}

            <span className={`absolute top-6 right-6 px-2 py-1 text-xs font-semibold rounded-full 
              ${connectedAccounts.some(account => account.provider.toLowerCase() === provider.name.toLowerCase()) ? 'bg-green-100 text-green-800' : 'bg-gray-100 text-gray-800'}`}>
              {connectedAccounts.some(account => account.provider.toLowerCase() === provider.name.toLowerCase()) ? 'Connected' : 'Not Connected'}
            </span>

            <ul className="space-y-2">
              {connectedAccounts
                .filter(account => account.provider.toLowerCase() === provider.name.toLowerCase())
                .map((account) => (
                  <li key={account.email} className="flex items-center justify-between bg-gray-50 p-2 rounded">
                    <span>{account.email}</span>
                    <button onClick={() => handleDisconnect(provider.name)} className="text-red-500 hover:text-red-700">
                      <XMarkIcon className="h-5 w-5" />
                    </button>
                  </li>
                ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ConnectionsPage;