import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { StarIcon, ChatBubbleLeftIcon } from '@heroicons/react/24/solid';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';

const Testimonials = () => {
  const [activeCategory, setActiveCategory] = useState('personal');

  const categories = {
    personal: "Personal Users",
    business: "Business Users",
    legal: "Legal Professionals"
  };

  const testimonials = {
    personal: [
      {
        quote: "I feel at peace knowing my children will receive my messages and life lessons at the right moments in their lives. The setup was surprisingly easy.",
        author: "Sarah Mitchell",
        role: "Parent & Digital Legacy Planner",
        image: "https://img.icons8.com/officel/80/circled-user-female-skin-type-4.png",
        rating: 5,
        tags: ["Family Messages", "Life Lessons"],
        highlight: "Messages scheduled for key life moments"
      },
      {
        quote: "After losing my father and struggling to access his digital accounts, I knew I needed to plan better. Cryonote solved this problem perfectly for my own legacy planning.",
        author: "Michael Chen",
        role: "Tech Professional",
        image: "https://img.icons8.com/officel/80/circled-user-male-skin-type-4.png",
        rating: 5,
        tags: ["Digital Assets", "Peace of Mind"],
        highlight: "Secure digital asset transfer"
      }
    ],
    business: [
      {
        quote: "As a business owner, knowing my succession plan and critical information will be securely delivered brings incredible peace of mind. The consensus trigger feature is brilliant.",
        author: "David Rodriguez",
        role: "CEO, TechStart Inc",
        image: "https://img.icons8.com/officel/80/circled-user-male-skin-type-4.png",
        rating: 5,
        tags: ["Business Continuity", "Succession Planning"],
        highlight: "Complete business transition plan"
      },
      {
        quote: "The enhanced security features and business-specific templates made setting up our company's contingency plan straightforward and comprehensive.",
        author: "Emma Thompson",
        role: "Operations Director",
        image: "https://img.icons8.com/officel/80/circled-user-female-skin-type-4.png",
        rating: 5,
        tags: ["Enterprise Security", "Templates"],
        highlight: "Enterprise-grade security"
      }
    ],
    legal: [
      {
        quote: "I recommend Cryonote to my estate planning clients. It perfectly complements traditional legal documents with secure digital legacy planning.",
        author: "Robert Maxwell",
        role: "Estate Planning Attorney",
        image: "https://img.icons8.com/officel/80/circled-user-male-skin-type-4.png",
        rating: 5,
        tags: ["Estate Planning", "Legal Compliance"],
        highlight: "Recommended by legal professionals"
      },
      {
        quote: "The platform's security and legal considerations are impressive. It's become an essential tool in our estate planning practice.",
        author: "Patricia Wong",
        role: "Legal Consultant",
        image: "https://img.icons8.com/officel/80/circled-user-female-skin-type-4.png",
        rating: 5,
        tags: ["Professional Usage", "Client Solutions"],
        highlight: "Used by law firms nationwide"
      }
    ]
  };

  return (
    <section className="relative py-24 bg-white overflow-hidden">
      {/* Decorative background elements */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_top,theme(colors.blue.50),white)] opacity-40" />
      </div>

      <div className="relative max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <motion.span
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="inline-block px-4 py-2 rounded-full bg-blue-100 text-blue-700 text-sm font-medium mb-4"
          >
            Success Stories
          </motion.span>

          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-4xl font-bold text-gray-900 mb-4"
          >
            Trusted by People Like You
          </motion.h2>

          <motion.p
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-xl text-gray-600 max-w-2xl mx-auto"
          >
            Hear from our users about how Cryonote helps them secure their digital legacy
          </motion.p>
        </div>

        {/* Category Selection */}
        <div className="flex justify-center mb-12">
          <div className="inline-flex p-1 bg-gray-100 rounded-xl">
            {Object.entries(categories).map(([key, value]) => (
              <button
                key={key}
                onClick={() => setActiveCategory(key)}
                className={`px-6 py-2 rounded-lg text-sm font-medium transition-all duration-200 ${
                  activeCategory === key
                    ? 'bg-white text-blue-600 shadow-sm'
                    : 'text-gray-600 hover:text-gray-900'
                }`}
              >
                {value}
              </button>
            ))}
          </div>
        </div>

        {/* Testimonial Cards */}
        <div className="grid md:grid-cols-2 gap-8">
          {testimonials[activeCategory].map((testimonial, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.2 }}
              className="relative group"
            >
              <div className="absolute inset-0 bg-gradient-to-r from-blue-50 to-white rounded-2xl transform transition-transform group-hover:scale-[1.02] duration-300" />
              
              <div className="relative bg-white/80 backdrop-blur-sm rounded-2xl p-8 shadow-lg">
                {/* Quote icon */}
                <div className="absolute top-4 right-4 text-blue-100">
                  <ChatBubbleLeftIcon className="h-8 w-8" />
                </div>

                {/* Rating */}
                <div className="flex mb-4">
                  {[...Array(testimonial.rating)].map((_, i) => (
                    <StarIcon key={i} className="h-5 w-5 text-yellow-400" />
                  ))}
                </div>

                {/* Quote */}
                <blockquote className="text-gray-700 mb-6">
                  "{testimonial.quote}"
                </blockquote>

                {/* Tags */}
                <div className="flex flex-wrap gap-2 mb-6">
                  {testimonial.tags.map((tag, idx) => (
                    <span
                      key={idx}
                      className="inline-flex items-center px-3 py-1 rounded-full text-xs font-medium bg-blue-50 text-blue-700"
                    >
                      {tag}
                    </span>
                  ))}
                </div>

                {/* Author */}
                <div className="flex items-center">
                  <img
                    src={testimonial.image}
                    alt={testimonial.author}
                    className="h-12 w-12 rounded-full mr-4"
                  />
                  <div>
                    <div className="font-medium text-gray-900">
                      {testimonial.author}
                    </div>
                    <div className="text-sm text-gray-500">
                      {testimonial.role}
                    </div>
                  </div>
                </div>

                {/* Highlight */}
                <div className="mt-4 pt-4 border-t border-gray-100">
                  <div className="text-sm text-blue-600 font-medium">
                    {testimonial.highlight}
                  </div>
                </div>
              </div>
            </motion.div>
          ))}
        </div>

        {/* Trust Indicators */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="mt-16 text-center"
        >
          <div className="bg-blue-50 rounded-2xl p-6 inline-block">
            <div className="flex items-center justify-center space-x-8">
              <div>
                <div className="text-3xl font-bold text-blue-600">4.9/5</div>
                <div className="text-sm text-gray-600">Average Rating</div>
              </div>
              <div className="h-12 w-px bg-blue-200" />
              <div>
                <div className="text-3xl font-bold text-blue-600">AES256</div>
                <div className="text-sm text-gray-600">Encryption Standard</div>
              </div>
              <div className="h-12 w-px bg-blue-200" />
              <div>
                <div className="text-3xl font-bold text-blue-600">99.9%</div>
                <div className="text-sm text-gray-600">Satisfaction Rate</div>
              </div>
            </div>
          </div>
        </motion.div>

        {/* Call to Action */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="mt-12 text-center"
        >
          <a
            href="/signup"
            className="inline-flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 transform hover:scale-105 transition-all duration-200 shadow-lg hover:shadow-xl"
          >
            Join Our Success Stories
            <span className="ml-2">→</span>
          </a>
        </motion.div>
      </div>
    </section>
  );
};

export default Testimonials;
