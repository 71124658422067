import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import api from '../services/api';
import { useDispatch } from 'react-redux';


const VerifyTrustedContact = () => {
  const { triggerId, token } = useParams();
  const [status, setStatus] = useState('verifying');
  const [error, setError] = useState(null);
  const dispatch = useDispatch();


  const verifyContact = useCallback(async () => {
    try {
      const response = await api.triggers.verifyTrustedContact(triggerId, token);
      console.log('Verification response:', response.data);
      setStatus('success');
    } catch (error) {
      console.error('Error verifying trusted contact:', error.response?.data || error.message);
      setStatus('error');
      setError(error.response?.data?.message || 'An unexpected error occurred');
    }
  }, [triggerId, token]);

  useEffect(() => {
    verifyContact();
  }, [verifyContact]);

  return (
    <div className="max-w-md mx-auto mt-10 p-6 bg-white rounded-lg shadow-xl">
      {status === 'verifying' && (
        <p className="text-blue-600">Verifying your status as a trusted contact...</p>
      )}
      {status === 'success' && (
        <p className="text-green-600">You have been successfully verified as a trusted contact.</p>
      )}
      {status === 'error' && (
        <div>
          <p className="text-red-600">There was an error verifying your status.</p>
          <p className="text-gray-600 mt-2">{error}</p>
        </div>
      )}
    </div>
  );
};

export default VerifyTrustedContact;