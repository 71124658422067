// TwoFactorAuth.jsx
import React, { useState } from 'react';

const TwoFactorAuth = ({ onSuccess, error, onErrorClear }) => {
  const [code, setCode] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onErrorClear();
    onSuccess(code);
  };

  return (
    <div className="max-w-md mx-auto">
      <h2 className="text-2xl font-semibold mb-4">Two-Factor Authentication</h2>
      <p className="mb-4 text-gray-600">
        Enter the 6-digit code sent to your email to verify your identity.
      </p>
      <form onSubmit={handleSubmit} className="space-y-4">
        <input
          type="text"
          value={code}
          onChange={(e) => setCode(e.target.value)}
          placeholder="Enter 6-digit code"
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          maxLength="6"
          pattern="[0-9]{6}"
          required
        />
        <button
          type="submit"
          className="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition duration-300"
        >
          Verify
        </button>
      </form>
    </div>
  );
};

export default TwoFactorAuth;