import React from 'react';
import { motion } from 'framer-motion';
import { 
  DocumentArrowDownIcon, 
  ShieldCheckIcon, 
  ScaleIcon,
  CheckCircleIcon,
  KeyIcon,
  CogIcon,
ArrowDownTrayIcon,
  ExclamationCircleIcon,
  EyeIcon,
  DocumentIcon,
  AcademicIcon,
  ArchiveBoxIcon,
  ClipboardDocumentCheckIcon,
  PhoneIcon,
  EnvelopeIcon,
  MapPinIcon,
  AcademicCapIcon,
  BellIcon,
  ExclamationIcon,
  TrashIcon,
  ClockIcon,
  DocumentCheckIcon,
  PrinterIcon,
  ArrowTopRightOnSquareIcon,
  ChevronRightIcon,
  LockClosedIcon,
  InformationCircleIcon,
  UserIcon,
  GlobeAltIcon
} from '@heroicons/react/24/outline';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const PrivacyPolicyPage = () => {
  const lastUpdated = "November 16, 2024";
  const effectiveDate = "December 1, 2024";
  const version = "2.1.0";

  // Table of contents sections for quick navigation
  const sections = [
    { id: 'introduction', title: '1. Introduction' },
    { id: 'information-collected', title: '2. Information We Collect' },
    { id: 'information-use', title: '3. How We Use Your Information' },
    { id: 'data-protection', title: '4. Data Protection' },
    { id: 'data-sharing', title: '5. Data Sharing' },
    { id: 'user-controls', title: '6. User Controls' },
    { id: 'data-retention', title: '7. Data Retention' },
    { id: 'childrens-privacy', title: '8. Children\'s Privacy' },
    { id: 'international-transfers', title: '9. International Data Transfers' },
    { id: 'cookie-policy', title: '10. Cookie Policy' },
    { id: 'your-rights', title: '11. Your Rights' },
    { id: 'security-measures', title: '12. Security Measures' },
    { id: 'policy-updates', title: '13. Privacy Policy Updates' },
    { id: 'contact-info', title: '14. Contact Information' },
    { id: 'regulatory', title: '15. Regulatory Compliance' }
  ];

  const downloadPrivacyPolicy = () => {
    const element = document.createElement('a');
    const file = new Blob(
      [document.getElementById('privacy-content').innerText],
      {type: 'text/plain'}
    );
    element.href = URL.createObjectURL(file);
    element.download = `Cryonote-Privacy-Policy-v${version}.txt`;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const printPrivacyPolicy = () => {
    window.print();
  };

  const scrollToSection = (sectionId) => {
    document.getElementById(sectionId).scrollIntoView({ 
      behavior: 'smooth',
      block: 'start'
    });
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <Navbar />
      
      <main className="pt-16 pb-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="lg:grid lg:grid-cols-12 lg:gap-8">
            {/* Sidebar Navigation */}
            <div className="hidden lg:block lg:col-span-3">
              <nav className="sticky top-20 divide-y divide-gray-200">
                <div className="pb-8">
                  <h2 className="text-sm font-semibold text-gray-900">On this page</h2>
                  <ul className="mt-4 space-y-2">
                    {sections.map((section) => (
                      <li key={section.id}>
                        <button
                          onClick={() => scrollToSection(section.id)}
                          className="group flex items-center text-sm text-gray-600 hover:text-gray-900"
                        >
                          <ChevronRightIcon className="h-4 w-4 mr-2 text-gray-400 group-hover:text-gray-900" />
                          {section.title}
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>

                {/* Quick Links */}
                <div className="pt-6">
                  <h2 className="text-sm font-semibold text-gray-900">Quick Links</h2>
                  <ul className="mt-4 space-y-2">
                    <li>
                      <a href="#data-request" className="group flex items-center text-sm text-blue-600 hover:text-blue-800">
                        <UserIcon className="h-4 w-4 mr-2" />
                        Request Your Data
                      </a>
                    </li>
                    <li>
                      <a href="#cookie-preferences" className="group flex items-center text-sm text-blue-600 hover:text-blue-800">
                        <GlobeAltIcon className="h-4 w-4 mr-2" />
                        Cookie Preferences
                      </a>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>

            {/* Main Content */}
            <div className="lg:col-span-9">
              {/* Header Section */}
              <div className="bg-white rounded-xl shadow-sm p-8 mb-8">
                <div className="flex justify-between items-start">
                  <div>
                    <h1 className="text-3xl font-bold text-gray-900">Privacy Policy</h1>
                    <div className="mt-2 space-y-1">
                      <p className="text-sm text-gray-500">Version {version}</p>
                      <p className="text-sm text-gray-500">Last Updated: {lastUpdated}</p>
                      <p className="text-sm text-gray-500">Effective Date: {effectiveDate}</p>
                    </div>
                  </div>
                  <div className="flex space-x-4">
                    <button
                      onClick={downloadPrivacyPolicy}
                      className="flex items-center px-4 py-2 border border-gray-300 rounded-lg text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      <DocumentArrowDownIcon className="h-5 w-5 mr-2 text-gray-500" />
                      Download
                    </button>
                    <button
                      onClick={printPrivacyPolicy}
                      className="flex items-center px-4 py-2 border border-gray-300 rounded-lg text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      <PrinterIcon className="h-5 w-5 mr-2 text-gray-500" />
                      Print
                    </button>
                  </div>
                </div>
                <div className="mt-6 flex flex-wrap gap-4">
                  <div className="flex items-center px-3 py-1 bg-blue-50 rounded-full">
                    <ShieldCheckIcon className="h-4 w-4 text-blue-600 mr-2" />
                    <span className="text-xs font-medium text-blue-600">ISO 27701 Guidelines Followed</span>
                  </div>
                  <div className="flex items-center px-3 py-1 bg-green-50 rounded-full">
                    <ScaleIcon className="h-4 w-4 text-green-600 mr-2" />
                    <span className="text-xs font-medium text-green-600">GDPR Compliant</span>
                  </div>
                  <div className="flex items-center px-3 py-1 bg-purple-50 rounded-full">
                    <DocumentCheckIcon className="h-4 w-4 text-purple-600 mr-2" />
                    <span className="text-xs font-medium text-purple-600">CCPA Compliant</span>
                  </div>
                  <div className="flex items-center px-3 py-1 bg-orange-50 rounded-full">
                    <LockClosedIcon className="h-4 w-4 text-orange-600 mr-2" />
                    <span className="text-xs font-medium text-orange-600">SOC 2 Type II Guidelines Followed</span>
                  </div>
                </div>

                {/* Overview Banner */}
                <div className="mt-6 bg-blue-50 border-l-4 border-blue-400 p-4 rounded-r-lg">
                  <div className="flex">
                    <InformationCircleIcon className="h-6 w-6 text-blue-400 mr-3" />
                    <div>
                      <h3 className="text-sm font-medium text-blue-800">Your Privacy Matters</h3>
                      <p className="mt-1 text-sm text-blue-700">
                        This Privacy Policy explains how we collect, use, and protect your personal information. 
                        We are committed to maintaining the trust you place in us by being transparent about our data practices.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Privacy Policy Content */}
              <div id="privacy-content" className="bg-white rounded-xl shadow-sm p-8 prose prose-blue max-w-none">
                <section id="introduction">
                  <h2>1. Introduction</h2>
                  <p>
                    Cryonote ("we", "us", "our") is committed to protecting your privacy. This Privacy Policy explains how we collect, 
                    use, disclose, and safeguard your information when you use our digital legacy planning service.
                  </p>
                  <p>
                    By using Cryonote, you consent to the data practices described in this Privacy Policy. We will only collect 
                    and use your information in accordance with this Policy.
                  </p>
                </section>

                <section id="information-collected">
                  <h2>2. Information We Collect</h2>
                  
                  <h3>2.1 Personal Information</h3>
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Data Type
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Purpose
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Legal Basis
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      <tr>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                          Contact Information
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          Account creation and communication
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          Contract performance
                        </td>
                      </tr>
                      <tr>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                          Authentication Data
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          Account security
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          Legal obligation
                        </td>
                      </tr>
                      <tr>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                          Payment Information
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          Subscription processing
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          Contract performance
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <h3 className="mt-8">2.2 Service Data</h3>
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Data Type
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Description
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Storage Method
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      <tr>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                          Message Content
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          Your created messages and attachments
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          Encrypted storage
                        </td>
                      </tr>
                      <tr>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                          Usage Data
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          Service interaction patterns
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          Aggregated analytics
                        </td>
                      </tr>
                      <tr>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                          Technical Data
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          Device and connection information
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          Secure logs
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </section>
                <section id="information-use" className="mt-12">
                  <h2>3. How We Use Your Information</h2>
                  
                  <div className="grid md:grid-cols-2 gap-6 mt-6">
                    <div className="bg-gray-50 rounded-lg p-6">
                      <h3 className="text-lg font-semibold text-gray-900 mb-4">3.1 Service Provision</h3>
                      <ul className="space-y-2 text-gray-600">
                        <li className="flex items-start">
                          <CheckCircleIcon className="h-5 w-5 text-blue-500 mt-0.5 mr-2" />
                          <span>Account creation and maintenance</span>
                        </li>
                        <li className="flex items-start">
                          <CheckCircleIcon className="h-5 w-5 text-blue-500 mt-0.5 mr-2" />
                          <span>Message storage and delivery</span>
                        </li>
                        <li className="flex items-start">
                          <CheckCircleIcon className="h-5 w-5 text-blue-500 mt-0.5 mr-2" />
                          <span>Trigger system operation</span>
                        </li>
                        <li className="flex items-start">
                          <CheckCircleIcon className="h-5 w-5 text-blue-500 mt-0.5 mr-2" />
                          <span>Customer support</span>
                        </li>
                      </ul>
                    </div>

                    <div className="bg-gray-50 rounded-lg p-6">
                      <h3 className="text-lg font-semibold text-gray-900 mb-4">3.2 Security</h3>
                      <ul className="space-y-2 text-gray-600">
                        <li className="flex items-start">
                          <ShieldCheckIcon className="h-5 w-5 text-blue-500 mt-0.5 mr-2" />
                          <span>Fraud prevention and detection</span>
                        </li>
                        <li className="flex items-start">
                          <ShieldCheckIcon className="h-5 w-5 text-blue-500 mt-0.5 mr-2" />
                          <span>Account protection</span>
                        </li>
                        <li className="flex items-start">
                          <ShieldCheckIcon className="h-5 w-5 text-blue-500 mt-0.5 mr-2" />
                          <span>System integrity verification</span>
                        </li>
                        <li className="flex items-start">
                          <ShieldCheckIcon className="h-5 w-5 text-blue-500 mt-0.5 mr-2" />
                          <span>Security audit logging</span>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="mt-6 bg-blue-50 rounded-lg p-6">
                    <h3 className="text-lg font-semibold text-blue-900 mb-4">3.3 Legal Basis for Processing</h3>
                    <div className="space-y-4">
                      <div className="flex items-start">
                        <DocumentCheckIcon className="h-5 w-5 text-blue-500 mt-0.5 mr-2" />
                        <div>
                          <h4 className="font-medium text-blue-900">Contract Performance</h4>
                          <p className="text-blue-700 text-sm">Processing necessary to provide our service to you</p>
                        </div>
                      </div>
                      <div className="flex items-start">
                        <DocumentCheckIcon className="h-5 w-5 text-blue-500 mt-0.5 mr-2" />
                        <div>
                          <h4 className="font-medium text-blue-900">Legal Obligations</h4>
                          <p className="text-blue-700 text-sm">Processing required to comply with legal requirements</p>
                        </div>
                      </div>
                      <div className="flex items-start">
                        <DocumentCheckIcon className="h-5 w-5 text-blue-500 mt-0.5 mr-2" />
                        <div>
                          <h4 className="font-medium text-blue-900">Legitimate Interests</h4>
                          <p className="text-blue-700 text-sm">Processing based on our legitimate business interests</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section id="data-protection" className="mt-12">
                  <h2>4. Data Protection</h2>

                  <div className="mt-6 space-y-8">
                    <div className="bg-gray-50 rounded-lg p-6">
                      <h3 className="text-lg font-semibold text-gray-900 mb-4">4.1 Encryption Standards</h3>
                      <div className="grid md:grid-cols-2 gap-6">
                        <div>
                          <h4 className="font-medium text-gray-900 mb-2">Data in Transit</h4>
                          <ul className="space-y-2 text-gray-600">
                            <li className="flex items-start">
                              <LockClosedIcon className="h-5 w-5 text-blue-500 mt-0.5 mr-2" />
                              <span>TLS 1.3 encryption</span>
                            </li>
                            <li className="flex items-start">
                              <LockClosedIcon className="h-5 w-5 text-blue-500 mt-0.5 mr-2" />
                              <span>Perfect Forward Secrecy</span>
                            </li>
                          </ul>
                        </div>
                        <div>
                          <h4 className="font-medium text-gray-900 mb-2">Data at Rest</h4>
                          <ul className="space-y-2 text-gray-600">
                            <li className="flex items-start">
                              <LockClosedIcon className="h-5 w-5 text-blue-500 mt-0.5 mr-2" />
                              <span>AES-256 encryption</span>
                            </li>
                            <li className="flex items-start">
                              <LockClosedIcon className="h-5 w-5 text-blue-500 mt-0.5 mr-2" />
                              <span>Hardware Security Modules</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="bg-blue-50 rounded-lg p-6">
                      <h3 className="text-lg font-semibold text-blue-900 mb-4">4.2 Enhanced Security Mode</h3>
                      <p className="text-blue-700 mb-4">
                        When Enhanced Security mode is enabled, additional protection measures are implemented:
                      </p>
                      <ul className="space-y-2 text-blue-700">
                        <li className="flex items-start">
                          <ShieldCheckIcon className="h-5 w-5 text-blue-500 mt-0.5 mr-2" />
                          <span>Client-side encryption before transmission</span>
                        </li>
                        <li className="flex items-start">
                          <ShieldCheckIcon className="h-5 w-5 text-blue-500 mt-0.5 mr-2" />
                          <span>Zero-knowledge architecture</span>
                        </li>
                        <li className="flex items-start">
                          <ShieldCheckIcon className="h-5 w-5 text-blue-500 mt-0.5 mr-2" />
                          <span>End-to-end encryption for all content</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </section>
                <section id="data-sharing" className="mt-12">
                  <h2>5. Data Sharing</h2>
                  
                  <div className="mt-6 bg-white rounded-lg border border-gray-200">
                    <div className="px-6 py-4 border-b border-gray-200">
                      <h3 className="text-lg font-semibold text-gray-900">5.1 Third-Party Service Providers</h3>
                    </div>
                    <div className="p-6">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead>
                          <tr>
                            <th className="px-4 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Provider Category
                            </th>
                            <th className="px-4 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Purpose
                            </th>
                            <th className="px-4 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Data Access Level
                            </th>
                            <th className="px-4 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Security Measures
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                          <tr>
                            <td className="px-4 py-4 text-sm text-gray-900">Payment Processors</td>
                            <td className="px-4 py-4 text-sm text-gray-600">Transaction processing</td>
                            <td className="px-4 py-4 text-sm text-gray-600">Payment data only</td>
                            <td className="px-4 py-4 text-sm text-gray-600">PCI DSS compliance</td>
                          </tr>
                          <tr>
                            <td className="px-4 py-4 text-sm text-gray-900">Cloud Storage</td>
                            <td className="px-4 py-4 text-sm text-gray-600">Data storage</td>
                            <td className="px-4 py-4 text-sm text-gray-600">Encrypted data only</td>
                            <td className="px-4 py-4 text-sm text-gray-600">SOC 2 Type II certified</td>
                          </tr>
                          <tr>
                            <td className="px-4 py-4 text-sm text-gray-900">Email Service</td>
                            <td className="px-4 py-4 text-sm text-gray-600">Notifications</td>
                            <td className="px-4 py-4 text-sm text-gray-600">Email address only</td>
                            <td className="px-4 py-4 text-sm text-gray-600">TLS encryption</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className="mt-8 bg-yellow-50 rounded-lg p-6">
                    <h3 className="text-lg font-semibold text-yellow-900 mb-4">5.2 Legal Requirements</h3>
                    <p className="text-yellow-800 mb-4">
                      We may disclose your information if required by law, regulation, or legal process:
                    </p>
                    <ul className="space-y-3">
                      <li className="flex items-start">
                        <DocumentIcon className="h-5 w-5 text-yellow-700 mt-0.5 mr-2" />
                        <span className="text-yellow-800">Response to valid court orders or subpoenas</span>
                      </li>
                      <li className="flex items-start">
                        <DocumentIcon className="h-5 w-5 text-yellow-700 mt-0.5 mr-2" />
                        <span className="text-yellow-800">Protection of legal rights and property</span>
                      </li>
                      <li className="flex items-start">
                        <DocumentIcon className="h-5 w-5 text-yellow-700 mt-0.5 mr-2" />
                        <span className="text-yellow-800">Prevention of fraud or illegal activities</span>
                      </li>
                    </ul>
                    <div className="mt-4 bg-yellow-100 rounded p-4">
                      <p className="text-yellow-800 text-sm">
                        Note: For enhanced security messages, we can only provide encrypted data that we cannot decrypt.
                      </p>
                    </div>
                  </div>
                </section>

                <section id="user-controls" className="mt-12">
                  <h2>6. User Controls</h2>

                  <div className="mt-6 grid md:grid-cols-2 gap-6">
                    <div className="bg-white rounded-lg border border-gray-200 p-6">
                      <h3 className="text-lg font-semibold text-gray-900 mb-4">6.1 Privacy Settings</h3>
                      <div className="space-y-4">
                        <div className="flex items-start">
                          <CogIcon className="h-5 w-5 text-blue-500 mt-0.5 mr-2" />
                          <div>
                            <h4 className="font-medium text-gray-900">Security Level</h4>
                            <p className="text-sm text-gray-600">Choose between standard or enhanced security</p>
                          </div>
                        </div>
                        <div className="flex items-start">
                          <BellIcon className="h-5 w-5 text-blue-500 mt-0.5 mr-2" />
                          <div>
                            <h4 className="font-medium text-gray-900">Notifications</h4>
                            <p className="text-sm text-gray-600">Manage communication preferences</p>
                          </div>
                        </div>
                        <div className="flex items-start">
                          <KeyIcon className="h-5 w-5 text-blue-500 mt-0.5 mr-2" />
                          <div>
                            <h4 className="font-medium text-gray-900">Authentication</h4>
                            <p className="text-sm text-gray-600">Configure two-factor authentication</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="bg-white rounded-lg border border-gray-200 p-6">
                      <h3 className="text-lg font-semibold text-gray-900 mb-4">6.2 Data Management</h3>
                      <div className="space-y-4">
                        <div className="flex items-start">
                          <ArrowDownTrayIcon className="h-5 w-5 text-blue-500 mt-0.5 mr-2" />
                          <div>
                            <h4 className="font-medium text-gray-900">Export Data</h4>
                            <p className="text-sm text-gray-600">Download your personal information</p>
                          </div>
                        </div>
                        <div className="flex items-start">
                          <TrashIcon className="h-5 w-5 text-blue-500 mt-0.5 mr-2" />
                          <div>
                            <h4 className="font-medium text-gray-900">Delete Data</h4>
                            <p className="text-sm text-gray-600">Remove your data from our systems</p>
                          </div>
                        </div>
                        <div className="flex items-start">
                          <EyeIcon className="h-5 w-5 text-blue-500 mt-0.5 mr-2" />
                          <div>
                            <h4 className="font-medium text-gray-900">Access Logs</h4>
                            <p className="text-sm text-gray-600">View account activity history</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section id="data-retention" className="mt-12">
                  <h2>7. Data Retention</h2>

                  <div className="mt-6 space-y-8">
                    <div className="bg-white rounded-lg border border-gray-200">
                      <div className="px-6 py-4 border-b border-gray-200">
                        <h3 className="text-lg font-semibold text-gray-900">7.1 Retention Periods</h3>
                      </div>
                      <div className="p-6">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead>
                            <tr>
                              <th className="px-4 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase">
                                Data Type
                              </th>
                              <th className="px-4 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase">
                                Retention Period
                              </th>
                              <th className="px-4 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase">
                                Reason for Retention
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="px-4 py-4 text-sm text-gray-900">Account Information</td>
                              <td className="px-4 py-4 text-sm text-gray-600">Duration of account + 30 days</td>
                              <td className="px-4 py-4 text-sm text-gray-600">Account recovery and legal compliance</td>
                            </tr>
                            <tr>
                              <td className="px-4 py-4 text-sm text-gray-900">Message Content</td>
                              <td className="px-4 py-4 text-sm text-gray-600">Until scheduled delivery</td>
                              <td className="px-4 py-4 text-sm text-gray-600">Service functionality</td>
                            </tr>
                            <tr>
                              <td className="px-4 py-4 text-sm text-gray-900">Security Logs</td>
                              <td className="px-4 py-4 text-sm text-gray-600">12 months</td>
                              <td className="px-4 py-4 text-sm text-gray-600">Security and audit requirements</td>
                            </tr>
                            <tr>
                              <td className="px-4 py-4 text-sm text-gray-900">Payment Records</td>
                              <td className="px-4 py-4 text-sm text-gray-600">7 years</td>
                              <td className="px-4 py-4 text-sm text-gray-600">Financial regulations</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="bg-gray-50 rounded-lg p-6">
                      <h3 className="text-lg font-semibold text-gray-900 mb-4">7.2 Account Closure Process</h3>
                      <div className="space-y-4">
                        <div className="flex items-start">
                          <ClockIcon className="h-5 w-5 text-blue-500 mt-1 mr-3" />
                          <div>
                            <h4 className="font-medium text-gray-900">30-Day Recovery Period</h4>
                            <p className="text-sm text-gray-600">
                              After account deletion request, data is maintained for 30 days to allow for recovery
                            </p>
                          </div>
                        </div>
                        <div className="flex items-start">
                          <ArchiveBoxIcon className="h-5 w-5 text-blue-500 mt-1 mr-3" />
                          <div>
                            <h4 className="font-medium text-gray-900">Data Archival</h4>
                            <p className="text-sm text-gray-600">
                              Certain data may be retained in encrypted backups for up to 90 days
                            </p>
                          </div>
                        </div>
                        <div className="flex items-start">
                          <TrashIcon className="h-5 w-5 text-blue-500 mt-1 mr-3" />
                          <div>
                            <h4 className="font-medium text-gray-900">Permanent Deletion</h4>
                            <p className="text-sm text-gray-600">
                              After retention periods expire, data is securely and permanently deleted
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section id="childrens-privacy" className="mt-12">
                  <h2>8. Children's Privacy</h2>

                  <div className="mt-6 bg-red-50 rounded-lg p-6">
                    <div className="flex items-start">
                      <ExclamationCircleIcon className="h-6 w-6 text-red-500 mt-1 mr-3" />
                      <div>
                        <h3 className="text-lg font-semibold text-red-900 mb-2">Age Restrictions</h3>
                        <p className="text-red-700 mb-4">
                          Cryonote does not knowingly collect or solicit personal information from anyone under the age of 18. 
                          If you are under 18, please do not attempt to register for the Services or send any personal information 
                          about yourself to us.
                        </p>
                        <div className="bg-red-100 rounded p-4">
                          <h4 className="font-medium text-red-900 mb-2">If We Learn of Children's Data:</h4>
                          <ul className="list-disc list-inside space-y-2 text-red-700">
                            <li>We will immediately delete such information</li>
                            <li>We will terminate associated accounts</li>
                            <li>We will notify relevant guardians if possible</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section id="international-transfers" className="mt-12">
                  <h2>9. International Data Transfers</h2>

                  <div className="mt-6 space-y-6">
                    <div className="bg-white rounded-lg border border-gray-200 p-6">
                      <h3 className="text-lg font-semibold text-gray-900 mb-4">9.1 Data Location</h3>
                      <div className="grid md:grid-cols-3 gap-4">
                        <div className="p-4 bg-gray-50 rounded-lg">
                          <h4 className="font-medium text-gray-900 mb-2">Primary Storage</h4>
                          <p className="text-sm text-gray-600">European Union (Frankfurt)</p>
                        </div>
                        <div className="p-4 bg-gray-50 rounded-lg">
                          <h4 className="font-medium text-gray-900 mb-2">Backup Storage</h4>
                          <p className="text-sm text-gray-600">United States (Virginia)</p>
                        </div>
                        <div className="p-4 bg-gray-50 rounded-lg">
                          <h4 className="font-medium text-gray-900 mb-2">Disaster Recovery</h4>
                          <p className="text-sm text-gray-600">Singapore</p>
                        </div>
                      </div>
                    </div>

                    <div className="bg-blue-50 rounded-lg p-6">
                      <h3 className="text-lg font-semibold text-blue-900 mb-4">9.2 Transfer Safeguards</h3>
                      <ul className="space-y-4">
                        <li className="flex items-start">
                          <ShieldCheckIcon className="h-5 w-5 text-blue-500 mt-1 mr-3" />
                          <div>
                            <h4 className="font-medium text-blue-900">Standard Contractual Clauses (SCCs)</h4>
                            <p className="text-sm text-blue-700">EU-approved mechanisms for international data transfers</p>
                          </div>
                        </li>
                        <li className="flex items-start">
                          <ShieldCheckIcon className="h-5 w-5 text-blue-500 mt-1 mr-3" />
                          <div>
                            <h4 className="font-medium text-blue-900">Privacy Shield Framework</h4>
                            <p className="text-sm text-blue-700">Compliant with EU-US and Swiss-US requirements</p>
                          </div>
                        </li>
                        <li className="flex items-start">
                          <ShieldCheckIcon className="h-5 w-5 text-blue-500 mt-1 mr-3" />
                          <div>
                            <h4 className="font-medium text-blue-900">Additional Security Measures</h4>
                            <p className="text-sm text-blue-700">End-to-end encryption and access controls</p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </section>

                <section id="security-measures" className="mt-12">
                  <h2>12. Security Measures</h2>
                  <div className="mt-6 grid md:grid-cols-2 gap-6">
                    <div className="bg-white rounded-lg border border-gray-200 p-6">
                      <h3 className="text-lg font-semibold text-gray-900 mb-4">12.1 Technical Controls</h3>
                      <ul className="space-y-3">
                        <li className="flex items-center">
                          <LockClosedIcon className="h-5 w-5 text-green-500 mr-2" />
                          <span>End-to-end encryption</span>
                        </li>
                        <li className="flex items-center">
                          <KeyIcon className="h-5 w-5 text-green-500 mr-2" />
                          <span>Multi-factor authentication</span>
                        </li>
                        <li className="flex items-center">
                          <ShieldCheckIcon className="h-5 w-5 text-green-500 mr-2" />
                          <span>Advanced intrusion detection</span>
                        </li>
                        <li className="flex items-center">
                          <ClockIcon className="h-5 w-5 text-green-500 mr-2" />
                          <span>Real-time threat monitoring</span>
                        </li>
                      </ul>
                    </div>
                    <div className="bg-white rounded-lg border border-gray-200 p-6">
                      <h3 className="text-lg font-semibold text-gray-900 mb-4">12.2 Personnel Security</h3>
                      <ul className="space-y-3">
                        <li className="flex items-center">
                          <UserIcon className="h-5 w-5 text-green-500 mr-2" />
                          <span>Background checks</span>
                        </li>
                        <li className="flex items-center">
                          <AcademicCapIcon className="h-5 w-5 text-green-500 mr-2" />
                          <span>Regular security training</span>
                        </li>
                        <li className="flex items-center">
                          <DocumentCheckIcon className="h-5 w-5 text-green-500 mr-2" />
                          <span>Security certifications</span>
                        </li>
                        <li className="flex items-center">
                          <ClipboardDocumentCheckIcon className="h-5 w-5 text-green-500 mr-2" />
                          <span>Access control policies</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </section>

                <section id="contact-info" className="mt-12">
                  <h2>14. Contact Information</h2>
                  <div className="mt-6 bg-gray-50 rounded-lg p-6">
                    <div className="grid md:grid-cols-2 gap-6">
                      <div>
                        <h3 className="text-lg font-semibold text-gray-900 mb-4">Data Protection Officer</h3>
                        <ul className="space-y-2 text-gray-600">
                          <li className="flex items-center">
                            <EnvelopeIcon className="h-5 w-5 text-blue-500 mr-2" />
                            <span>dpo@cryonote.com</span>
                          </li>
                          <li className="flex items-center">
                            <PhoneIcon className="h-5 w-5 text-blue-500 mr-2" />
                            <span>+1 (555) 123-4567</span>
                          </li>
                          <li className="flex items-center">
                            <MapPinIcon className="h-5 w-5 text-blue-500 mr-2" />
                            <span>123 Privacy Street, NY 10001, USA</span>
                          </li>
                        </ul>
                      </div>
                      <div>
                        <h3 className="text-lg font-semibold text-gray-900 mb-4">Privacy Team</h3>
                        <ul className="space-y-2 text-gray-600">
                          <li className="flex items-center">
                            <EnvelopeIcon className="h-5 w-5 text-blue-500 mr-2" />
                            <span>privacy@cryonote.com</span>
                          </li>
                          <li className="flex items-center">
                            <ClockIcon className="h-5 w-5 text-blue-500 mr-2" />
                            <span>Response within 24 hours</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </section>

                {/* Final CTA Section */}
                <div className="mt-12 bg-blue-50 rounded-lg p-8 text-center">
                  <h3 className="text-2xl font-bold text-blue-900 mb-4">Questions About Your Privacy?</h3>
                  <p className="text-blue-700 mb-6">
                    We're committed to protecting your privacy and are always here to help with any questions or concerns.
                  </p>
                  <div className="flex justify-center space-x-4">
                    <button
                      onClick={() => window.location.href = '/contact'}
                      className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
                    >
                      Contact Us
                    </button>
                    <button
                      onClick={() => window.location.href = '/privacy/faq'}
                      className="px-6 py-2 border border-blue-600 text-blue-600 rounded-lg hover:bg-blue-50 transition-colors"
                    >
                      Privacy FAQ
                    </button>
                  </div>
                </div>
              </div>

              {/* Document Footer with Version Info */}
              <div className="mt-8 bg-white rounded-lg shadow-sm p-6">
                <div className="flex justify-between items-center text-sm text-gray-500">
                  <div className="flex items-center">
                    <DocumentCheckIcon className="h-5 w-5 text-green-500 mr-2" />
                    <span>Last updated: {lastUpdated}</span>
                  </div>
                  <div>Version {version}</div>
                </div>
                <div className="mt-4 pt-4 border-t border-gray-200 text-center">
                  <p className="text-sm text-gray-600">
                    This Privacy Policy is available in other languages. 
                    <button className="ml-2 text-blue-600 hover:text-blue-800">
                      Change Language
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      
      <Footer />
    </div>
  );
};

export default PrivacyPolicyPage;