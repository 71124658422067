// FileDecryptor.jsx
import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { InformationCircleIcon, ArrowUpTrayIcon } from '@heroicons/react/24/outline';
import EncryptionService from '../../services/EncryptionService';

const FileDecryptor = ({ decryptionKey }) => {
  const [files, setFiles] = useState([]);
  const [showInstructions, setShowInstructions] = useState(true);

  const onDrop = useCallback(acceptedFiles => {
    setFiles(acceptedFiles.map(file => ({
      file,
      status: 'pending',
      error: null
    })));
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  // Update FileDecryptor.jsx
  const handleDecrypt = async (fileInfo) => {
    try {
      fileInfo.status = 'processing';
      fileInfo.progress = 0;
      setFiles([...files]);
  
      const reader = new FileReader();
      
      reader.onprogress = (event) => {
        if (event.lengthComputable) {
          fileInfo.progress = (event.loaded / event.total) * 50; // First 50% is reading
          setFiles([...files]);
        }
      };
      
      reader.onload = async (event) => {
        try {
          const encryptedBlob = new Blob([event.target.result], { 
            type: 'application/octet-stream' 
          });
          
          fileInfo.progress = 50; // Reading complete
          setFiles([...files]);
  
          // Decrypt the file
          const decryptedFile = await EncryptionService.decryptFile(encryptedBlob, decryptionKey);
          
          fileInfo.progress = 90; // Decryption complete
          setFiles([...files]);
  
          // Create download URL and trigger download
          const url = URL.createObjectURL(decryptedFile);
          
          const a = document.createElement('a');
          a.href = url;
          a.download = decryptedFile.name;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          
          // Clean up
          URL.revokeObjectURL(url);
          
          fileInfo.status = 'completed';
          fileInfo.progress = 100;
          setFiles([...files]);
  
          // Verify file integrity
          const fileReader = new FileReader();
          fileReader.onload = () => {
            if (fileReader.result.byteLength === 0) {
              fileInfo.status = 'error';
              fileInfo.error = 'Decrypted file appears to be empty';
              setFiles([...files]);
            }
          };
          fileReader.readAsArrayBuffer(decryptedFile);
  
        } catch (error) {
          console.error('File decryption error:', error);
          fileInfo.status = 'error';
          fileInfo.error = 'Failed to decrypt file (please extract to different folder): ' + (error.message || 'Unknown error');
          fileInfo.progress = 0;
          setFiles([...files]);
        }
      };
  
      reader.onerror = (error) => {
        console.error('File reading error:', error);
        fileInfo.status = 'error';
        fileInfo.error = 'Failed to read file';
        fileInfo.progress = 0;
        setFiles([...files]);
      };
  
      // Read file as ArrayBuffer
      reader.readAsArrayBuffer(fileInfo.file);
    } catch (error) {
      console.error('Handling error:', error);
      fileInfo.status = 'error';
      fileInfo.error = error.message || 'Unknown error';
      fileInfo.progress = 0;
      setFiles([...files]);
    }
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow">
      <h3 className="text-xl font-semibold mb-4">File Decryptor</h3>

      {showInstructions && (
        <div className="mb-6 bg-blue-50 p-4 rounded-lg">
          <div className="flex">
            <InformationCircleIcon className="h-6 w-6 text-blue-500 mt-0.5 mr-3" />
            <div>
              <h4 className="text-lg font-medium text-blue-900 mb-2">File Decryption Instructions</h4>
              <ol className="list-decimal list-inside space-y-2 text-blue-800">
                <li>Extract the files from the downloaded .7z archive using the provided password</li>
                <li>Drop the extracted files here to decrypt them</li>
                <li>Files are decrypted locally in your browser</li>
                <li>Decrypted files will automatically download when ready</li>
              </ol>
            </div>
          </div>
        </div>
      )}

      <div
        {...getRootProps()}
        className={`border-2 border-dashed rounded-lg p-8 text-center cursor-pointer transition-colors ${
          isDragActive ? 'border-blue-500 bg-blue-50' : 'border-gray-300 hover:border-blue-500'
        }`}
      >
        <input {...getInputProps()} />
        <ArrowUpTrayIcon className="h-12 w-12 mx-auto text-gray-400 mb-4" />
        <p className="text-lg text-gray-600">
          {isDragActive
            ? "Drop the files here..."
            : "Drag 'n' drop files here, or click to select files"
        }
        </p>
      </div>

      {files.length > 0 && (
        <div className="mt-6 space-y-4">
          {files.map((fileInfo, index) => (
            <div key={index} className="flex items-center justify-between bg-gray-50 p-4 rounded-lg">
              <span className="font-medium">{fileInfo.file.name}</span>
              {fileInfo.status === 'pending' && (
                <button
                  onClick={() => handleDecrypt(fileInfo)}
                  className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                >
                  Decrypt
                </button>
              )}
              {fileInfo.status === 'processing' && (
                <span className="text-blue-600">Decrypting...</span>
              )}
              {fileInfo.status === 'completed' && (
                <span className="text-green-600">Decrypted Successfully</span>
              )}
              {fileInfo.status === 'error' && (
                <span className="text-red-600">{fileInfo.error}</span>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default FileDecryptor;