// src/pages/dashboard/FAQ.jsx
import React from 'react';
import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/24/outline';

const faqs = [
  {
    question: "How does the check-in trigger work?",
    answer: "The check-in trigger sends you regular notifications based on your chosen frequency. If you fail to respond to these check-ins within the grace period, it will initiate the final verification process before releasing your messages."
  },
  {
    question: "What is the consensus trigger?",
    answer: "The consensus trigger allows you to designate trusted contacts who can report if something has happened to you. If the required number of contacts report, it will initiate the final verification process before releasing your messages."
  },
  {
    question: "How do I create a message?",
    answer: "Go to the Messages page and click on 'New Message'. You can then compose your message, add attachments, and specify recipients. Messages can be set to different security levels."
  },
  {
    question: "What happens if I forget my passphrase?",
    answer: "Your passphrase is crucial for the final verification process. If you forget it, you won't be able to stop the release of your messages if a trigger is activated. Make sure to store it securely."
  },
  {
    question: "Can I edit or delete my messages after creating them?",
    answer: "Yes, you can edit or delete your messages at any time before they are released. Once a message is released, it cannot be recalled."
  }
];

const FAQ = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <h1 className="text-3xl font-bold text-gray-900 mb-6">Frequently Asked Questions</h1>
      <div className="space-y-4">
        {faqs.map((faq, index) => (
          <Disclosure as="div" key={index} className="bg-white shadow overflow-hidden sm:rounded-lg">
            {({ open }) => (
              <>
                <Disclosure.Button className="w-full px-4 py-5 sm:px-6 text-left">
                  <div className="flex justify-between items-center">
                    <span className="text-lg font-medium text-gray-900">{faq.question}</span>
                    <ChevronUpIcon
                      className={`${
                        open ? 'transform rotate-180' : ''
                      } w-5 h-5 text-gray-500`}
                    />
                  </div>
                </Disclosure.Button>
                <Disclosure.Panel className="px-4 pt-4 pb-2 sm:px-6 sm:pt-6 sm:pb-4">
                  <p className="text-sm text-gray-500">{faq.answer}</p>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        ))}
      </div>
    </div>
  );
};

export default FAQ;