import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const navItems = [
    { name: 'Features', path: '/', hash: '#features' },
    { name: 'Pricing', path: '/', hash: '#pricing' },
    { name: 'Security', path: '/security' },
    { name: 'Longevity & Trust', path: '/longevity-and-trust' },
    { name: 'About', path: '/about' },
  ];

  const isActive = (item) => {
    const currentPath = location.pathname;
    const currentHash = location.hash;
    if (item.hash) {
      return currentPath === item.path && currentHash === item.hash;
    } else {
      return currentPath === item.path;
    }
  };

  return (
    <nav
      className={`${
        isScrolled ? 'bg-white shadow-lg' : 'bg-transparent'
      } fixed top-0 left-0 right-0 z-50 transition-all duration-300`}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16">
          {/* Logo */}
          <div className="flex-shrink-0 flex items-center">
            <Link to="/" className="flex items-center space-x-2">
              {/* Your logo SVG */}
              <svg
                className="h-10 w-10"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="5"
                  y="5"
                  width="30"
                  height="30"
                  rx="5"
                  fill="url(#logo-gradient)"
                />
                <path d="M15 15L25 15L25 25L15 25L15 15Z" fill="white" />
                <defs>
                  <linearGradient
                    id="logo-gradient"
                    x1="5"
                    y1="5"
                    x2="35"
                    y2="35"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#3B82F6" />
                    <stop offset="1" stopColor="#06B6D4" />
                  </linearGradient>
                </defs>
              </svg>
              <span className="text-2xl font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-cyan-500">
                Cryonote
              </span>
            </Link>
          </div>
          {/* Desktop Menu */}
          <div className="hidden md:flex md:items-center md:space-x-6">
            {navItems.map((item) => {
              const active = isActive(item);
              return (
                <Link
                  key={item.name}
                  to={{ pathname: item.path, hash: item.hash }}
                  className={`${
                    active
                      ? 'text-blue-600 px-3 py-2 text-sm font-medium rounded-md border border-blue-600'
                      : 'text-gray-600 hover:text-blue-600 px-3 py-2 text-sm font-medium transition-colors duration-200'
                  }`}
                >
                  {item.name}
                </Link>
              );
            })}
            <Link
              to="/login"
              className="text-blue-600 hover:text-blue-700 px-3 py-2 text-sm font-medium transition-colors duration-200"
            >
              Login
            </Link>
            <Link
              to="/signup"
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-full text-white bg-gradient-to-r from-blue-600 to-cyan-500 hover:from-blue-700 hover:to-cyan-600 transition-all duration-200 shadow-md hover:shadow-lg"
            >
              Start Now
            </Link>
          </div>
          {/* Mobile Menu Button */}
          <div className="md:hidden flex items-center">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500 transition-colors duration-200"
            >
              <span className="sr-only">Open main menu</span>
              {isMenuOpen ? (
                <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
              ) : (
                <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
              )}
            </button>
          </div>
        </div>
      </div>
      {/* Mobile Menu */}
      <div
        className={`${
          isMenuOpen ? 'block' : 'hidden'
        } md:hidden transition-all duration-300 ease-in-out`}
      >
        <div
          className="fixed inset-0 bg-gray-800 bg-opacity-25 backdrop-blur-sm"
          onClick={() => setIsMenuOpen(false)}
        />
        <div className="absolute top-16 inset-x-0 bg-white shadow-lg border-b border-gray-200">
          <div className="px-4 py-6 space-y-4">
            {navItems.map((item) => {
              const active = isActive(item);
              return (
                <Link
                  key={item.name}
                  to={{ pathname: item.path, hash: item.hash }}
                  className={`block px-4 py-2 rounded-md text-base font-medium ${
                    active
                      ? 'text-blue-600 bg-blue-50'
                      : 'text-gray-700 hover:text-blue-600 hover:bg-blue-50'
                  } transition-colors duration-200`}
                  onClick={() => setIsMenuOpen(false)}
                >
                  {item.name}
                </Link>
              );
            })}
            <Link
              to="/signup"
              className="block w-full text-center px-4 py-3 bg-gradient-to-r from-blue-600 to-cyan-500 text-white rounded-full font-medium hover:from-blue-700 hover:to-cyan-600 transition-all duration-200 shadow-md hover:shadow-lg"
              onClick={() => setIsMenuOpen(false)}
            >
              Start Now
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
