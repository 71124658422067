import React, { useState, useMemo } from 'react';
import MessageCard from './MessageCard';
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/24/solid';

const MessageList = ({ messages, onView, onEdit, onDelete, onStatusChange }) => {
  const [sortBy, setSortBy] = useState('date');
  const [sortOrder, setSortOrder] = useState('desc');

  const sortedMessages = useMemo(() => {
    return [...messages].sort((a, b) => {
      switch (sortBy) {
        case 'date':
          return sortOrder === 'asc' 
            ? new Date(a.createdAt) - new Date(b.createdAt)
            : new Date(b.createdAt) - new Date(a.createdAt);
        case 'title':
          return sortOrder === 'asc'
            ? a.title.localeCompare(b.title)
            : b.title.localeCompare(a.title);
        case 'status':
          return sortOrder === 'asc'
            ? a.status.localeCompare(b.status)
            : b.status.localeCompare(a.status);
        default:
          return 0;
      }
    });
  }, [messages, sortBy, sortOrder]);

  const handleSort = (newSortBy) => {
    if (newSortBy === sortBy) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(newSortBy);
      setSortOrder('asc');
    }
  };

  const SortButton = ({ label, sortKey }) => (
    <button
      onClick={() => handleSort(sortKey)}
      className={`px-3 py-2 text-sm font-medium rounded-md ${
        sortBy === sortKey ? 'bg-blue-100 text-blue-800' : 'text-gray-500 hover:bg-gray-100'
      }`}
    >
      {label}
      {sortBy === sortKey && (
        sortOrder === 'asc' ? <ChevronUpIcon className="inline w-4 h-4 ml-1" /> : <ChevronDownIcon className="inline w-4 h-4 ml-1" />
      )}
    </button>
  );

  return (
    <div>
      <div className="mb-4 flex space-x-2">
        <SortButton label="Date" sortKey="date" />
        <SortButton label="Title" sortKey="title" />
        <SortButton label="Status" sortKey="status" />
      </div>
      <div className="space-y-4">
        {sortedMessages.map((message) => (
          <MessageCard
            key={message._id}
            message={message}
            onView={onView}
            onEdit={onEdit}
            onDelete={onDelete}
            onStatusChange={onStatusChange}
          />
        ))}
      </div>
    </div>
  );
};

export default MessageList;