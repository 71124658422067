import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../services/api';

export const fetchTriggers = createAsyncThunk('triggers/fetchTriggers', async () => {
  const response = await api.triggers.getTriggers();
  return response.data;
});

export const updateTrigger = createAsyncThunk(
  'triggers/updateTrigger',
  async (triggerData, { rejectWithValue }) => {
    try {
      const response = await api.triggers.updateTrigger(triggerData._id, triggerData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const triggerSlice = createSlice({
  name: 'triggers',
  initialState: {
    triggers: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTriggers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchTriggers.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.triggers = [action.payload];
        console.log('State.triggers =', state.triggers);
      })
      .addCase(fetchTriggers.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(updateTrigger.fulfilled, (state, action) => {
        const index = state.triggers.findIndex(trigger => trigger._id === action.payload._id);
        if (index !== -1) {
          state.triggers[index] = action.payload;
        }
      });
  },
});

export default triggerSlice.reducer;