import React, { useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-gray-200">
      <button
        className="flex justify-between items-center w-full py-4 text-left"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="text-lg font-medium text-gray-900">{question}</span>
        {isOpen ? (
          <ChevronUpIcon className="h-5 w-5 text-blue-500" />
        ) : (
          <ChevronDownIcon className="h-5 w-5 text-blue-500" />
        )}
      </button>
      {isOpen && (
        <div className="pb-4 pr-4">
          <p className="text-gray-600">{answer}</p>
        </div>
      )}
    </div>
  );
};

const HelpCenter = () => {
  const faqs = [
    {
      question: "How do I create a new trigger?",
      answer: "To create a new trigger, go to the 'Triggers' page and click on the 'New Trigger' button. Follow the step-by-step guide to set up your trigger conditions and actions."
    },
    {
      question: "Can I customize notification settings?",
      answer: "Yes, you can customize your notification settings in the 'Settings' page. You can choose to receive notifications via email, SMS, or push notifications on your mobile device."
    },
    {
      question: "How do I integrate Cryonote with my existing tools?",
      answer: "Cryonote offers various integrations with popular tools. Check the 'Integrations' section in the 'Settings' page to see available options and follow the integration guides."
    },
    {
      question: "How secure is my data on Cryonote?",
      answer: "We take data security very seriously. All data is encrypted in transit and at rest. We use industry-standard security practices and regularly perform security audits."
    },
    {
      question: "Can I export my data from Cryonote?",
      answer: "Yes, you can export your data in various formats from the 'Account' section. We support CSV, JSON, and XML exports for most data types."
    },
    {
      question: "What kind of support does Cryonote offer?",
      answer: "We offer 24/7 email support, live chat during business hours, and scheduled video calls for premium users. Our documentation is also constantly updated with user feedback."
    },
    // Add more FAQs as needed
  ];

  const handleContactSupport = () => {
    window.location.href = "mailto:cryonotemail@gmail.com?subject=Support%20Request&body=Please%20describe%20your%20issue%20here.";
  };

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold text-gray-900 mb-8">Help Center</h1>
      
      <div className="bg-white shadow-lg rounded-lg overflow-hidden mb-8">
        <div className="p-6">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">Frequently Asked Questions</h2>
          <div className="space-y-4">
            {faqs.map((faq, index) => (
              <FAQItem key={index} question={faq.question} answer={faq.answer} />
            ))}
          </div>
        </div>
      </div>

      <div className="grid md:grid-cols-2 gap-8">
        <div className="bg-white shadow-lg rounded-lg overflow-hidden">
          <div className="p-6">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">Need More Help?</h2>
            <p className="text-gray-600 mb-4">
              If you couldn't find the answer you were looking for, our support team is here to help.
            </p>
            <button 
              onClick={handleContactSupport}
              className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-150 ease-in-out"
            >
              Contact Support
            </button>
          </div>
        </div>

        {/* <div className="bg-white shadow-lg rounded-lg overflow-hidden">
          <div className="p-6">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">Resources</h2>
            <ul className="space-y-2 text-gray-600">
              <li>
                <a href="/docs" className="text-blue-600 hover:underline">Documentation</a>
              </li>
              <li>
                <a href="/tutorials" className="text-blue-600 hover:underline">Video Tutorials</a>
              </li>
              <li>
                <a href="/blog" className="text-blue-600 hover:underline">Blog</a>
              </li>
              <li>
                <a href="/community" className="text-blue-600 hover:underline">Community Forum</a>
              </li>
            </ul>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default HelpCenter;
