// src/services/api.js
import axios from 'axios';
import { auth as firebaseAuth } from '../config/firebase';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Comment

// Request interceptor
axiosInstance.interceptors.request.use(
  async (config) => {
    // Skip auth header for public endpoints
    const skipAuth = [
      '/api/auth/login',
      '/api/auth/signup',
      '/api/auth/google',
      '/api/auth/csrf-token',
      '/uploads'
    ];

    if (!skipAuth.some(path => config.url.includes(path))) {
      try {
        const user = firebaseAuth.currentUser;
        if (user) {
          const token = await user.getIdToken();
          config.headers['Authorization'] = `Bearer ${token}`;
        }
      } catch (error) {
        console.error('Failed to get auth token:', error);
      }
    }

    return config;
  },
  (error) => {
    console.error('Request interceptor error:', error);
    return Promise.reject(error);
  }
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    // Handle specific error cases
    if (error.response?.status === 401) {
      // Redirect to login on authentication failure
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

// Authentication endpoints
const auth = {
  login: (credentials) => axiosInstance.post('/api/auth/login', credentials),
  register: (userData) => axiosInstance.post('/api/auth/signup', userData),
  logout: () => axiosInstance.post('/api/auth/logout'),
  verify2FA: (token, code) => axiosInstance.post('/api/auth/verify-2fa', { token, code }),
  getUser: () => axiosInstance.get('/api/auth/me'),
  getSessions: () => axiosInstance.get('/api/auth/sessions'),
  revokeSession: (sessionId) => axiosInstance.post(`/api/auth/sessions/${sessionId}/revoke`),
  setup2FA: (method) => axiosInstance.post('/api/auth/2fa/setup', { method }),
  disable2FA: () => axiosInstance.post('/api/auth/2fa/disable'),
  verifyDevice: () => axiosInstance.post('/api/auth/verify-device')
};

// User-related endpoints
const user = {
  getProfile: () => axiosInstance.get('/api/user/profile'),
  updateProfile: (profileData) => axiosInstance.put('/api/user/profile', profileData),
  updateSettings: (settings) => axiosInstance.put('/api/user/settings', settings),
  changePassword: (passwordData) => axiosInstance.put('/api/user/password', passwordData),
  getStorageStats: () => axiosInstance.get('/api/user/storage'),
  deleteAccount: () => axiosInstance.delete('/api/user/account')
};

// Messages endpoints
const messages = {
  getMessages: () => axiosInstance.get('/api/messages'),
  getMessage: (messageId) => axiosInstance.get(`/api/messages/${messageId}`),
  deleteMessage: (messageId) => axiosInstance.delete(`/api/messages/${messageId}`),
  createMessage: (messageData) => axiosInstance.post('/api/messages', messageData),
  updateMessage: (messageId, messageData) => axiosInstance.put(`/api/messages/${messageId}`, messageData),
  updateMessageStatus: (messageId, status) => axiosInstance.put(`/api/messages/${messageId}/status`, { status })
};

// Triggers endpoints
const triggers = {
  getTriggers: () => axiosInstance.get('/api/triggers'),
  getTrigger: (triggerId) => axiosInstance.get(`/api/triggers/${triggerId}`),
  deleteTrigger: (triggerId) => axiosInstance.delete(`/api/triggers/${triggerId}`),
  createTrigger: (triggerData) => axiosInstance.post('/api/triggers', triggerData),
  updateTrigger: (triggerId, triggerData) => axiosInstance.put(`/api/triggers/${triggerId}`, triggerData),
  verifyTrustedContact: (triggerId, token) => axiosInstance.get(`/api/triggers/verify-trusted-contact/${triggerId}/${token}`)
};

// Security-related endpoints
const security = {
  getCsrfToken: () => axiosInstance.get('/api/auth/csrf-token'),
  getSecurityStatus: () => axiosInstance.get('/api/security/status'),
  getAuditLog: () => axiosInstance.get('/api/security/audit-log'),
  revokeAllSessions: () => axiosInstance.post('/api/security/revoke-all-sessions'),
  updateSecurityPreferences: (preferences) => 
    axiosInstance.put('/api/security/preferences', preferences)
};

// File handling and device fingerprint functionality
const generateFingerprint = async () => {
  const components = {
    userAgent: navigator.userAgent,
    language: navigator.language,
    platform: navigator.platform,
    screen: {
      width: window.screen.width,
      height: window.screen.height,
      colorDepth: window.screen.colorDepth
    },
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
  };

  try {
    // Create hash of components
    const hash = await crypto.subtle.digest(
      'SHA-256',
      new TextEncoder().encode(JSON.stringify(components))
    );

    // Convert hash to hex string
    const hashArray = Array.from(new Uint8Array(hash));
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');

    return {
      hash: hashHex,
      components
    };
  } catch (error) {
    console.error('Failed to generate fingerprint:', error);
    return null;
  }
};

// Final API composition
const api = {
  ...axiosInstance,
  auth,
  user,
  messages,
  triggers,
  security,
  generateFingerprint
};

export default api;


