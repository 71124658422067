import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { 
  HeartIcon, 
  BriefcaseIcon, 
  KeyIcon, 
  DocumentTextIcon,
  UserGroupIcon,
  CurrencyDollarIcon
} from '@heroicons/react/24/outline';

const UseCases = () => {
  const [activeCase, setActiveCase] = useState('personal');

  const cases = {
    personal: {
      title: "Personal Legacy",
      description: "Share your life story, wisdom, and final wishes with loved ones",
      icon: HeartIcon,
      scenarios: [
        {
          title: "Messages to Family",
          description: "Record heartfelt messages and life advice for your children and grandchildren",
          examples: [
            "Birthday messages for future milestones",
            "Life advice and family history",
            "Personal stories and memories",
            "Final wishes and farewells"
          ],
          stats: "Most popular use case among families"
        },
        {
          title: "Digital Asset Transfer",
          description: "Ensure loved ones can access important digital accounts and assets",
          examples: [
            "Password manager master key",
            "Cryptocurrency wallet access",
            "Social media account credentials",
            "Cloud storage access"
          ],
          stats: "Secure transfer of digital assets"
        }
      ]
    },
    business: {
      title: "Business Continuity",
      description: "Ensure smooth business transition and protect company assets",
      icon: BriefcaseIcon,
      scenarios: [
        {
          title: "Succession Planning",
          description: "Comprehensive handover instructions for business continuity",
          examples: [
            "Business operation procedures",
            "Client relationship details",
            "Vendor contracts and contacts",
            "Employee information"
          ],
          stats: "Used by 500+ businesses"
        },
        {
          title: "Asset Management",
          description: "Secure transfer of business assets and intellectual property",
          examples: [
            "Financial account access",
            "Legal documentation",
            "Trade secrets and patents",
            "Software licenses"
          ],
          stats: "Enterprise-grade security"
        }
      ]
    },
    legal: {
      title: "Legal & Estate",
      description: "Complement your legal arrangements with crucial information",
      icon: DocumentTextIcon,
      scenarios: [
        {
          title: "Estate Documentation",
          description: "Crucial information to support your legal will and testament",
          examples: [
            "Location of important documents",
            "Insurance policy details",
            "Property inventory lists",
            "Debt and asset information"
          ],
          stats: "Recommended by estate planners"
        },
        {
          title: "Financial Instructions",
          description: "Detailed guidance for handling financial matters",
          examples: [
            "Investment account details",
            "Tax document locations",
            "Cryptocurrency instructions",
            "Trust fund information"
          ],
          stats: "Secure financial transition"
        }
      ]
    }
  };

  return (
    <section className="relative py-24 overflow-hidden">
      {/* Updated background */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-gradient-to-b from-blue-50 via-white to-gray-50 opacity-80" />
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_top_left,theme(colors.blue.100),transparent_50%)]" />
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_bottom_right,theme(colors.gray.100),transparent_50%)]" />
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-96 h-96 bg-blue-100 rounded-full filter blur-3xl opacity-20" />
      </div>

      <div className="relative max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <motion.span
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="inline-block px-4 py-2 rounded-full bg-blue-100 text-blue-700 text-sm font-medium mb-4"
          >
            Real-World Applications
          </motion.span>

          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-4xl font-bold text-gray-900 mb-4"
          >
            How People Use Cryonote
          </motion.h2>

          <motion.p
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-xl text-gray-600 max-w-2xl mx-auto"
          >
            Discover how individuals and businesses protect their legacy and ensure continuity
          </motion.p>
        </div>

        {/* Updated Case Type Selection */}
        <div className="flex justify-center mb-16">
          <div className="inline-flex flex-col sm:flex-row p-2 bg-white rounded-xl shadow-lg">
            {Object.entries(cases).map(([key, value]) => (
              <button
                key={key}
                onClick={() => setActiveCase(key)}
                className={`flex items-center px-6 py-3 rounded-lg text-sm font-medium transition-all duration-200 ${
                  activeCase === key
                    ? 'bg-blue-600 text-white shadow-sm'
                    : 'text-gray-600 hover:text-gray-900'
                } w-full sm:w-auto justify-center sm:justify-start mb-2 sm:mb-0`}
              >
                <value.icon className="h-5 w-5 mr-2" />
                {value.title}
              </button>
            ))}
          </div>
        </div>

        {/* Scenario Cards */}
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="grid md:grid-cols-2 gap-8"
        >
          {cases[activeCase].scenarios.map((scenario, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.2 }}
              className="group relative bg-white rounded-2xl p-8 shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1"
            >
              <div className="absolute inset-0 rounded-2xl bg-gradient-to-br from-blue-50 to-white opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
              
              <div className="relative">
                <h3 className="text-2xl font-bold text-gray-900 mb-4">
                  {scenario.title}
                </h3>
                
                <p className="text-gray-600 mb-6">
                  {scenario.description}
                </p>

                <ul className="space-y-3 mb-8">
                  {scenario.examples.map((example, idx) => (
                    <li key={idx} className="flex items-center text-gray-600">
                      <svg className="h-5 w-5 text-blue-500 mr-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                      {example}
                    </li>
                  ))}
                </ul>

                <div className="flex items-center justify-between">
                  <span className="text-sm font-medium text-blue-600">
                    {scenario.stats}
                  </span>
                  <a 
                    href="#learn-more" 
                    className="inline-flex items-center text-sm font-medium text-blue-600 hover:text-blue-700"
                  >
                    Learn more
                    <svg className="ml-2 w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                    </svg>
                  </a>
                </div>
              </div>
            </motion.div>
          ))}
        </motion.div>

        {/* Call to Action */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="mt-16 text-center"
        >
          <div className="inline-block p-4 bg-blue-50 rounded-2xl mb-8">
            <p className="text-blue-700 font-medium">
            Secure Your Legacy with Cryonote—Peace of Mind Starts Here
            </p>
          </div>
          <div>
            <a
              href="/signup"
              className="inline-flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 transform hover:scale-105 transition-all duration-200 shadow-lg hover:shadow-xl"
            >
              Start Securing Your Legacy
              <span className="ml-2">→</span>
            </a>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default UseCases;
