import React from 'react';
import {
  ShieldCheckIcon,
  LockClosedIcon,
  KeyIcon,
  ServerIcon,
  UserGroupIcon,
  ChartBarIcon,
  ExclamationTriangleIcon,
  DocumentIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const Security = () => {
  const sections = [
    { id: 'introduction', title: '1. Introduction' },
    { id: 'architecture', title: '2. Security Architecture' },
    { id: 'levels', title: '3. Security Levels' },
    { id: 'commitment', title: '4. Our Commitment to Security' },
    { id: 'faq', title: '5. Frequently Asked Questions' },
    { id: 'canary', title: '6. Canary Notice' },
    { id: 'get-started', title: '7. Ready to Secure Your Legacy?' },
  ];

  const scrollToSection = (sectionId) => {
    document.getElementById(sectionId).scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <Navbar />

      <main className="pt-16 pb-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="lg:grid lg:grid-cols-12 lg:gap-8">
            {/* Sidebar Navigation */}
            <div className="hidden lg:block lg:col-span-3">
              <nav className="sticky top-20 divide-y divide-gray-200">
                <div className="pb-8">
                  <h2 className="text-sm font-semibold text-gray-900">On this page</h2>
                  <ul className="mt-4 space-y-2">
                    {sections.map((section) => (
                      <li key={section.id}>
                        <button
                          onClick={() => scrollToSection(section.id)}
                          className="group flex items-center text-sm text-gray-600 hover:text-gray-900"
                        >
                          <ChevronRightIcon className="h-4 w-4 mr-2 text-gray-400 group-hover:text-gray-900" />
                          {section.title}
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              </nav>
            </div>

            {/* Main Content */}
            <div className="lg:col-span-9">
              {/* Header Section */}
              <div className="bg-white rounded-xl shadow-sm p-8 mb-8">
                <h1 className="text-3xl font-bold text-gray-900 mb-4">
                  Uncompromising <span className="text-blue-600">Security</span> for Your Digital Legacy
                </h1>
                <p className="text-gray-600">
                  At Cryonote, we've implemented cutting-edge cryptographic protocols to ensure your digital legacy remains private, secure, and accessible only to those you choose.
                </p>
              </div>

              {/* Content Sections */}
              <div id="security-content" className="bg-white rounded-xl shadow-sm p-8 max-w-none">
                {/* Introduction */}
                <section id="introduction" className="mb-8">
                  <h2 className="text-2xl font-bold mb-4">1. Introduction</h2>
                  <p>
                    Security is at the core of everything we do at Cryonote. We understand the importance of protecting your most sensitive information and have designed our platform with multiple layers of security to ensure your data remains confidential and secure.
                  </p>
                </section>

                {/* Security Architecture */}
                <section id="architecture" className="mb-8">
                  <h2 className="text-2xl font-bold mb-4">2. Security Architecture</h2>
                  <p>
                    Our security architecture is built upon industry-leading standards and best practices. Below are some key components of our security framework:
                  </p>
                  <div className="grid gap-8 md:grid-cols-2 mt-6">
                    {/* Feature 1 */}
                    <div className="flex">
                      <ShieldCheckIcon className="h-8 w-8 text-blue-600 flex-shrink-0" />
                      <div className="ml-4">
                        <h3 className="text-xl font-semibold text-gray-900">Client-Side Encryption</h3>
                        <p className="text-gray-600 mt-2">
                          All sensitive data is encrypted on your device using AES-256 before transmission, ensuring end-to-end encryption.
                        </p>
                      </div>
                    </div>
                    {/* Feature 2 */}
                    <div className="flex">
                      <LockClosedIcon className="h-8 w-8 text-blue-600 flex-shrink-0" />
                      <div className="ml-4">
                        <h3 className="text-xl font-semibold text-gray-900">Zero-Knowledge Architecture</h3>
                        <p className="text-gray-600 mt-2">
                          Your encryption keys never leave your device. Our servers only store encrypted data, making it impossible for us to access your information.
                        </p>
                      </div>
                    </div>
                    {/* Feature 3 */}
                    <div className="flex">
                      <KeyIcon className="h-8 w-8 text-blue-600 flex-shrink-0" />
                      <div className="ml-4">
                        <h3 className="text-xl font-semibold text-gray-900">Advanced Key Derivation</h3>
                        <p className="text-gray-600 mt-2">
                          We use PBKDF2 with 100,000 iterations and SHA-256 for key derivation, protecting against brute-force attacks.
                        </p>
                      </div>
                    </div>
                    {/* Feature 4 */}
                    <div className="flex">
                      <ServerIcon className="h-8 w-8 text-blue-600 flex-shrink-0" />
                      <div className="ml-4">
                        <h3 className="text-xl font-semibold text-gray-900">Secure Data Storage</h3>
                        <p className="text-gray-600 mt-2">
                          All data at rest is encrypted using AES-256 in GCM mode, with unique keys for each piece of data, ensuring multiple layers of protection.
                        </p>
                      </div>
                    </div>
                    {/* Feature 5 */}
                    <div className="flex">
                      <UserGroupIcon className="h-8 w-8 text-blue-600 flex-shrink-0" />
                      <div className="ml-4">
                        <h3 className="text-xl font-semibold text-gray-900">Granular Access Controls</h3>
                        <p className="text-gray-600 mt-2">
                          Define precise access rules for your digital legacy, specifying which trusted contacts can access what information and under what circumstances.
                        </p>
                      </div>
                    </div>
                    {/* Feature 6 */}
                    <div className="flex">
                      <ChartBarIcon className="h-8 w-8 text-blue-600 flex-shrink-0" />
                      <div className="ml-4">
                        <h3 className="text-xl font-semibold text-gray-900">Continuous Security Monitoring</h3>
                        <p className="text-gray-600 mt-2">
                          We employ advanced intrusion detection systems and conduct regular security audits to maintain the highest level of protection.
                        </p>
                      </div>
                    </div>
                    {/* Feature 7 */}
                    <div className="flex">
                      <DocumentIcon className="h-8 w-8 text-blue-600 flex-shrink-0" />
                      <div className="ml-4">
                        <h3 className="text-xl font-semibold text-gray-900">Encrypted File Attachments</h3>
                        <p className="text-gray-600 mt-2">
                          We also encrypt all file attachments with the same encryption method you choose for your messages, ensuring that your files are protected both in transit and at rest. 
                        </p>
                      </div>
                    </div>
                  </div>
                </section>

                {/* Security Levels */}
                <section id="levels" className="mb-8">
                  <h2 className="text-2xl font-bold mb-4">3. Security Levels</h2>
                  {/* Standard Security */}
                  <div className="border rounded-lg p-6 mb-6">
                    <h3 className="text-xl font-bold text-gray-900 mb-4">Standard Security</h3>
                    <p className="text-gray-600 mb-4">
                      Robust protection for non-sensitive information. In Standard Security mode, files and message content are encrypted server-side. Please note that Standard Security does not offer zero-knowledge encryption; our servers handle the encryption process.
                    </p>
                    <ul className="list-disc list-inside text-gray-600 space-y-2">
                      <li>Server-side AES-256 encryption</li>
                      <li>Secure file storage with server-side encryption</li>
                      <li>TLS 1.3 for data in transit</li>
                      <li>Two-factor authentication</li>
                      <li>Regular security audits</li>
                    </ul>
                  </div>
                  {/* Enhanced Security */}
                  <div className="border rounded-lg p-6">
                    <h3 className="text-xl font-bold text-gray-900 mb-4">Enhanced Security</h3>
                    <p className="text-gray-600 mb-4">
                      Maximum privacy with local encryption for sensitive data. In Enhanced Security mode, we employ zero-knowledge encryption; files and message content are encrypted on your device, and we never have access to your unencrypted data.
                    </p>
                    <ul className="list-disc list-inside text-gray-600 space-y-2">
                      <li>Client-side AES-256 encryption</li>
                      <li>Zero-knowledge architecture</li>
                      <li>End-to-end encryption for all data, including file attachments</li>
                      <li>Local key generation and management</li>
                      <li>Advanced access controls</li>
                      <li>Optional hardware security module integration</li>
                    </ul>
                  </div>
                </section>

                {/* Our Commitment to Security */}
                <section id="commitment" className="mb-8">
                  <h2 className="text-2xl font-bold mb-4">4. Our Commitment to Security</h2>
                  <p>
                    Security is ingrained in every aspect of our operations:
                  </p>
                  <ul className="list-disc list-inside text-gray-600 space-y-2 mt-4">
                    <li>Regular third-party security audits and penetration testing</li>
                    <li>Continuous monitoring and rapid response to emerging threats</li>
                    <li>Strict adherence to industry best practices and standards (NIST, ISO 27001)</li>
                    <li>Ongoing employee security training and background checks</li>
                    <li>Transparent bug bounty program to encourage responsible disclosure</li>
                  </ul>
                </section>

                {/* Frequently Asked Questions */}
                <section id="faq" className="mb-8">
                  <h2 className="text-2xl font-bold mb-4">5. Frequently Asked Questions</h2>
                  {/* Question 1 */}
                  <div className="mb-6">
                    <h3 className="text-xl font-semibold text-gray-900 mb-2">
                      How does Cryonote protect my data?
                    </h3>
                    <p className="text-gray-600">
                      We use end-to-end encryption, meaning your data is encrypted on your device before it's sent to our servers. With Enhanced Security, we never have access to your unencrypted data or encryption keys. All file attachments are also encrypted to ensure complete protection.
                    </p>
                  </div>
                  {/* Question 2 */}
                  <div className="mb-6">
                    <h3 className="text-xl font-semibold text-gray-900 mb-2">
                      What's the difference between Standard and Enhanced Security?
                    </h3>
                    <p className="text-gray-600">
                      Standard Security provides robust protection with server-side encryption but does not offer zero-knowledge encryption. Files and message content are encrypted on our servers. Enhanced Security offers maximum privacy with client-side encryption and a zero-knowledge architecture, meaning we have no access to your unencrypted data.
                    </p>
                  </div>
                  {/* Question 3 */}
                  <div className="mb-6">
                    <h3 className="text-xl font-semibold text-gray-900 mb-2">
                      How does Cryonote handle government requests for data?
                    </h3>
                    <p className="text-gray-600">
                      We comply with valid legal requests, but due to our zero-knowledge architecture with Enhanced Security, we can only provide encrypted data. We cannot decrypt your data or provide your encryption keys.
                    </p>
                  </div>
                </section>

                {/* Canary Notice */}
                <section id="canary" className="mb-8">
                  <h2 className="text-2xl font-bold mb-4">6. Canary Notice</h2>
                  <div className="bg-yellow-50 border-l-4 border-yellow-400 p-6 rounded-r-lg">
                    <div className="flex">
                      <ExclamationTriangleIcon className="h-6 w-6 text-yellow-400 mr-4" />
                      <div>
                        <p className="text-gray-700">
                          As of {new Date().toLocaleDateString()}, Cryonote has never received a National Security Letter, an order under the Foreign Intelligence Surveillance Act, or any other classified request for user information.
                        </p>
                        <p className="text-sm text-gray-600 mt-2">
                          If we ever receive such a request, we will update this notice to remove this statement. The absence of this statement in future versions of this page should be interpreted as an indication that we have received such a request.
                        </p>
                      </div>
                    </div>
                  </div>
                </section>

                {/* Ready to Secure Your Legacy */}
                <section id="get-started" className="text-center">
                  <h2 className="text-2xl font-bold text-gray-900 mb-4">7. Ready to Secure Your Legacy?</h2>
                  <p className="text-gray-600 mb-6">
                    Join Cryonote today and experience unparalleled protection for your digital legacy.
                  </p>
                  <a
                    href="/signup"
                    className="inline-block px-6 py-3 bg-blue-600 text-white font-medium rounded-lg hover:bg-blue-700 transition-colors"
                  >
                    Get Started
                  </a>
                </section>
              </div>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default Security;
