import React from 'react';
import {
  ShieldCheckIcon,
  KeyIcon,
  UserGroupIcon,
  BuildingOfficeIcon,
  ArrowPathIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const AboutPage = () => {
  const sections = [
    { id: 'our-story', title: '1. Our Story' },
    { id: 'mission', title: '2. Our Mission' },
    { id: 'values', title: '3. Our Values' },
    { id: 'use-cases', title: '4. Use Cases' },
    { id: 'features', title: '5. Key Features' },
    { id: 'get-started', title: '6. Ready to Secure Your Legacy?' },
  ];

  const scrollToSection = (sectionId) => {
    document.getElementById(sectionId).scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <Navbar />

      <main className="pt-16 pb-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="lg:grid lg:grid-cols-12 lg:gap-8">
            {/* Sidebar Navigation */}
            <div className="hidden lg:block lg:col-span-3">
              <nav className="sticky top-20 divide-y divide-gray-200">
                <div className="pb-8">
                  <h2 className="text-sm font-semibold text-gray-900">On this page</h2>
                  <ul className="mt-4 space-y-2">
                    {sections.map((section) => (
                      <li key={section.id}>
                        <button
                          onClick={() => scrollToSection(section.id)}
                          className="group flex items-center text-sm text-gray-600 hover:text-gray-900"
                        >
                          <ChevronRightIcon className="h-4 w-4 mr-2 text-gray-400 group-hover:text-gray-900" />
                          {section.title}
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              </nav>
            </div>

            {/* Main Content */}
            <div className="lg:col-span-9">
              {/* Header Section */}
              <div className="bg-white rounded-xl shadow-sm p-8 mb-8">
                <h1 className="text-3xl font-bold text-gray-900 mb-4">About Cryonote</h1>
                <p className="text-gray-600">
                  Securing the transfer of digital assets and important information for individuals and businesses.
                </p>
              </div>

              {/* Content Sections */}
              <div id="about-content" className="bg-white rounded-xl shadow-sm p-8 max-w-none">
                {/* Our Story */}
                <section id="our-story" className="mb-8">
                  <h2 className="text-2xl font-bold mb-4">1. Our Story</h2>
                  <p>
                  In today's digital age, ensuring that your important messages and digital assets reach the right people is more crucial than ever. Cryonote was founded to address this need by providing a secure platform for posthumous message delivery that simplifies the entire setup process. By choosing straightforward delivery options, we've eliminated the need for your recipients to sign up or navigate complex app structures that could change over time. This empowers you to remain in full control of how and what gets delivered, making the transmission of your digital legacy both simple and reliable.
                  </p>
                </section>

                {/* Our Mission */}
                <section id="mission" className="mb-8">
                  <h2 className="text-2xl font-bold mb-4">2. Our Mission</h2>
                  <p>
                    Our mission is to offer a reliable and secure solution for transmitting sensitive information when it's needed most. We are committed to upholding the highest standards of security and privacy, ensuring that your digital legacy is preserved and protected.
                  </p>
                </section>

                {/* Our Values */}
                <section id="values" className="mb-8">
                  <h2 className="text-2xl font-bold mb-4">3. Our Values</h2>
                  <ul className="list-disc list-inside text-gray-600 space-y-2 mt-4">
                    <li>
                      <strong>Security First:</strong> Prioritizing the protection of your information through advanced encryption and security protocols.
                    </li>
                    <li>
                      <strong>Reliability:</strong> Ensuring consistent and dependable service for seamless message delivery.
                    </li>
                    <li>
                      <strong>Simplicity:</strong> Offering an intuitive interface that makes managing your digital legacy straightforward.
                    </li>
                    <li>
                      <strong>Privacy:</strong> Upholding strict privacy standards to keep your data confidential.
                    </li>
                  </ul>
                </section>

                {/* Use Cases */}
                <section id="use-cases" className="mb-8">
                  <h2 className="text-2xl font-bold mb-4">4. Use Cases</h2>
                  {/* Individuals */}
                  <div className="mb-6">
                    <div className="flex items-center mb-4">
                      <UserGroupIcon className="h-6 w-6 text-blue-600 mr-2" />
                      <h3 className="text-xl font-semibold text-gray-900">For Individuals</h3>
                    </div>
                    <ul className="list-disc list-inside text-gray-600 space-y-2">
                      <li>Personal messages and final wishes</li>
                      <li>Digital asset transfer instructions</li>
                      <li>Password and account access management</li>
                      <li>Family history and important documents</li>
                    </ul>
                  </div>
                  {/* Businesses */}
                  <div>
                    <div className="flex items-center mb-4">
                      <BuildingOfficeIcon className="h-6 w-6 text-blue-600 mr-2" />
                      <h3 className="text-xl font-semibold text-gray-900">For Businesses</h3>
                    </div>
                    <ul className="list-disc list-inside text-gray-600 space-y-2">
                      <li>Business continuity planning</li>
                      <li>Succession documentation</li>
                      <li>Critical system access protocols</li>
                      <li>Client relationship management</li>
                    </ul>
                  </div>
                </section>

                {/* Key Features */}
                <section id="features" className="mb-8">
                  <h2 className="text-2xl font-bold mb-4">5. Key Features</h2>
                  <div className="grid gap-8 md:grid-cols-2">
                    {/* Feature 1 */}
                    <div className="flex">
                      <ShieldCheckIcon className="h-8 w-8 text-blue-600 flex-shrink-0" />
                      <div className="ml-4">
                        <h3 className="text-xl font-semibold text-gray-900">Advanced Security</h3>
                        <p className="text-gray-600 mt-2">
                          Utilizing end-to-end encryption to protect your sensitive information.
                        </p>
                      </div>
                    </div>
                    {/* Feature 2 */}
                    <div className="flex">
                      <ArrowPathIcon className="h-8 w-8 text-blue-600 flex-shrink-0" />
                      <div className="ml-4">
                        <h3 className="text-xl font-semibold text-gray-900">Reliability</h3>
                        <p className="text-gray-600 mt-2">
                          Multiple redundancies and backups ensure your messages are delivered as intended.
                        </p>
                      </div>
                    </div>
                    {/* Feature 3 */}
                    <div className="flex">
                      <KeyIcon className="h-8 w-8 text-blue-600 flex-shrink-0" />
                      <div className="ml-4">
                        <h3 className="text-xl font-semibold text-gray-900">User-Friendly Interface</h3>
                        <p className="text-gray-600 mt-2">
                          An intuitive platform that makes managing your digital legacy straightforward.
                        </p>
                      </div>
                    </div>
                    {/* Feature 4 */}
                    <div className="flex">
                      <ShieldCheckIcon className="h-8 w-8 text-blue-600 flex-shrink-0" />
                      <div className="ml-4">
                        <h3 className="text-xl font-semibold text-gray-900">Privacy Assurance</h3>
                        <p className="text-gray-600 mt-2">
                          Strict privacy policies to keep your data confidential and secure.
                        </p>
                      </div>
                    </div>
                  </div>
                </section>

                {/* Ready to Secure Your Legacy */}
                <section id="get-started" className="text-center">
                  <h2 className="text-2xl font-bold text-gray-900 mb-4">6. Ready to Secure Your Legacy?</h2>
                  <p className="text-gray-600 mb-6">
                    Join Cryonote today and experience unparalleled protection for your important messages and assets.
                  </p>
                  <a
                    href="/signup"
                    className="inline-block px-6 py-3 bg-blue-600 text-white font-medium rounded-lg hover:bg-blue-700 transition-colors"
                  >
                    Get Started
                  </a>
                </section>
              </div>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default AboutPage;
