import React from 'react';
import { 
  DocumentArrowDownIcon, 
  ShieldCheckIcon, 
  ScaleIcon,
  ClockIcon,
  DocumentCheckIcon,
  PrinterIcon,
  ArrowTopRightOnSquareIcon,
  ChevronRightIcon
} from '@heroicons/react/24/outline';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const TosPage = () => {
  const lastUpdated = "November 16, 2024";
  const effectiveDate = "December 1, 2024";
  const version = "2.1.0";

  // Table of contents sections for quick navigation
  const sections = [
    { id: 'introduction', title: '1. Introduction' },
    { id: 'service-description', title: '2. Service Description' },
    { id: 'account-registration', title: '3. Account Registration and Security' },
    { id: 'message-creation', title: '4. Message Creation and Delivery' },
    { id: 'privacy-security', title: '5. Privacy and Data Security' },
    { id: 'trusted-contacts', title: '6. Trusted Contacts' },
    { id: 'subscription', title: '7. Subscription and Payments' },
    { id: 'service-availability', title: '8. Service Availability' },
    { id: 'termination', title: '9. Termination' },
    { id: 'intellectual-property', title: '10. Intellectual Property' },
    { id: 'liability', title: '11. Liability and Indemnification' },
    { id: 'dispute-resolution', title: '12. Dispute Resolution' },
    { id: 'changes', title: '13. Changes to Terms' },
    { id: 'contact', title: '14. Contact Information' },
    { id: 'severability', title: '15. Severability' }
  ];

  const downloadTOS = () => {
    const element = document.createElement('a');
    const file = new Blob(
      [document.getElementById('tos-content').innerText],
      { type: 'text/plain' }
    );
    element.href = URL.createObjectURL(file);
    element.download = `Cryonote-Terms-of-Service-v${version}.txt`;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const printTOS = () => {
    window.print();
  };

  const scrollToSection = (sectionId) => {
    document.getElementById(sectionId).scrollIntoView({ 
      behavior: 'smooth',
      block: 'start'
    });
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <Navbar />
      
      <main className="pt-16 pb-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="lg:grid lg:grid-cols-12 lg:gap-8">
            {/* Sidebar Navigation */}
            <div className="hidden lg:block lg:col-span-3">
              <nav className="sticky top-20 divide-y divide-gray-200">
                <div className="pb-8">
                  <h2 className="text-sm font-semibold text-gray-900">On this page</h2>
                  <ul className="mt-4 space-y-2">
                    {sections.map((section) => (
                      <li key={section.id}>
                        <button
                          onClick={() => scrollToSection(section.id)}
                          className="group flex items-center text-sm text-gray-600 hover:text-gray-900"
                        >
                          <ChevronRightIcon className="h-4 w-4 mr-2 text-gray-400 group-hover:text-gray-900" />
                          {section.title}
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              </nav>
            </div>

            {/* Main Content */}
            <div className="lg:col-span-9">
              {/* Header Section */}
              <div className="bg-white rounded-xl shadow-sm p-8 mb-8">
                <div className="flex justify-between items-start">
                  <div>
                    <h1 className="text-3xl font-bold text-gray-900">Terms of Service</h1>
                    <div className="mt-2 space-y-1">
                      <p className="text-sm text-gray-500">Version {version}</p>
                      <p className="text-sm text-gray-500">Last Updated: {lastUpdated}</p>
                      <p className="text-sm text-gray-500">Effective Date: {effectiveDate}</p>
                    </div>
                  </div>
                  <div className="flex space-x-4">
                    <button
                      onClick={downloadTOS}
                      className="flex items-center px-4 py-2 border border-gray-300 rounded-lg text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      <DocumentArrowDownIcon className="h-5 w-5 mr-2 text-gray-500" />
                      Download
                    </button>
                    <button
                      onClick={printTOS}
                      className="flex items-center px-4 py-2 border border-gray-300 rounded-lg text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      <PrinterIcon className="h-5 w-5 mr-2 text-gray-500" />
                      Print
                    </button>
                  </div>
                </div>

                {/* Compliance Badges */}
                <div className="mt-6 flex flex-wrap gap-4">
                  <div className="flex items-center px-3 py-1 bg-blue-50 rounded-full">
                    <ShieldCheckIcon className="h-4 w-4 text-blue-600 mr-2" />
                    <span className="text-xs font-medium text-blue-600">ISO 27001 Guidelines Followed</span>
                  </div>
                  <div className="flex items-center px-3 py-1 bg-green-50 rounded-full">
                    <ScaleIcon className="h-4 w-4 text-green-600 mr-2" />
                    <span className="text-xs font-medium text-green-600">GDPR Compliant</span>
                  </div>
                  <div className="flex items-center px-3 py-1 bg-purple-50 rounded-full">
                    <DocumentCheckIcon className="h-4 w-4 text-purple-600 mr-2" />
                    <span className="text-xs font-medium text-purple-600">CCPA Compliant</span>
                  </div>
                </div>

                {/* Warning Banner */}
                <div className="mt-6 bg-yellow-50 border-l-4 border-yellow-400 p-4 rounded-r-lg">
                  <div className="flex">
                    <ClockIcon className="h-6 w-6 text-yellow-400 mr-3" />
                    <div>
                      <p className="text-sm text-yellow-700">
                        Please read these Terms of Service carefully before using Cryonote. By using the Service, you agree to be bound by these terms.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Terms Content */}
              <div id="tos-content" className="bg-white rounded-xl shadow-sm p-8 max-w-none">
                {/* Introduction */}
                <section id="introduction" className="mb-8">
                  <h2 className="text-2xl font-bold mb-4">1. Introduction</h2>
                  <p>
                    Welcome to Cryonote ("Service", "we", "us", or "our"). By accessing or using our digital legacy planning and message delivery service, you agree to be bound by these Terms of Service ("Terms"). Please read them carefully.
                  </p>
                </section>

                {/* Service Description */}
                <section id="service-description" className="mb-8">
                  <h2 className="text-2xl font-bold mb-4">2. Service Description</h2>
                  <p>
                    Cryonote provides a digital legacy planning platform that allows users to:
                  </p>
                  <ul className="list-disc list-inside ml-4">
                    <li>Create and store encrypted messages for future delivery</li>
                    <li>Set up automated check-in systems</li>
                    <li>Designate trusted contacts</li>
                    <li>Configure message delivery triggers</li>
                    <li>Store and transmit digital assets securely</li>
                  </ul>
                </section>

                {/* Account Registration and Security */}
                <section id="account-registration" className="mb-8">
                  <h2 className="text-2xl font-bold mb-4">3. Account Registration and Security</h2>
                  <h3 className="text-xl font-semibold mb-2">3.1 Account Creation</h3>
                  <ul className="list-disc list-inside ml-4">
                    <li>You must be at least 18 years old to use the Service</li>
                    <li>You must provide accurate, current, and complete information</li>
                    <li>You are responsible for maintaining the confidentiality of your account credentials</li>
                  </ul>

                  <h3 className="text-xl font-semibold mt-4 mb-2">3.2 Security Obligations</h3>
                  <ul className="list-disc list-inside ml-4">
                    <li>You must use reasonable security measures to protect your account</li>
                    <li>You must promptly notify us of any unauthorized access</li>
                    <li>You are responsible for all activities under your account</li>
                  </ul>
                </section>

                {/* Message Creation and Delivery */}
                <section id="message-creation" className="mb-8">
                  <h2 className="text-2xl font-bold mb-4">4. Message Creation and Delivery</h2>
                  <h3 className="text-xl font-semibold mb-2">4.1 Content Responsibility</h3>
                  <ul className="list-disc list-inside ml-4">
                    <li>You retain ownership of all content you create</li>
                    <li>You must have the right to share any content you upload</li>
                    <li>You are solely responsible for the accuracy and appropriateness of your messages</li>
                  </ul>

                  <h3 className="text-xl font-semibold mt-4 mb-2">4.2 Delivery Mechanisms</h3>
                  <ul className="list-disc list-inside ml-4">
                    <li>We do not guarantee exact delivery timing</li>
                    <li>Multiple verification steps are in place to prevent premature delivery</li>
                    <li>We are not responsible for undelivered messages due to incorrect recipient information</li>
                  </ul>
                </section>

                {/* Privacy and Data Security */}
                <section id="privacy-security" className="mb-8">
                  <h2 className="text-2xl font-bold mb-4">5. Privacy and Data Security</h2>
                  <h3 className="text-xl font-semibold mb-2">5.1 Encryption</h3>
                  <ul className="list-disc list-inside ml-4">
                    <li>Messages are encrypted using industry-standard protocols</li>
                    <li>Enhanced security mode provides client-side encryption</li>
                    <li>We cannot access the content of enhanced security messages</li>
                  </ul>

                  <h3 className="text-xl font-semibold mt-4 mb-2">5.2 Data Storage</h3>
                  <ul className="list-disc list-inside ml-4">
                    <li>Data is stored in secure, redundant systems</li>
                    <li>We implement multiple security layers to protect your information</li>
                    <li>Regular security audits are conducted</li>
                  </ul>
                </section>

                {/* Trusted Contacts */}
                <section id="trusted-contacts" className="mb-8">
                  <h2 className="text-2xl font-bold mb-4">6. Trusted Contacts</h2>
                  <h3 className="text-xl font-semibold mb-2">6.1 Designation</h3>
                  <ul className="list-disc list-inside ml-4">
                    <li>You are responsible for designating appropriate trusted contacts</li>
                    <li>Trusted contacts must verify their status</li>
                    <li>You must inform trusted contacts of their responsibilities</li>
                  </ul>

                  <h3 className="text-xl font-semibold mt-4 mb-2">6.2 Consensus System</h3>
                  <ul className="list-disc list-inside ml-4">
                    <li>Multiple trusted contacts may be required for message delivery</li>
                    <li>We are not responsible for trusted contacts' decisions</li>
                    <li>You can modify trusted contact settings at any time</li>
                  </ul>
                </section>

                {/* Subscription and Payments */}
                <section id="subscription" className="mb-8">
                  <h2 className="text-2xl font-bold mb-4">7. Subscription and Payments</h2>
                  <h3 className="text-xl font-semibold mb-2">7.1 Pricing</h3>
                  <ul className="list-disc list-inside ml-4">
                    <li>Subscription fees are charged according to your selected plan</li>
                    <li>All fees are non-refundable unless required by law</li>
                    <li>We may modify pricing with 30 days notice</li>
                  </ul>

                  <h3 className="text-xl font-semibold mt-4 mb-2">7.2 Billing</h3>
                  <ul className="list-disc list-inside ml-4">
                    <li>Recurring billing occurs every six months</li>
                    <li>You authorize us to charge your payment method</li>
                    <li>Failed payments may result in service interruption</li>
                  </ul>
                </section>

                {/* Service Availability */}
                <section id="service-availability" className="mb-8">
                  <h2 className="text-2xl font-bold mb-4">8. Service Availability</h2>
                  <h3 className="text-xl font-semibold mb-2">8.1 Uptime</h3>
                  <ul className="list-disc list-inside ml-4">
                    <li>We strive for 99.9% uptime</li>
                    <li>Scheduled maintenance will be announced in advance</li>
                    <li>We are not liable for service interruptions beyond our control</li>
                  </ul>

                  <h3 className="text-xl font-semibold mt-4 mb-2">8.2 Modifications</h3>
                  <ul className="list-disc list-inside ml-4">
                    <li>We may modify the Service with reasonable notice</li>
                    <li>Critical security updates may be implemented immediately</li>
                    <li>We will maintain backward compatibility when possible</li>
                  </ul>
                </section>

                {/* Termination */}
                <section id="termination" className="mb-8">
                  <h2 className="text-2xl font-bold mb-4">9. Termination</h2>
                  <h3 className="text-xl font-semibold mb-2">9.1 User Termination</h3>
                  <ul className="list-disc list-inside ml-4">
                    <li>You may terminate your account at any time</li>
                    <li>Termination does not affect scheduled message delivery</li>
                    <li>No refunds for partial subscription periods</li>
                  </ul>

                  <h3 className="text-xl font-semibold mt-4 mb-2">9.2 Service Termination</h3>
                  <ul className="list-disc list-inside ml-4">
                    <li>We may terminate accounts for Terms violations</li>
                    <li>We will provide reasonable notice before termination</li>
                    <li>We will assist in data export upon termination</li>
                  </ul>
                </section>

                {/* Intellectual Property */}
                <section id="intellectual-property" className="mb-8">
                  <h2 className="text-2xl font-bold mb-4">10. Intellectual Property</h2>
                  <h3 className="text-xl font-semibold mb-2">10.1 Service Rights</h3>
                  <ul className="list-disc list-inside ml-4">
                    <li>The Service software and technology are our property</li>
                    <li>You receive a limited license to use the Service</li>
                    <li>No rights are granted beyond explicit Terms</li>
                  </ul>

                  <h3 className="text-xl font-semibold mt-4 mb-2">10.2 User Content</h3>
                  <ul className="list-disc list-inside ml-4">
                    <li>You retain rights to your content</li>
                    <li>You grant us limited rights to provide the Service</li>
                    <li>We will not use your content for marketing without consent</li>
                  </ul>
                </section>

                {/* Liability and Indemnification */}
                <section id="liability" className="mb-8">
                  <h2 className="text-2xl font-bold mb-4">11. Liability and Indemnification</h2>
                  <h3 className="text-xl font-semibold mb-2">11.1 Limitation of Liability</h3>
                  <ul className="list-disc list-inside ml-4">
                    <li>We are not liable for indirect or consequential damages</li>
                    <li>Our liability is limited to your subscription fees</li>
                    <li>Force majeure events are excluded from liability</li>
                  </ul>

                  <h3 className="text-xl font-semibold mt-4 mb-2">11.2 Indemnification</h3>
                  <ul className="list-disc list-inside ml-4">
                    <li>You agree to indemnify us against claims from your use</li>
                    <li>Indemnification includes reasonable legal fees</li>
                    <li>We will notify you of relevant claims</li>
                  </ul>
                </section>

                {/* Dispute Resolution */}
                <section id="dispute-resolution" className="mb-8">
                  <h2 className="text-2xl font-bold mb-4">12. Dispute Resolution</h2>
                  <h3 className="text-xl font-semibold mb-2">12.1 Governing Law</h3>
                  <ul className="list-disc list-inside ml-4">
                    <li>These Terms are governed by the laws of [Your Jurisdiction]</li>
                    <li>Disputes will be resolved in courts located in [Your Jurisdiction]</li>
                    <li>You waive any jurisdictional objections</li>
                  </ul>

                  <h3 className="text-xl font-semibold mt-4 mb-2">12.2 Arbitration</h3>
                  <ul className="list-disc list-inside ml-4">
                    <li>Disputes will be resolved by binding arbitration</li>
                    <li>Small claims may be pursued in court</li>
                    <li>Class action waiver applies</li>
                  </ul>
                </section>

                {/* Changes to Terms */}
                <section id="changes" className="mb-8">
                  <h2 className="text-2xl font-bold mb-4">13. Changes to Terms</h2>
                  <ul className="list-disc list-inside ml-4">
                    <li>We may update these Terms with notice</li>
                    <li>Continued use constitutes acceptance</li>
                    <li>Material changes require explicit consent</li>
                  </ul>
                </section>

                {/* Contact Information */}
                <section id="contact" className="mb-8">
                  <h2 className="text-2xl font-bold mb-4">14. Contact Information</h2>
                  <p>For questions about these Terms, contact us at:</p>
                  <ul className="list-disc list-inside ml-4">
                    <li>Email: legal@cryonote.com</li>
                    <li>Address: [Company Address]</li>
                    <li>Phone: [Company Phone]</li>
                  </ul>
                </section>

                {/* Severability */}
                <section id="severability" className="mb-8">
                  <h2 className="text-2xl font-bold mb-4">15. Severability</h2>
                  <p>If any provision is found unenforceable:</p>
                  <ul className="list-disc list-inside ml-4">
                    <li>The provision will be modified minimally to be enforceable</li>
                    <li>Other provisions remain in full effect</li>
                    <li>The Terms' essence will be preserved</li>
                  </ul>
                </section>

                {/* Conclusion */}
                <div className="mt-8 border-t border-gray-200 pt-8">
                  <div className="bg-gray-50 p-6 rounded-lg">
                    <p className="text-sm text-gray-600">
                      By using Cryonote, you acknowledge that you have read, understood, and agree to be bound by these Terms of Service. If you do not agree to these terms, please do not use the Service.
                    </p>
                  </div>
                </div>
              </div>

              {/* Document Footer */}
              <div className="mt-8 bg-white rounded-xl shadow-sm p-6">
                <div className="flex items-center justify-between text-sm text-gray-500">
                  <div className="flex items-center">
                    <DocumentCheckIcon className="h-5 w-5 text-green-500 mr-2" />
                    <span>Digitally signed and verified</span>
                  </div>
                  <div>
                    Document ID: TOS-{version}-{new Date(lastUpdated).getTime()}
                  </div>
                </div>

                {/* Archive Notice */}
                <div className="mt-4 flex items-center justify-between text-xs text-gray-500 border-t border-gray-200 pt-4">
                  <span>Previous versions are archived and available upon request</span>
                  <a 
                    href="/terms/archive" 
                    className="flex items-center text-blue-600 hover:text-blue-800"
                  >
                    View archive
                    <ArrowTopRightOnSquareIcon className="h-4 w-4 ml-1" />
                  </a>
                </div>
              </div>

              {/* Action Buttons */}
              <div className="mt-8 flex justify-center space-x-4">
                <button
                  onClick={() => window.history.back()}
                  className="px-6 py-2 border border-gray-300 rounded-lg text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Go Back
                </button>
                <button
                  onClick={downloadTOS}
                  className="px-6 py-2 bg-blue-600 text-white rounded-lg text-sm font-medium hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Download Terms
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>

      <Footer />

      {/* Print Styles */}
      <style jsx global>{`
        @media print {
          nav, footer, button, .no-print {
            display: none !important;
          }
          
          body {
            padding: 2cm;
          }

          #tos-content {
            font-size: 12pt;
          }

          h1, h2, h3 {
            page-break-after: avoid;
          }

          ul, ol {
            page-break-inside: avoid;
          }

          @page {
            margin: 2cm;
          }
        }
      `}</style>
    </div>
  );
};

export default TosPage;
