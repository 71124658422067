import React, { useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';

const CollapsibleExplanation = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg mb-8 border border-gray-200">
      {/* Header (Entire area clickable) */}
      <div
        onClick={toggleCollapse}
        className="flex justify-between items-center px-4 py-5 sm:p-6 bg-gray-50 hover:bg-gray-100 transition cursor-pointer"
      >
        <h1 className="text-3xl font-bold text-gray-900">{title}</h1>
        <div className="flex-shrink-0 w-6 h-6">
          {isOpen ? (
            <ChevronUpIcon className="w-full h-full text-gray-500" />
          ) : (
            <ChevronDownIcon className="w-full h-full text-gray-500" />
          )}
        </div>
      </div>

      {/* Collapsible Content */}
      {isOpen && (
        <div className="px-4 py-5 sm:p-6 text-gray-700">
          {children}
        </div>
      )}
    </div>
  );
};

export default CollapsibleExplanation;
