import React from 'react';
import {
  ShieldCheckIcon,
  BanknotesIcon,
  CubeTransparentIcon,
  BuildingLibraryIcon,
  ChartBarIcon,
  RocketLaunchIcon,
  ChevronRightIcon,
  DocumentTextIcon,
  ClockIcon,
} from '@heroicons/react/24/outline';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const LongevityAndTrust = () => {
  const sections = [
    { id: 'introduction', title: '1. Introduction' },
    { id: 'operational-reserve', title: '2. Current Operational Reserve' },
    { id: 'commitment', title: '3. Our Commitment to Stability' },
    { id: 'current-measures', title: '4. Current Measures' },
    { id: 'development-roadmap', title: '5. Development Roadmap' },
    { id: 'user-protection', title: '6. User Protection' },
    { id: 'get-started', title: '7. Ready to Secure Your Legacy?' },
  ];

  const scrollToSection = (sectionId) => {
    document.getElementById(sectionId).scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <Navbar />

      <main className="pt-16 pb-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="lg:grid lg:grid-cols-12 lg:gap-8">
            {/* Sidebar Navigation */}
            <div className="hidden lg:block lg:col-span-3">
              <nav className="sticky top-20 divide-y divide-gray-200">
                <div className="pb-8">
                  <h2 className="text-sm font-semibold text-gray-900">On this page</h2>
                  <ul className="mt-4 space-y-2">
                    {sections.map((section) => (
                      <li key={section.id}>
                        <button
                          onClick={() => scrollToSection(section.id)}
                          className="group flex items-center text-sm text-gray-600 hover:text-gray-900"
                        >
                          <ChevronRightIcon className="h-4 w-4 mr-2 text-gray-400 group-hover:text-gray-900" />
                          {section.title}
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              </nav>
            </div>

            {/* Main Content */}
            <div className="lg:col-span-9">
              {/* Header Section */}
              <div className="bg-white rounded-xl shadow-sm p-8 mb-8">
                <h1 className="text-3xl font-bold text-gray-900 mb-4">
                  Building a Lasting Digital Legacy
                </h1>
                <p className="text-gray-600">
                  At Cryonote, we're committed to the long-term preservation of your digital legacy. Discover how we're building a stable, secure, and enduring platform for your future messages.
                </p>
              </div>

              {/* Content Sections */}
              <div id="longevity-content" className="bg-white rounded-xl shadow-sm p-8 max-w-none">
                {/* Introduction */}
                <section id="introduction" className="mb-8">
                  <h2 className="text-2xl font-bold mb-4">1. Introduction</h2>
                  <p>
                    Ensuring the longevity and trustworthiness of our platform is paramount. We understand that you're entrusting us with your most valuable messages, and we're dedicated to maintaining that trust over the long term.
                  </p>
                </section>

                {/* Current Operational Reserve */}
                <section id="operational-reserve" className="mb-8">
                  <h2 className="text-2xl font-bold mb-4">2. Current Operational Reserve</h2>
                  <div className="flex items-center mb-4">
                    <ClockIcon className="h-8 w-8 text-blue-600 flex-shrink-0" />
                    <h3 className="text-xl font-bold text-gray-900 ml-3">
                      5+ Years of Operational Reserve
                    </h3>
                  </div>
                  <p className="text-gray-600">
                    We have established an operational reserve that covers more than 5 years of our current operating costs. This reserve ensures that we can continue to provide uninterrupted service, even in the face of unforeseen circumstances. As our business grows, we will update this reserve accordingly to maintain or exceed this coverage period.
                  </p>
                </section>

                {/* Our Commitment to Stability */}
                <section id="commitment" className="mb-8">
                  <h2 className="text-2xl font-bold mb-4">3. Our Commitment to Stability</h2>
                  <p>
                    We're building Cryonote with a focus on longevity, implementing robust systems today while developing advanced solutions for tomorrow. Our commitment includes financial stability, infrastructure redundancy, and continuous improvement.
                  </p>
                </section>

                {/* Current Measures */}
                <section id="current-measures" className="mb-8">
                  <h2 className="text-2xl font-bold mb-4">4. Current Measures</h2>
                  <div className="grid gap-8 md:grid-cols-2">
                    {/* Financial Foundation */}
                    <div className="border rounded-lg p-6">
                      <div className="flex items-center mb-4">
                        <BanknotesIcon className="h-8 w-8 text-blue-600 flex-shrink-0" />
                        <h3 className="text-xl font-bold text-gray-900 ml-3">
                          Financial Foundation
                        </h3>
                      </div>
                      <p className="text-gray-600 mb-4">
                        We're building a sustainable financial foundation with dedicated operating reserves and transparent financial planning.
                      </p>
                      <ul className="list-disc list-inside text-gray-600 space-y-2">
                        <li>25% of revenue allocated to reserves</li>
                        <li>Lean operational structure</li>
                        <li>Transparent financial planning</li>
                        <li>Regular financial audits</li>
                      </ul>
                    </div>
                    {/* Infrastructure Redundancy */}
                    <div className="border rounded-lg p-6">
                      <div className="flex items-center mb-4">
                        <BuildingLibraryIcon className="h-8 w-8 text-blue-600 flex-shrink-0" />
                        <h3 className="text-xl font-bold text-gray-900 ml-3">
                          Infrastructure Redundancy
                        </h3>
                      </div>
                      <p className="text-gray-600 mb-4">
                        Multiple layers of redundancy across different geographic locations ensure your data remains secure and accessible.
                      </p>
                      <ul className="list-disc list-inside text-gray-600 space-y-2">
                        <li>Multi-region deployment</li>
                        <li>Regular backups</li>
                        <li>Automated failover systems</li>
                        <li>Disaster recovery protocols</li>
                      </ul>
                    </div>
                  </div>
                </section>

                {/* Development Roadmap */}
                <section id="development-roadmap" className="mb-8">
                  <h2 className="text-2xl font-bold mb-4">5. Development Roadmap</h2>
                  <p>
                    Our development roadmap illustrates how we plan to enhance our platform's stability and security as our business grows.
                  </p>
                  <div className="mt-6">
                    {/* Timeline */}
                    <div className="space-y-12">
                      {/* Phase 1 */}
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <div className="h-12 w-12 rounded-full bg-blue-600 flex items-center justify-center">
                            <DocumentTextIcon className="h-6 w-6 text-white" />
                          </div>
                        </div>
                        <div className="ml-4">
                          <h3 className="text-xl font-semibold text-gray-900">Phase 1: Foundation</h3>
                          <p className="text-gray-600 mt-2">
                            Establishing core systems and processes to ensure initial stability.
                          </p>
                          <ul className="list-disc list-inside text-gray-600 space-y-2 mt-2">
                            <li>Secure cloud infrastructure</li>
                            <li>Geographic redundancy</li>
                            <li>Operating reserve building</li>
                            <li>24/7 monitoring</li>
                          </ul>
                        </div>
                      </div>
                      {/* Phase 2 */}
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <div className="h-12 w-12 rounded-full bg-blue-600 flex items-center justify-center">
                            <RocketLaunchIcon className="h-6 w-6 text-white" />
                          </div>
                        </div>
                        <div className="ml-4">
                          <h3 className="text-xl font-semibold text-gray-900">Phase 2: Expansion</h3>
                          <p className="text-gray-600 mt-2">
                            Enhancing our platform with advanced technologies and methods.
                          </p>
                          <ul className="list-disc list-inside text-gray-600 space-y-2 mt-2">
                            <li>Blockchain integration</li>
                            <li>Enhanced backup systems</li>
                            <li>Additional redundancy layers</li>
                            <li>Extended financial reserves</li>
                          </ul>
                        </div>
                      </div>
                      {/* Phase 3 */}
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <div className="h-12 w-12 rounded-full bg-blue-600 flex items-center justify-center">
                            <ChartBarIcon className="h-6 w-6 text-white" />
                          </div>
                        </div>
                        <div className="ml-4">
                          <h3 className="text-xl font-semibold text-gray-900">Phase 3: Innovation</h3>
                          <p className="text-gray-600 mt-2">
                            Continual improvement and adaptation to future technologies.
                          </p>
                          <ul className="list-disc list-inside text-gray-600 space-y-2 mt-2">
                            <li>Exploring decentralized storage solutions</li>
                            <li>AI-driven security enhancements</li>
                            <li>Community-driven development</li>
                            <li>Ongoing research and development</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                {/* User Protection */}
                <section id="user-protection" className="mb-8">
                  <h2 className="text-2xl font-bold mb-4">6. User Protection</h2>
                  <p>
                    We understand the importance of your digital legacy. That's why we've implemented several measures to ensure your data's security and accessibility:
                  </p>
                  <ul className="list-disc list-inside text-gray-600 space-y-2 mt-4">
                    <li>Advanced notice of any significant platform changes</li>
                    <li>Data portability and export options</li>
                    <li>Regular system backups and redundancy</li>
                    <li>Transparent communication about platform status</li>
                    <li>Commitment to data integrity and privacy</li>
                  </ul>
                </section>

                {/* Ready to Secure Your Legacy */}
                <section id="get-started" className="text-center">
                  <h2 className="text-2xl font-bold text-gray-900 mb-4">7. Ready to Secure Your Legacy?</h2>
                  <p className="text-gray-600 mb-6">
                    Join us in building a secure and lasting digital legacy platform.
                  </p>
                  <a
                    href="/signup"
                    className="inline-block px-6 py-3 bg-blue-600 text-white font-medium rounded-lg hover:bg-blue-700 transition-colors"
                  >
                    Get Started
                  </a>
                </section>
              </div>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default LongevityAndTrust;
