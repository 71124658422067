import React, { useState, useEffect } from 'react';
import { InformationCircleIcon, XCircleIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { Switch } from '@headlessui/react';
import InfoModal from '../../common/InfoModal';

const TriggerCard = ({ type, settings, onSave }) => {
  const [localSettings, setLocalSettings] = useState(settings);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [currentEmail, setCurrentEmail] = useState('');
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    setLocalSettings(settings);
    setHasChanges(false);
  }, [settings]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLocalSettings(prev => {
      const newSettings = { 
        ...prev, 
        [name]: ['finalVerificationPeriod', 'requiredConfirmations', 'gracePeriod'].includes(name) 
          ? parseInt(value, 10) 
          : value 
      };
      setHasChanges(JSON.stringify(newSettings) !== JSON.stringify(settings));
      return newSettings;
    });
  };

  const handleToggleActive = () => {
    setLocalSettings(prev => ({
      ...prev,
      isActive: !prev.isActive
    }));
    setHasChanges(true);
  };

  const handleSave = () => {
    console.log('Saving settings:', localSettings);
    onSave(localSettings);
    setHasChanges(false);
  };

  const isValidEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleEmailInputChange = (e) => {
    setCurrentEmail(e.target.value);
  };

  const handleEmailInputKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === ',') {
      e.preventDefault();
      const email = currentEmail.trim();
      if (email && isValidEmail(email)) {
        setLocalSettings(prev => {
          const newTrustedContacts = Array.isArray(prev.trustedContacts) 
            ? [...prev.trustedContacts, { email }] 
            : [{ email }];
          const newSettings = {
            ...prev,
            trustedContacts: newTrustedContacts
          };
          setHasChanges(JSON.stringify(newSettings) !== JSON.stringify(settings));
          return newSettings;
        });
        setCurrentEmail('');
      }
    }
  };

  const removeEmail = (emailToRemove) => {
    setLocalSettings(prev => {
      const newTrustedContacts = Array.isArray(prev.trustedContacts)
        ? prev.trustedContacts.filter(contact => contact.email !== emailToRemove)
        : [];
      const newSettings = {
        ...prev,
        trustedContacts: newTrustedContacts
      };
      setHasChanges(JSON.stringify(newSettings) !== JSON.stringify(settings));
      return newSettings;
    });
  };

  const getTriggerStatus = () => {
    if (type === 'Recurring Check-in') {
      return localSettings.notificationMethod ? 'Complete - Active' : 'Incomplete - Active';
    } else {
      if (!localSettings.finalVerificationPeriod || localSettings.trustedContacts.length === 0) {
        return 'Incomplete - Inactive';
      }
      return localSettings.isActive ? 'Complete - Active' : 'Complete - Inactive';
    }
  };

  const renderStatusBadge = () => {
    const status = getTriggerStatus();
    let bgColor = 'bg-yellow-100 text-yellow-800';
    if (status.includes('Complete - Active')) {
      bgColor = 'bg-green-100 text-green-800';
    } else if (status.includes('Complete - Inactive')) {
      bgColor = 'bg-gray-100 text-gray-800';
    }

    return (
      <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${bgColor}`}>
        {status}
      </span>
    );
  };

  const getInfoContent = () => {
    if (type === 'Recurring Check-in') {
      return (
        <>
          <p>The Recurring Check-in is a proactive safety measure designed to ensure your well-being and manage your digital legacy.</p>
          <p className="mt-2">Here's a detailed breakdown of how it works:</p>
          <ol className="list-decimal list-inside mt-2 space-y-2">
            <li>
              <strong>Regular Check-ins:</strong> Based on your chosen frequency (daily, weekly, or monthly), you'll receive a notification asking you to confirm your status.
            </li>
            <li>
              <strong>Easy Confirmation:</strong> Responding to these check-ins is simple - just a click of a button or a quick reply to confirm you're okay.
            </li>
            <li>
              <strong>Grace Period:</strong> If you miss a check-in, don't worry. There's a grace period (which you can set) before any further action is taken.
            </li>
            <li>
              <strong>Escalation Process:</strong> If you don't respond during the grace period, a final verification will be sent to ensure it's not just a temporary oversight.
            </li>
            <li>
              <strong>Message Release:</strong> Only if there's no response to the final verification will your pre-set messages be released to your designated recipients.
            </li>
          </ol>
          <p className="mt-2">
            <strong>Important:</strong> Keep your contact information up-to-date to prevent accidental triggers. This system is designed with multiple safeguards to avoid premature message releases while ensuring your wishes are carried out when necessary.
          </p>
        </>
      );
    } else if (type === 'Trusted Consensus') {
      return (
        <>
          <p>The Trusted Consensus trigger is a collaborative approach to managing your digital legacy, relying on the collective judgment of your most trusted contacts.</p>
          <p className="mt-2">Here's a comprehensive explanation of how it functions:</p>
          <ol className="list-decimal list-inside mt-2 space-y-2">
            <li>
              <strong>Trusted Contacts:</strong> You designate a group of reliable individuals who know you well and can make informed decisions about your status.
            </li>
            <li>
              <strong>Consensus Threshold:</strong> You set the number of confirmations required to activate the trigger. This helps prevent false alarms from a single mistaken report.
            </li>
            <li>
              <strong>Reporting Process:</strong> If your trusted contacts believe it's time to release your messages, they can independently report this through a secure channel.
            </li>
            <li>
              <strong>Verification:</strong> Once the required number of confirmations is reached, the system doesn't immediately release messages. Instead, it initiates a final verification process.
            </li>
            <li>
              <strong>Grace Period:</strong> A final check is sent to your primary contact method. You set the duration of this grace period, allowing time for you to respond if there's been a mistake.
            </li>
            <li>
              <strong>Message Release:</strong> If there's no response during the grace period, only then will your pre-set messages be released to their intended recipients.
            </li>
          </ol>
          <p className="mt-2">
            <strong>Key Benefit:</strong> This method combines human judgment with systematic safeguards, providing a balanced approach to managing your digital legacy. It's especially useful for situations where automated check-ins might not be sufficient.
          </p>
        </>
      );
    }
  };

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg mb-6">
      <div className="px-4 py-5 sm:px-6 flex justify-between items-center">
        <h3 className="text-lg leading-6 font-medium text-gray-900">{type}</h3>
        <div className="flex items-center space-x-4">
          {renderStatusBadge()}
          <Switch
            checked={type === 'Recurring Check-in' ? true : localSettings.isActive}
            onChange={handleToggleActive}
            disabled={type === 'Recurring Check-in'}
            className={`${
              localSettings.isActive ? 'bg-blue-600' : 'bg-gray-200'
            } relative inline-flex h-6 w-11 items-center rounded-full`}
          >
            <span className="sr-only">Enable {type}</span>
            <span
              className={`${
                localSettings.isActive ? 'translate-x-6' : 'translate-x-1'
              } inline-block h-4 w-4 transform rounded-full bg-white transition`}
            />
          </Switch>
          <button onClick={() => setIsInfoModalOpen(true)} className="text-blue-600 hover:text-blue-800">
            <InformationCircleIcon className="h-6 w-6" />
          </button>
        </div>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:p-6">
        {type === 'Recurring Check-in' && (
          <>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Check-in Frequency</label>
              <select
                name="frequency"
                value={localSettings.frequency}
                onChange={handleInputChange}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
              >
                <option value="daily">Daily</option>
                <option value="weekly">Weekly</option>
                <option value="monthly">Monthly</option>
                <option value="3months">3 Months</option>
                <option value="6months">6 Months</option>
              </select>
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Notification Method</label>
              <select
                name="notificationMethod"
                value={localSettings.notificationMethod || ''}
                onChange={handleInputChange}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
              >
                <option value="">Select a method</option>
                <option value="email">Email</option>
                <option value="sms">SMS</option>
                <option value="app_notification">App Notification</option>
              </select>
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Grace Period for Missed Check-ins</label>
              <select
                name="gracePeriod"
                value={localSettings.gracePeriod}
                onChange={handleInputChange}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
              >
                <option value={1}>1 Day</option>
                <option value={7}>1 Week</option>
                <option value={30}>1 Month</option>
              </select>
            </div>
          </>
        )}
        {type === 'Trusted Consensus' && (
          <>
            <div className="mb-4">
              <div className="flex items-center">
                <label className="block text-sm font-medium text-gray-700">Number of Confirmations Required</label>
                <div className="relative ml-1 group">
                  <InformationCircleIcon className="h-5 w-5 text-blue-500 cursor-help" />
                  <div className="hidden group-hover:block absolute z-50 w-72 px-3 py-2 text-sm bg-gray-900 text-white rounded-lg -left-20 top-full mt-2">
                    This is the minimum number of trusted contacts who must confirm before the trigger activates. This can be less than your total number of trusted contacts.
                  </div>
                </div>
              </div>
              {localSettings.requiredConfirmations > (localSettings.trustedContacts?.length || 0) && (
                <div className="mt-1 flex items-center text-sm text-red-600">
                  <ExclamationCircleIcon className="h-5 w-5 mr-1" />
                  <span>Warning: Required confirmations exceed number of trusted contacts</span>
                </div>
              )}
              <input
                type="number"
                name="requiredConfirmations"
                value={localSettings.requiredConfirmations}
                onChange={handleInputChange}
                min="1"
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
            <div className="mb-4">
  <label className="block text-sm font-medium text-gray-700">Trusted Contacts</label>
  <div className="mt-1 flex flex-wrap gap-2">
    {Array.isArray(localSettings.trustedContacts) && localSettings.trustedContacts.map((contact, index) => (
    <div key={index} className={`px-2 py-1 rounded-full text-sm flex items-center ${
      contact.isVerified ? 'bg-green-100 text-green-800' : 'bg-blue-100 text-blue-800'
    }`}>
      {contact.email}
      {contact.isVerified ? (
        <CheckCircleIcon className="h-4 w-4 ml-1 text-green-500" title="Verified Contact" />
      ) : (
        <span className="ml-1 text-xs">(Unverified)</span>
      )}
      <button onClick={() => removeEmail(contact.email)} className="ml-2 text-blue-600 hover:text-blue-800">
        <XCircleIcon className="h-4 w-4" />
      </button>
    </div>
    ))}
  </div>
  <input
    type="email"
    value={currentEmail}
    onChange={handleEmailInputChange}
    onKeyDown={handleEmailInputKeyDown}
    className="mt-2 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
    placeholder="Enter email and press Enter"
  />
  <div className="mt-2 flex items-center text-sm text-gray-500">
    <InformationCircleIcon className="h-5 w-5 mr-1 text-blue-500" />
    <span>New contacts will receive a verification email when you save changes.</span>
  </div>
</div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Final Verification Grace Period</label>
              <select
                name="finalVerificationPeriod"
                value={localSettings.finalVerificationPeriod || ''}
                onChange={handleInputChange}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
              >
                <option value="">Select a period</option>
                <option value={1}>1 Day</option>
                <option value={3}>3 Days</option>
                <option value={7}>1 Week</option>
                <option value={30}>1 Month</option>
              </select>
            </div>
          </>
        )}
      </div>
      {hasChanges && (
        <div className="bg-gray-50 px-4 py-4 sm:px-6">
          <button
            onClick={handleSave}
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Save Changes
          </button>
        </div>
      )}
      <InfoModal
        isOpen={isInfoModalOpen}
        onClose={() => setIsInfoModalOpen(false)}
        title={type}
        content={getInfoContent()}
      />
    </div>
  );
};

export default TriggerCard;