import React, { useState } from 'react';

const EnhancedSecurityPopup = ({ onClose }) => {
  const [dontShowAgain, setDontShowAgain] = useState(false);

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
      <div className="relative bg-white rounded-lg shadow-xl p-6 max-w-md">
        <h2 className="text-xl font-semibold mb-4">Enhanced Security (Local Encryption)</h2>
        <p className="mb-4">
          Enhanced Security encrypts your message with a unique key. This key is required to decrypt and read the message. 
          Please ensure you save this key securely and share it with the intended recipients through a secure channel.
        </p>
        <p className="mb-4">
          <b>Without this key, the message cannot be decrypted, even by us.</b>
        </p>
        
        <button
          onClick={() => onClose(dontShowAgain)}
          className="w-full bg-blue-600 text-white rounded-md py-2 hover:bg-blue-700"
        >
          I understand
        </button>
      </div>
    </div>
  );
};

export default EnhancedSecurityPopup;