import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { 
  ShieldCheckIcon, 
  EnvelopeIcon, 
  DocumentTextIcon, 
  KeyIcon, 
  BellIcon, 
  CloudArrowUpIcon 
} from '@heroicons/react/24/outline';
import FeatureVisual from './FeatureVisual'; // Add this import

const FeaturesAndBenefits = () => {
  const [activeTab, setActiveTab] = useState('messages');

  const categories = [
    {
      id: 'messages',
      name: 'Message Creation',
      icon: EnvelopeIcon,
      description: 'Craft your messages with powerful tools and options'
    },
    {
      id: 'security',
      name: 'Security',
      icon: ShieldCheckIcon,
      description: 'Enterprise-grade protection for your sensitive content'
    },
    {
      id: 'files',
      name: 'File Management',
      icon: DocumentTextIcon,
      description: 'Secure storage and delivery of important documents'
    }
  ];

  const features = {
    messages: [
      {
        title: "Simple Message Creator",
        description: "Easily compose messages with our intuitive, email-like interface",
        benefits: [
          "Clean, distraction-free writing environment",
          "Seamless file attachment",
          "Save drafts for later"
        ],
        image: "/api/placeholder/400/300",
        highlight: "Loved by 98% of our users"
      },
      {
        title: "Smart Delivery Rules",
        description: "Ensure your messages are delivered only after your passing, acting as a digital deadman's switch",
        benefits: [
          "Automatic delivery upon confirmation of passing",
          "Customizable check-in intervals",
          "Multiple trusted contacts for verification",
          "Failsafe mechanisms to prevent false triggers"
        ],
        image: "/api/placeholder/400/300",
        highlight: "Peace of mind with secure, posthumous message delivery"
      }
    ],
    security: [
      {
        title: "End-to-End Encryption",
        description: "Your messages are encrypted before they leave your device",
        benefits: [
          "256-bit AES encryption",
          "Zero-knowledge architecture",
          "Local key generation"
        ],
        image: "/api/placeholder/400/300",
        highlight: "Bank-grade security"
      },
      {
        title: "Access Control",
        description: "Granular control over who can access your content",
        benefits: [
          "Two-factor authentication",
          "Trusted contact verification",
          "Audit logging"
        ],
        image: "/api/placeholder/400/300",
        highlight: "Enterprise ready"
      }
    ],
    files: [
      {
        title: "Secure File Storage",
        description: "Store and protect important documents for future delivery",
        benefits: [
          "Encrypted file storage",
          "Automatic file versioning",
          "Format preservation"
        ],
        image: "/api/placeholder/400/300",
        highlight: "10GB secure storage included"
      },
      {
        title: "Smart File Delivery",
        description: "Ensure your files reach the right people securely",
        benefits: [
          "Secure download links",
          "Access expiration",
          "Delivery confirmation"
        ],
        image: "/api/placeholder/400/300",
        highlight: "Support for all file types"
      }
    ]
  };

  return (
    <section className="relative py-24 bg-white overflow-hidden">
      {/* Background decorative elements */}
      <div className="absolute inset-0">
        <div className="absolute inset-y-0 left-0 w-1/2 bg-gradient-to-r from-blue-50 to-transparent" />
        <div className="absolute inset-y-0 right-0 w-1/2 bg-gradient-to-l from-blue-50 to-transparent" />
      </div>

      <div className="relative max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <motion.span
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="inline-block px-4 py-2 rounded-full bg-blue-100 text-blue-700 text-sm font-medium mb-4"
          >
            Powerful Yet Simple
          </motion.span>

          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-4xl font-bold text-gray-900 mb-4"
          >
            Everything You Need to Secure Your Legacy
          </motion.h2>

          <motion.p
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-xl text-gray-600 max-w-2xl mx-auto"
          >
            Comprehensive tools and features designed to make digital legacy planning simple and secure
          </motion.p>
        </div>

        {/* Category Tabs */}
        <div className="flex justify-center mb-12">
          <div className="inline-flex p-2 space-x-2 bg-gray-100 rounded-xl">
            {categories.map((category) => (
              <button
                key={category.id}
                onClick={() => setActiveTab(category.id)}
                className={`flex items-center px-4 py-2 rounded-lg text-sm font-medium transition-all duration-200 ${
                  activeTab === category.id
                    ? 'bg-white text-blue-600 shadow-sm'
                    : 'text-gray-600 hover:text-gray-900'
                }`}
              >
                <category.icon className="h-5 w-5 mr-2" />
                {category.name}
              </button>
            ))}
          </div>
        </div>

        {/* Feature Cards */}
        <div className="space-y-16">
          {features[activeTab].map((feature, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              className="relative group"
            >
              <div className="grid md:grid-cols-2 gap-8 items-center">
                <div className={`${index % 2 === 0 ? 'md:order-1' : 'md:order-2'}`}>
                  <div className="bg-white rounded-2xl p-8 shadow-lg transform transition-all duration-300 group-hover:scale-105">
                    <h3 className="text-2xl font-bold text-gray-900 mb-4">
                      {feature.title}
                    </h3>
                    <p className="text-gray-600 mb-6">
                      {feature.description}
                    </p>
                    <ul className="space-y-4">
                      {feature.benefits.map((benefit, idx) => (
                        <li key={idx} className="flex items-start">
                          <svg className="h-6 w-6 text-blue-500 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                          </svg>
                          <span className="text-gray-600">{benefit}</span>
                        </li>
                      ))}
                    </ul>
                    <div className="mt-6 flex items-center">
                      <span className="text-sm font-medium text-blue-600">
                        {feature.highlight}
                      </span>
                    </div>
                  </div>
                </div>
                <div className={`${index % 2 === 0 ? 'md:order-2' : 'md:order-1'}`}>
                  <div className="relative rounded-2xl overflow-hidden shadow-lg transform transition-all duration-300 group-hover:scale-105">
                    <FeatureVisual feature={feature} />
                  </div>
                </div>
              </div>
            </motion.div>
          ))}
        </div>

        {/* Call to Action */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="mt-16 text-center"
        >
          <a
            href="/signup"
            className="inline-flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gradient-to-r from-blue-600 to-blue-500 hover:from-blue-700 hover:to-blue-600 transform hover:scale-105 transition-all duration-200 shadow-lg hover:shadow-xl"
          >
            Start Free Trial
            <span className="ml-2">→</span>
          </a>
          <p className="mt-4 text-sm text-gray-500">
            No credit card required • 14-day free trial
          </p>
        </motion.div>
      </div>
    </section>
  );
};

export default FeaturesAndBenefits;
