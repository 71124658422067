import React from 'react';
import { motion } from 'framer-motion';
import { ArrowRightIcon, PencilSquareIcon, BellAlertIcon, PaperAirplaneIcon } from '@heroicons/react/24/outline';

const HowItWorks = () => {
  const steps = [
    {
      icon: <PencilSquareIcon className="h-8 w-8 text-blue-500" />,
      number: "01",
      title: "Write your messages",
      description: "Create personal messages, share access to digital assets, or leave business instructions.",
    },
    {
      icon: <BellAlertIcon className="h-8 w-8 text-blue-500" />,
      number: "02",
      title: "Choose your triggers",
      description: "Set up automated check-ins or designate trusted contacts to determine when messages are sent.",
    },
    {
      icon: <PaperAirplaneIcon className="h-8 w-8 text-blue-500" />,
      number: "03",
      title: "We handle delivery",
      description: "Your messages will be securely delivered to the right people at the right time.",
    }
  ];

  return (
    <section className="bg-gradient-to-b from-white to-blue-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24 sm:py-32">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="text-center mb-16"
        >
          <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Secure your legacy in three simple steps
          </h2>
          <p className="mt-6 text-xl text-gray-600 max-w-3xl mx-auto">
            No complex setup. No technical knowledge required. 
            Just straightforward digital legacy planning that works.
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-12 mt-16">
          {steps.map((step, index) => (
            <motion.div
              key={step.number}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
            >
              <div className="bg-white rounded-2xl shadow-xl p-8 h-full flex flex-col items-center text-center group hover:bg-blue-50 transition-all duration-300">
                <div className="bg-blue-100 rounded-full p-3 mb-6 group-hover:bg-blue-200 transition-colors duration-300">
                  {step.icon}
                </div>
                <span className="text-blue-600 font-bold text-lg mb-4">{step.number}</span>
                <h3 className="text-2xl font-semibold text-gray-900 mb-4 group-hover:text-blue-600 transition-colors duration-300">
                  {step.title}
                </h3>
                <p className="text-gray-600 flex-grow">
                  {step.description}
                </p>
              </div>
            </motion.div>
          ))}
        </div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8, delay: 0.6 }}
          className="mt-20 text-center"
        >
          <a
            href="#pricing"
            className="inline-flex items-center px-8 py-4 text-lg font-semibold text-white bg-blue-600 rounded-full hover:bg-blue-700 transition-colors duration-300 shadow-lg hover:shadow-xl"
          >
            Start securing your legacy
            <ArrowRightIcon className="ml-3 h-5 w-5" />
          </a>
          <p className="mt-6 text-gray-600">
            No credit card required • Free 14-day trial • Cancel anytime
          </p>
        </motion.div>
      </div>
    </section>
  );
};

export default HowItWorks;
