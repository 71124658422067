// src/contexts/AuthContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';
import { 
  signInWithEmailAndPassword, 
  signInWithPopup,
  GoogleAuthProvider,
  signOut
} from 'firebase/auth';
import { auth } from '../config/firebase';
import api from '../services/api';
import stripeService from '../services/stripeService';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser ] = useState(null);
  const [displayName, setDisplayName ] = useState(null);
  const [loading, setLoading] = useState(true);
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);


  useEffect(() => {
    // Listen for Firebase auth state changes
    const unsubscribe = auth.onAuthStateChanged(async (firebaseUser) => {
      if (firebaseUser && window.location.pathname.startsWith('/dashboard')) {
        try {
          // Fetch additional user data from our backend
          const response = await api.auth.getUser();
          setUser(response.data);
        } catch (error) {
          console.error('Error fetching user data:', error);
          setUser(null);
        }
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

useEffect(() => {
  if (user && window.location.pathname.startsWith('/dashboard')) {
    stripeService.getSubscriptionStatus()
      .then(status => setSubscriptionStatus(status));
  }
}, [user, window.location.pathname]);


  const login = async (credentials) => {
    try {
      // Firebase email/password auth
      const userCredential = await signInWithEmailAndPassword(
        auth,
        credentials.email,
        credentials.password
      );

      // Check if 2FA is required
      const response = await api.auth.getUser();
      
      if (response.data.settings?.twoFactorAuth?.isEnabled) {
        return {
          require2FA: true,
          tempToken: await userCredential.user.getIdToken(),
          method: response.data.settings.twoFactorAuth.method,
          provider: 'email'
        };
      }

      setUser(response.data);
      return { success: true };
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
  };

  const signup = async (userData) => {
    try {
      // Create user in Firebase
      const response = await api.auth.register(userData);
      
      // Sign in the user
      await signInWithEmailAndPassword(auth, userData.email, userData.password);
      
      setUser(response.data);
      return { success: true };
    } catch (error) {
      console.error('Signup error:', error);
      throw error;
    }
  };

  const googleLogin = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      
      // Create or fetch user from our backend
      const response = await api.auth.getUser();

      console.log('Google Login Response: ', result.user.displayName);
      console.log('BackendResponse: ', response);
      
      if (response.data.settings?.twoFactorAuth?.isEnabled) {
        return {
          require2FA: true,
          tempToken: await result.user.getIdToken(),
          provider: 'google'
        };
      }

      const usersName = response.data.user.name || result.user.displayName

      setUser(response.data);
      setDisplayName(usersName);
      return { success: true };
    } catch (error) {
      console.error('Google login error:', error);
      throw error;
    }
  };

  const logout = async () => {
    try {
      // await api.auth.logout(); // Cleanup on backend
      await signOut(auth);
      setUser(null);
    } catch (error) {
      console.error('Logout error:', error);
      throw error;
    }
  };

  const verify2FA = async (token, code) => {
    try {
      const response = await api.auth.verify2FA(token, code);
      setUser(response.data.user);
      return { success: true };
    } catch (error) {
      console.error('2FA verification error:', error);
      throw error;
    }
  };

  const updateUserProfile = async (profileData) => {
    try {
      const response = await api.user.updateProfile(profileData);
      setUser(prev => ({ ...prev, ...response.data }));
      return response.data;
    } catch (error) {
      console.error('Update profile error:', error);
      throw error;
    }
  };

  const value = {
    user,
    loading,
    login,
    signup,
    logout,
    verify2FA,
    googleLogin,
    updateUserProfile
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};