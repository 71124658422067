// SecureMessagePage.jsx
import React from 'react';
import SecureMessageViewer from '../components/SecureViewer/SecureMessageViewer';

const SecureMessagePage = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-100 flex items-center justify-center p-4">
      <div className="w-full max-w-4xl">
        <SecureMessageViewer />
      </div>
    </div>
  );
};

export default SecureMessagePage;